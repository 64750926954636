import { createContext, useState, useContext, useEffect } from "react";
import { ToastContext } from "cai-fusion";
import { useUserProfile } from "./UserProfileContext";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useNavigate } from "react-router-dom";
const DataStoreContext = createContext();

function DataStoreProvider({ children }) {
    const apiServiceClient = useSymphonyApiService("v2");
    const { userProfile } = useUserProfile();
    const { createToast } = useContext(ToastContext);

    const [dataStoreId, setDataStoreId] = useState(undefined);

    // All data stores as confined by the search criteria.
    const [dataStores, setDataStores] = useState([]); // List of DataStores
    // All, every.
    const [allDataStores, setAllDataStores] = useState([]);

    const [dataStore, setDataStore] = useState(null); // Single DataStore (the user's current chosen one)
    
    const [sharedUsers, setSharedUsers] = useState([]); //used to show what users (and their role) for a given DataStore
    //use sharedUsers.membership to get role
    
    // Should be toggled when UI needs to show a loading state.
    const [isLoading, setIsLoading] = useState(false);

    const [usersharedUsership, setUsersharedUsership] = useState(0);
    const [canShare, setCanShare] = useState(true);
    const membershipLevels = {
        1: "Manager",
        2: "Contribute",
        3: "View Only"
    };

    const navigate = useNavigate();

    useEffect(() => {
        getDataStores();
    }, []);

    useEffect(() => {
        const theUser = sharedUsers.find(user => user.userProfileId === userProfile.userProfileId)
        const userMembership = theUser?.membership
        setCanShare(userMembership < 2);
    }, [userProfile, usersharedUsership, sharedUsers])

    // When ID is updated
    useEffect(() => {
        // If we already have the object, what's the point in refetching it?
        if (dataStoreId && dataStore?.id !== dataStoreId) {
            fetchDataStore(dataStoreId);
        }
        else {
            setDataStore(undefined);
        }
    }, [dataStoreId]);

    /// --------------------------
    /// Data Store List functions
    /// Functions that apply to the array of data stores.
    /// --------------------------

    // Fetches the list of data stores available to the user.
    const getDataStores = async () => {
        setIsLoading(true);
        try {
            const newDataStores = await apiServiceClient.DataStore.getDataStores();
            setDataStores(newDataStores ?? []);
            setAllDataStores([...newDataStores] ?? []);
            console.log("[DATASTORES] Found data stores!", newDataStores);
        } catch (error) {
            handleApiError("Error when grabbing data stores.", error);
        } finally {
            setIsLoading(false);
        }
    }

    // Searches the available list of data stores with a given query.
    const searchDataStores = async (query, silent = false) => {
        // Handling the event of an empty search query
        if (!query || !query.trim()) {
            setDataStores(allDataStores);
            return;
        }

        // Setting loading state for UI loading components
        if (!silent) setIsLoading(true);

        try {
            console.log("[DATASTORES] Searching data stores with query", query);
            const respDataStores = await apiServiceClient.DataStore.getDataStores(query);
            setDataStores([...respDataStores]);
            console.log("[DATASTORES] Results:", respDataStores);
        } catch (error) {
            handleApiError("Error searching data stores.", error);
        } finally {
            setIsLoading(false);
        }
    }

    // Takes a destination ID and does the necessary jobs to set up the single view page.
    const selectDataStore = async (destDataStoreId) => {
        console.log("[DATASTORES] Entering data store with ID", destDataStoreId);
        setDataStoreId(destDataStoreId);
        await fetchDataStore(destDataStoreId);
        navigate(`/maestro/data/${destDataStoreId}`);
    }

    /// --------------------------
    /// Data Store Functions
    /// Functions that apply to the data store object itself.
    /// --------------------------

    // Gets a single data store via a given ID.
    const fetchDataStore = async (dataStoreId) =>{
        if (!dataStoreId) return;

        setIsLoading(true);
        try {
            console.log("[DATASTORE] Attempting to fetch data store...")
            const response = await apiServiceClient.DataStore.getDataStore(dataStoreId);
            console.log("[DATASTORE] Recieved data store:", response)
            
            const { accesses } = response;
            // Updating states
            setDataStore(response);
            setSharedUsers(accesses);
        } catch (error) {
            handleApiError("Failed to fetch data store", error);
        } finally {
           setIsLoading(false);
        }
    };

    // Creates a new data store.
    const createDataStore = async (name, description = undefined) => {
        setIsLoading(true);
        console.log("[DATASTORES] Creating a new data store named", name, "...");
        try {
            // Create a temporary data store, the ID being "loadingitem" controls conditional rendering.
            setDataStores([...dataStores, {"id": "loadingitem", "name": name, "description": description}]);
            // Construct the data store object from the name and description
            let result = await apiServiceClient.DataStore.createDataStore({ name: name, description: description });
            // Filter out the temporary data store and add the new fully assembled data store.
            setDataStores([...dataStores.filter((dataStore) => dataStore.id !== "loadingitem"), result]);
            createToast("Data collection created successfully.", "success");
        } catch (error) {
            handleApiError("There was a problem creating your data collection.", error);
        } finally {
            setIsLoading(false);
        }
    }

    // Renames a given data store with a new name.
    const renameDataStore = async (dataStoreId, newName) => {
        console.log("[DATASTORE] Changing name for data store...");
        try {
            let changedDataStore = dataStores.find((dataStore) => dataStore.id === dataStoreId);
            // Local update of the list of data stores
            changedDataStore.name = newName;
            setDataStores([...dataStores]);
            // Backend/API update of the list of data stores
            await apiServiceClient.DataStore.renameDataStore(dataStoreId, newName);
            setDataStoreId(undefined);
        } catch (error) {
            handleApiError("Error renaming DataStore.", error);
        }  
    }

    // Changes a given data store's description.
    const changeDataStoreDescription = async (dataStoreId, newDescription) => {
        try {
            console.log("[DATASTORE] Changing description for data store...");
            // Local update of the list of data stores
            let changedDataStore = dataStores.find((dataStore) => dataStore.id === dataStoreId);
            changedDataStore.description = newDescription;
            setDataStores([...dataStores]);
            // Backend/API update of the list of data stores
            await apiServiceClient.DataStore.updateDataStoreDescription(dataStoreId, newDescription);
        } catch (error) {
            handleApiError("Error updating DataStore description.", error);
        }
    }

    // Delete the current data store.
    const deleteDataStore = async () => {
        setIsLoading(true);
        try {
            console.log("[DATASTORES] Attempting to delete data store...")
            await apiServiceClient.DataStore.deleteDataStore(dataStoreId);
            setDataStores(dataStores.filter((dataStore) => dataStore.id !== dataStoreId));
            setAllDataStores(allDataStores.filter((dataStore) => dataStore.id !== dataStoreId));
            setDataStoreId(undefined);
        } catch (error) {
            console.log("[DATASTORES] Error when deleting data store.")
        }
        setIsLoading(false);
    }

    // Toggle the favorite status of the data store.
    const toggleFavoriteDataStore = async (dataStoreId, isFavorited) => {
        try {
            let currDataStore = dataStores.find((dataStore) => dataStore.id === dataStoreId);
            console.log("[DATASTORES] Toggling favorite for ID", dataStoreId, "to", !isFavorited);
            if (isFavorited) {
                await apiServiceClient.DataStore.unfavoriteDataStore(dataStoreId);
            } else {
                await apiServiceClient.DataStore.favoriteDataStore(dataStoreId);
            }
            currDataStore.isFavorited = !currDataStore.isFavorited;
            // Proc update to the data store list to update rendering
            setDataStores([...dataStores]);
            //createToast("Favorite status updated successfully.", "success");
        } catch (error) {
            handleApiError("Error toggling favorite status.", error);
        }        
    }

    /// --------------------------
    /// Data Store Share Functions
    /// Functions that relate to sharing or access to the data stores.
    /// --------------------------

    // Get the members of the current data store.
    const getDataStoreMembers = async () => {
        let members = dataStore?.accesses;
        console.log("[ACCESS] Get chat members: ", members);
        setSharedUsers(members ?? []);

        // Sanity check to make sure the user has access to the current data store.
        const membership = members.find((x) => x.userProfileId === userProfile?.userProfileId);

        if (membership) {
            setUsersharedUsership(membership.membership);
        }
        else {
            //It should never be possible to get here, since the backend will only return data stores the user has access to, but including for security sake
            console.error("[DATASTORE] User does not have access to this chat.");
        }

        // Returning object value instead of state in case of race conditions
        return dataStore?.accesses;
    }

    // Adds a user to a data store.
    const shareDataStore = async (user, membership) =>{
        if (!dataStoreId) {
            console.error("[SHAREDATASTORE] NO DATASTORE HERE DUDE");
            return;
        }

        if (user) {
            try {
                console.log("[SHAREDATASTORE] Sharing datastore with a user.")
                const resp = await apiServiceClient.DataStore.shareDataStore(dataStoreId, undefined, user.identifier, membership);
                //const resp = await apiServiceClient.DataStore.getActiveUsers(dataStoreId);
                setSharedUsers([...sharedUsers, resp]);
                createToast("DataStore shared successfully.", "success");
            } catch(error) {
                handleApiError("Error sharing DataStore.", error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    // Updates a member's current membership level for a data store.
    const updateDataStoreAccess = async (userId, membership) => {
        console.log("[DATASTORES] test")
        setIsLoading(true);
        try {
            await apiServiceClient.DataStore.updateDataStoreAccess(dataStoreId, userId.userProfileId, membership);
            const response = await apiServiceClient.DataStore.getDataStore(dataStoreId);
            replaceUser(userId.userProfileId, response.accesses);
            createToast("DataStore access updated successfully", "success" );
        } catch (error) {
            handleApiError("Error updating DataStore access", error);
        } finally {
            setIsLoading(false);
        }
    };

    // I'm gonna be honest I have no idea why this function exists
    const replaceUser = (userId, accessList) =>{
        const updatedUsers = accessList.map((user) => user.userProfileId === userId.userProfileId ? userId : user)
        setSharedUsers(updatedUsers);
    }

    // Removes a user's access to a data store.
    const removeUserDataStoreAccess = async (userId) => {
        setIsLoading(true);
        try {
            await apiServiceClient.DataStore.removeUserDataStoreAccess(dataStoreId, userId.userProfileId);
            const response = await apiServiceClient.DataStore.getDataStore(dataStoreId); //could optimize by creating a getActive users only api call
            setSharedUsers(response.accesses);
            createToast("User access removed successfully", "success" );
        } catch (error) {
            handleApiError("Error removing user access", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Check if a user is a member of the current chat
    const isMember = async (user) => {
        console.log("[DATASTORE] " + user.identifier + " being checked for membership");
        await getDataStoreMembers();
        const membership = sharedUsers.find((x) => x.userProfileId === user?.userProfileId);
        return membership;
    }

    // Search all users with a given query, usually a name.
    const searchUsers = async (query) => {
        return await apiServiceClient.UserProfiles.searchUsers(query);
    }

    /// --------------------------
    /// Data Store Content Functions
    /// Functions that apply to the files or notes stored inside the data store.
    /// --------------------------
    
    // Adds a file to the data store.
    const addContentToCurrentDataStore = async (file) => {
        // setIsLoading(true);
        try {
            const result = await apiServiceClient.DataStore.addContentToDataStore(dataStoreId, file);
            console.log("[DATASTORES] Upload finished. New item:", result);
            // if (!result.isSuccess) {
            //     throw new Error("File upload failure.");
            // }
            //createToast("File was added to collection successfully.", "success" );
            return result.result;
        } catch (error) {
            //handleApiError("An error occurred when adding your file. File was not added to collection.", error);
            const errorMessage = error.message || "An error occurred during the file upload.";
            throw new Error(errorMessage);
        }
    };

    // Adds a note to the data store.
    const addNoteToCurrentDataStore = async (name, text) => {
        setIsLoading(true);
        try {
            const result = await apiServiceClient.DataStore.addContentToDataStore(dataStoreId, {resourceName: name, text});
            return result?.result;
        } catch (error) {
            handleApiError("An error occurred when adding your note.", error);
        } finally {
            setIsLoading(false);
        }
    }

    // Fetches and returns the list of content items in a given data store.
    const fetchDataStoreContents = async (dataStoreId) => {
        try {
            const responseContentItems = await apiServiceClient.DataStore.getDataStoreContents(dataStoreId);
            return responseContentItems;
        } catch (error) {
            console.log("[DATASTORE] Error fetching contents.")
        }
    }

    // Fetches and returns a given content item in a given data store.
    const fetchDataStoreContentItem = async (dataStoreId, contentItemId) => {
        try {
            const responseContentItem = await apiServiceClient.DataStore.getDataStoreContentItem(dataStoreId, contentItemId);
            return responseContentItem;
        } catch (error) {
            handleApiError("[DATASTORES] Error fetching content item", error);
        }
    }; 

    // Removes a file or a note from a given data store. Silent flag to not update state.
    const deleteCurrentDataStoreContent = async (contentId, silent = false) => {
        try {
            console.log("[DATASTORES] Deleting a file with ID", contentId);
            await apiServiceClient.DataStore.removeDataStoreContent(dataStoreId, contentId);
            // If we don't care about being silent, update the state.
            if (!silent) setDataStore((current) => ({...current, contentItems: current.contentItems.filter((item) => item.id != contentId)}));
        } catch (error) {
            handleApiError("Error deleting DataStore Cotent.", error);
        }
    }

    // Deletes a "folder", which is equivalent to deleting all files that begin with a certain path.
    const deleteDataStoreFolder = async (targetPath) => {
        // Sift out all the files that have that path
        let toBeDeleted = dataStore.contentItems.filter((item) => (item.path ?? "").startsWith(targetPath));
        // Delete one by one
        const deleteOperations = toBeDeleted.map(async (file) => {
            // Silent delete so there's only one state update, at the very end.
            // Each state update regenerates the file-tree structure in dataStoreFileView. While not intensive resource-wise, it's probably smart to batch this update.
            deleteCurrentDataStoreContent(file.id, true);
        });

        await Promise.all(deleteOperations);
        // Update the data store to filter those out.
        setDataStore({...dataStore, contentItems: dataStore.contentItems.filter((item) => !(item.path ?? "").startsWith(targetPath))});
    }

    // Renames a file in a given data store.
    const renameCurrentDataStoreItem = async (contentId, newName) => {
        console.log("[DATASTORE] Renaming file...");
        await apiServiceClient.DataStore.renameDataStoreContent(dataStoreId, contentId, newName);

        let renamedItem = dataStore.contentItems.find((item) => item.id === contentId);
        if (renamedItem) {
            renamedItem.resourceName = newName;
            setDataStore({...dataStore, contentItems: [...dataStore.contentItems.filter((item) => item.id !== contentId), renamedItem]});
        }
    }

    // Helper function for printing API errors.
    const handleApiError = (message, error) => {
        console.error(message, error);
        createToast(message, "error");
        setIsLoading(false);
    };

    return (
        <DataStoreContext.Provider
            value={{
                // States
                dataStoreId,
                setDataStoreId,
                dataStores,
                allDataStores,
                dataStore,
                setDataStore,
                sharedUsers, //List of users who have access to this, you can revoke and adjust access levels by using sharedUsers props (membership, userprofileid)
                isLoading,
                // error,
                canShare,
                usersharedUsership,
                membershipLevels,
                // Funcs
                getDataStores,
                searchDataStores,
                selectDataStore,
                fetchDataStore,
                createDataStore,
                renameDataStore,
                changeDataStoreDescription,
                deleteDataStore,
                toggleFavoriteDataStore,
                getDataStoreMembers,
                shareDataStore,
                updateDataStoreAccess,
                replaceUser,
                removeUserDataStoreAccess,
                isMember,
                searchUsers,
                addContentToCurrentDataStore,
                addNoteToCurrentDataStore,
                fetchDataStoreContents,
                fetchDataStoreContentItem,
                deleteCurrentDataStoreContent,
                deleteDataStoreFolder,
                renameCurrentDataStoreItem,
            }}
        >
            {children}
        </DataStoreContext.Provider>
    );
}

function useDataStore() {
    const context = useContext(DataStoreContext);
    if (!context) throw new Error("useDataStore must be used within a DataStoreProvider");
    return context;
}

export { DataStoreProvider, useDataStore };

import { Heading, Drawer, Button, ContextMenu, ContextMenuItem, HeadingRow, Pagination } from "cai-fusion";
import React, { useEffect, useState } from "react"

import DeleteTemplateModal from "./overlay/deleteTemplateModal";
import RenameTemplateModal from "./overlay/renameTemplateModal";
import ShareTemplateDrawer from "./overlay/shareTemplateDrawer";

// Side menu icons
import { ReactComponent as IconPerson } from "../../../images/icon-person.svg";
import { ReactComponent as IconUsers } from "../../../images/icon-users.svg";
import { ReactComponent as IconBuilding } from "../../../images/icon-building.svg";
// Chat template icons
import { ReactComponent as IconFavorite } from "../../../images/icon-favorite.svg";
import { ReactComponent as IconEdit } from "../../../images/icon-draft.svg";
// Context menu icons
import { ReactComponent as IconTrash } from "../../../images/icon-trash.svg";
import { ReactComponent as IconShare } from "../../../images/icon-share.svg";
import { ReactComponent as IconRename } from "../../../images/icon-rename.svg";
// Search bar search icon
import ImageSearch from "../../../images/search-icon-light.png"
// Loading bar
import { ReactComponent as RippleLoading } from "../../../images/ripple-loading.svg";

import { useUserProfile } from "../contexts/UserProfileContext";
import { useTransientVisibility } from "../../fusion/Contexts/VisualContext";
import { useNewTemplate } from "../contexts/NewTemplateContext";

const MenuOption = ({ id, name, isActive, onClick, SVGObject }) => {

    return (
        <li className={`m-vertical-tabs__item ${isActive ? "m-vertical-tabs__item--active" : ""}`}
            id={`tab${id}`}
            role="tab"
            tabIndex={id}
            aria-controls={`tabpanel${id}`}
            aria-selected="true"
        >
            <a className="m-vertical-tabs__link" onClick={onClick}>
                <div className="m-vertical-tabs__icon-wrapper">
                    <SVGObject className="m-vertical-tabs__icon"/>
                </div>
                <span className="m-vertical-tabs__label">{name}</span>
            </a>
        </li>
    )
}

const Template = ({chatTemplateObj}) => {
    const [hideContextMenu, setHideContextMenu] = useState(true);

    const { setChatTemplateId, navigateToTemplateForm, toggleFavoriteTemplate } = useNewTemplate();
    const { userProfile } = useUserProfile();
    const { show } = useTransientVisibility(); 

    return (
        <tr className="o-treegrid__row o-treegrid__row--shown" key={chatTemplateObj.id}>
            {/* Name & Description */}
            <td className="o-treegrid__cell">
                <p className="a-subtle-text l-mb-none">
                    <strong>
                        <a className="a-link" href="#" 
                            onClick={(e) => {
                                e.preventDefault(); 
                                if(chatTemplateObj.accesses.some(access => access.userProfile.userProfileId === userProfile.userProfileId && access.membership < 3)){
                                    navigateToTemplateForm(chatTemplateObj.id ?? undefined);
                                } 
                            }}
                        >
                            {chatTemplateObj.title}
                        </a>
                    </strong>
                </p>
                <p className="a-subtle-text a-muted-text l-mt-none">
                    {chatTemplateObj.description}
                </p>
            </td>
            {/* Shared Icon */}
            <td className="o-treegrid__cell o-treegrid__cell--right-aligned">
                { chatTemplateObj.isShared && 
                <button 
                    className="a-tag a-tag--interactive"
                    onClick={() => {setChatTemplateId(chatTemplateObj.id); show('ShareTemplateDrawer'); }}
                >
                    <IconUsers className="a-tag__icon" />
                    <p className="a-tag__label">Shared</p>
                </button> }
            </td>
            {/* Buttons */}
            <td className="o-treegrid__cell">
                { chatTemplateObj.id === "loadingitem" ?
                    <>
                        <RippleLoading className="a-icon__img" style={{maxHeight: 50}}/>
                    </> :
                    <div className="m-button-row">
                        <ContextMenu
                            isHidden={hideContextMenu}
                            onClick={() => setHideContextMenu(!hideContextMenu)}
                            onBlur={() => setHideContextMenu(true)}
                        >
                            <ContextMenuItem 
                                disabled={!chatTemplateObj.accesses.some(access => access.userProfile.userProfileId === userProfile.userProfileId && access.membership < 3)}
                                Icon={IconRename} 
                                name="Rename Template" 
                                onClick={() => {setChatTemplateId(chatTemplateObj.id); show('RenameTemplateModal')}}  
                            />
                            <ContextMenuItem 
                                disabled={!chatTemplateObj.accesses.some(access => access.userProfile.userProfileId === userProfile.userProfileId && access.membership < 2)}
                                Icon={IconShare} 
                                name="Share Template" 
                                onClick={() => {setChatTemplateId(chatTemplateObj.id); show('ShareTemplateDrawer'); }}
                            />
                            <ContextMenuItem
                                disabled={!chatTemplateObj.accesses.some(access => access.userProfile.userProfileId === userProfile.userProfileId && access.membership < 1)}
                                Icon={IconTrash} 
                                name="Delete Template" 
                                danger 
                                onClick={() => {setChatTemplateId(chatTemplateObj.id); show('DeleteTemplateModal');}}
                            />
                        </ContextMenu>
                        <button 
                            className={`a-icon-link${chatTemplateObj?.isFavorited ? " a-icon-link--active" : ""}`}
                            onClick={() => toggleFavoriteTemplate(chatTemplateObj.id, chatTemplateObj.isFavorited)}
                        >
                            <div className="a-icon-link__inner">
                                <div className="a-icon-link__icon-wrapper">
                                    <IconFavorite className="a-icon-link__icon" />
                                </div>
                            </div>
                        </button>
                        <button 
                            className={`a-icon-link${chatTemplateObj.accesses.some(access => access.userProfile.userProfileId === userProfile.userProfileId && access.membership < 3) ? "" : " a-icon-link--disabled"}`}
                            disabled={!chatTemplateObj.accesses.some(access => access.userProfile.userProfileId === userProfile.userProfileId && access.membership < 3)}
                            onClick={() => navigateToTemplateForm(chatTemplateObj.id)}
                        >
                            <div className="a-icon-link__inner">
                                <div className="a-icon-link__icon-wrapper">
                                    <IconEdit className="a-icon-link__icon" />
                                </div>
                            </div>
                        </button>
                    </div> 
                }
            </td>
        </tr>
    )
}

const SymphonyTemplates = () => {
    const { chatTemplates, setChatTemplateId, setChatTemplate, searchTemplates, navigateToTemplateForm, isLoading } = useNewTemplate();
    const { userProfile } = useUserProfile();
    // The currently selected tab, used to filter the templates by shared/public/etc. state.
    const [tabID, setTabID] = useState(1);
    // States used to filter the templates further.
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");

    const [filteredTemplates, setFilteredTemplates] = useState([]);

    const { show, hide, visibilityStates } = useTransientVisibility(); 

    const removeDuplicatesAndSort = (templates) => {
        if (!Array.isArray(templates)) return [];
        const uniqueTemplatesMap = new Map();
        templates.forEach(template => {
            if (template && template.id) {
                uniqueTemplatesMap.set(template.id, template);
            }
        });
        
        const uniqueTemplates = [...uniqueTemplatesMap.values()];
        uniqueTemplates.sort((a, b) => {
            const aTitle = typeof a.title === "string" ? a.title : "";
            const bTitle = typeof b.title === "string" ? b.title : "";
            return aTitle.localeCompare(bTitle);
          });
        return uniqueTemplates;
    };

    const handleIconPress = (event) => {
        event.preventDefault();
        searchTemplates(searchText);
    }

    // Logic to reset values and set the document title accordingly.
    useEffect(() => {
        setChatTemplateId(undefined);
        setChatTemplate({});
        document.title = "Maestro - Templates"
    }, [])

    // Logic to handle the shown chat templates based on the filter specified by the drawer on the left side of the screen.
    useEffect(() => {
        if (
            !userProfile ||
            !userProfile.userProfileId ||
            !Array.isArray(chatTemplates)
        ) {
            setFilteredTemplates([]);
            return;
        }
        
        if (tabID === 1) {
            // Tab 1: My Templates - Show user's own templates AND templates shared with them
            const userTemplates = chatTemplates.filter((template) => 
                // Templates owned by the user
                template?.userProfileId === userProfile.userProfileId ||
                // Templates shared with the user (via accesses array)
                template?.accesses?.some(access => 
                    access.userProfile.userProfileId === userProfile.userProfileId
                )
            );
            
            setFilteredTemplates(removeDuplicatesAndSort(userTemplates));
        }
        else if (tabID === 2) {
            // Tab 2: Shared With Me - Templates where user has access but is NOT the owner
            const sharedTemplates = chatTemplates.filter((template) => 
                // User is NOT the owner
                template?.userProfileId !== userProfile.userProfileId && 
                // User has access to the template
                template?.accesses?.some(access => 
                    access.userProfile.userProfileId === userProfile.userProfileId
                )
            );
            setFilteredTemplates(removeDuplicatesAndSort(sharedTemplates));
        }
        else {
            const publishedTemplates = chatTemplates.filter((template) => template?.isPublished);
            setFilteredTemplates(removeDuplicatesAndSort(publishedTemplates));
        }
    }, [chatTemplates, tabID, userProfile])

    useEffect(() => {
        // A check to update pagination as needed - if we're on a page that no longer exists, go back one.
        if (page > 1 && chatTemplates.length <= (page - 1) * 10) {
            setPage(page - 1);
        }
    }, [chatTemplates])

    return (
        <>
            <div className="o-page-section l-mt-md l-pt-none">
                <div className="o-page-section__content-container o-page-section__content-container--med-wide-fixed-width l-pt-none l-mt-neg-sm">
                    <div className="l-row l-pt-sm">
                        <Drawer 
                            className="o-drawer--left-narrow col-md-3"
                            type="hybrid"
                            header={
                                <div className="o-drawer-heading-row">
                                    <div className="o-drawer-title">
                                        <Heading size={3}>Chat Templates</Heading>
                                    </div>
                                </div>
                            }
                            children={
                                <nav className="m-vertical-tabs">
                                    <ul className="m-vertical-tabs__list" role="tablist" aria-orientation="vertical">
                                        <MenuOption id={1} name="My Templates" isActive={tabID === 1} onClick={() => setTabID(1)} SVGObject={IconPerson}/>
                                        <MenuOption id={2} name="Shared With Me" isActive={tabID === 2} onClick={() => setTabID(2)} SVGObject={IconUsers}/>
                                        <MenuOption id={3} name="Public Templates" isActive={tabID === 3} onClick={() => setTabID(3)} SVGObject={IconBuilding}/>   
                                    </ul>
                                </nav>
                            }
                        />
                        <div className="col-md-9">
                            {/* TODO: Conditionally render based off of tabID */}
                            <HeadingRow
                                title={"My Templates"}
                            >
                                <Button
                                    children="Create New Template"
                                    onClick={() => navigateToTemplateForm()}
                                />
                            </HeadingRow>
                            <p className="a-muted-text">
                                {"Templates allow you to quickly begin a chat with Maestro in a preset way. These can be used to streamline repetitive processes and get straight to the good part! " + 
                                    (
                                        tabID === 1 ?  "There are also a number of helpful public templates you can use to begin a new chat." :
                                        tabID === 2 ?  "These are the templates that were shared with you." :
                                        tabID === 3 && "You can create your own, or have them be shared with you."   
                                    )
                                }
                            </p>
                            <div className="row">
                                <div className="col-md-12">
                                    <form className="a-search-input l-mb-xs l-mt-xs">
                                        <input 
                                            className="a-search-input__text-box"
                                            type="text"
                                            placeholder="Search templates..."
                                            id="search-field"
                                            name="search-field"
                                            value={searchText || ''}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                        <button 
                                            className="a-search-input__button"
                                            type="submit"
                                            onClick={handleIconPress}
                                        >
                                            <img className="a-search-input__img" src={ImageSearch} />
                                        </button>
                                    </form>
                                    <div className="o-block l-mt-xs">
                                        { (isLoading && chatTemplates.length === 0) ?
                                            <div className="text-center" aria-colspan={4}>
                                                <RippleLoading className="a-icon__img" style={{maxHeight: 50}}/>
                                                <p className="a-muted-text">Loading available chat templates...</p>   
                                            </div> :
                                            <>
                                                { (filteredTemplates.length === 0) ? 
                                                    <div className="text-center" aria-colspan={4}>
                                                        <p className="a-muted-text">
                                                            {
                                                                tabID === 1 ?  "You have no chat templates that fit this criteria. Create one using the button above!" :
                                                                tabID === 2 ?  "There are no chat templates shared with you that fit this criteria." :
                                                                tabID === 3 && "There are currently no public templates available that fit this criteria."
                                                            }
                                                        </p>   
                                                    </div> :
                                                    <>
                                                        <table id="chatTemplates" className="o-treegrid o-treegrid--compact l-mr-sm">
                                                            <colgroup className="o-treegrid__colgroup">
                                                                <col span="1" className="o-treegrid__col o-treegrid__col--xxx-wide" />
                                                                <col span="1" className="o-treegrid__col o-treegrid__col--small" />
                                                                <col span="1" className="o-treegrid__col o-treegrid__col--medium" />
                                                            </colgroup>
                                                            <tbody>
                                                                {/* Take a slice based on the current page. If we're at the end of the list, we don't want to access indexes out of bounds. */}
                                                                {filteredTemplates.slice((page - 1) * 10, Math.min(filteredTemplates.length, page * 10)).map((chatTemplate) => {
                                                                    return (
                                                                        <Template 
                                                                            chatTemplateObj={chatTemplate}
                                                                            key={chatTemplate.id}
                                                                        />
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        <Pagination 
                                                            numPerPage={10}
                                                            data={chatTemplates}
                                                            currentPage={page}
                                                            onPageChange={(page) => setPage(page)}
                                                        />
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RenameTemplateModal
                isHidden={ !visibilityStates.RenameTemplateModal }
                onClose={() => {
                    hide('RenameTemplateModal')
                    setChatTemplateId(undefined);
                }}
            />
            <DeleteTemplateModal 
                isHidden={ !visibilityStates.DeleteTemplateModal }
                onClose={() => {
                    hide('DeleteTemplateModal');
                    setChatTemplateId(undefined);
                }}
            />
            <ShareTemplateDrawer 
                isHidden={ !visibilityStates.ShareTemplateDrawer }
                onClose={ () => { 
                    hide('ShareTemplateDrawer');
                    setChatTemplateId(undefined); 
                }} 
            />
        </>
    )
}

export default SymphonyTemplates;
// Package imports
import { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";

// Modals and Drawers
import AttachFileModal from "./overlay/attachFileModal";
import AuditLogDrawer from "./overlay/auditLogDrawer";
import ChatHistoryDrawer from "./overlay/chatHistoryDrawer";
import ChatSettingsDrawer from "./overlay/chatSettingsDrawer";
import DataSourceDrawer from "./overlay/dataSourceDrawer";
import DeleteChatModal from "./overlay/deleteChatModal";
import EulaModal from "./overlay/eulaModal";
import ExportChatModal from "./overlay/exportChatModal";
import ExportChatTracker from "./overlay/exportChatTracker";
import PlagiarismDisclaimerModal from "./overlay/plagiarismDisclaimerModal";
import PlagiarismSnackbar from "./components/PlagiarismSnackbar";
import RenameChatModal from "./overlay/renameChatModal";
import ShareChatDrawer from "./overlay/shareChatDrawer";
import UploadActivityTracker from "./overlay/uploadActivityTracker"

// Pages
import SymphonyMainView from "./symphonyMainView";
import SymphonyDataStores from "./dataStores/symphonyDataStores";
import SymphonyDataStoreView from "./dataStores/symphonyDataStoreView";
import TemplateForm from "./chatTemplates/symphonyTemplateForm";
import SymphonyTemplates from "./chatTemplates/symphonyTemplates";

// Misc
import UnlicensedModal from "./overlay/unlicensedModal";
import { useCompliance } from "./contexts/ComplianceContext";
import { useLicensing } from "./contexts/LicenseContext";
import { useResource } from "./contexts/ResourceContext";
import { useChat } from "./contexts/ChatContext";
import ProviderFactory, { symphonyAppProviders } from "../../utilities/ProviderFactory";
import { useUserProfile } from "./contexts/UserProfileContext";
import LeaveSharedChatModal from "./overlay/leaveChatModal";
import DnDFileUpload from "../fusion/FileUpload/DragAndDrop";

const SymphonyApp = () => {

    const { agreedToEULA, agreeToEULA, setAgreedToEULA, outstandingEULA } = useCompliance();
    const { isLicensedEnv, hasLicense } = useLicensing();
    const loadingUserProfile = useUserProfile();
    const SymphonyAppPage = () => {

        // These are used for the file upload activity tracker.
        const { isExporting, processUrlParameters } = useChat();
        const { uploadingFilesCount, isUploading, handleFilesUpload } = useResource();

        const [lockInput, setLockInput] = useState(false);
        const [editingMessageId, setEditingMessageId] = useState(null);

        const params = useParams();

        // This code is only necessary to run when the page is loading for the first time.
		// Why are we doing it here? Because this is in the scope of the router component, so it can see the field as 'ChatId'.
        useEffect(() => {
            processUrlParameters(params.ChatId);
        }, [])

        return (
            <>
                <div className="o-page-section o-page-section l-mt-md l-pt-none">
                    <div className="o-page-section__content-container o-page-section__content-container--med-wide-fixed-width l-pt-none l-mt-neg-sm">
                        <div className="l-row l-pt-sm">
                            <ChatHistoryDrawer />
                            {/* <div className="col-md-9 l-pb-xl">  */}
                            <div className="col-md-9">
                                <SymphonyMainView
                                    lockInput={lockInput}
                                    editingMessageId={editingMessageId}
                                    setEditingMessageId={setEditingMessageId}
                                />
                                <DnDFileUpload 
                                    omni={true}
                                    onFileUpload={handleFilesUpload} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                <ChatSettingsDrawer />
                
                <DataSourceDrawer />
                
                <ShareChatDrawer />
                
                <AttachFileModal />
                
                <AuditLogDrawer />
                
                <DeleteChatModal />

                <LeaveSharedChatModal />
                
                <RenameChatModal />
                
                <PlagiarismSnackbar />

                <PlagiarismDisclaimerModal />
                
                <ExportChatModal />
                
                <UploadActivityTracker 
                    isHidden={!isUploading}
                    numFiles={uploadingFilesCount}
                />
                
                <ExportChatTracker
                    isHidden={!isExporting}
                />
                
                {outstandingEULA &&
                    <EulaModal
                        content={outstandingEULA.text}
                        onAccept={agreeToEULA}
                        onDecline={() => setAgreedToEULA(false)}
                    />
                }
                {(isLicensedEnv && !hasLicense && !loadingUserProfile) &&
                    <UnlicensedModal />
                }

            </>
        )
    }

    const ChatContextElements = agreedToEULA && (

        <ProviderFactory providers={symphonyAppProviders}>
            <Routes>
                <Route path="data">
                    <Route index element={<SymphonyDataStores />} />
                    <Route path=":DataStoreId?/*" element={<SymphonyDataStoreView />} />
                </Route>
                <Route path="templates">
                    <Route index element={<SymphonyTemplates />} />
                    <Route path="new" element={<TemplateForm isEditing={false} />} />
                    <Route path=":TemplateId?/*" element={<TemplateForm isEditing={true} />} />
                </Route>
                <Route path=":ChatId?/*" element={<SymphonyAppPage />} />
            </Routes>
        </ProviderFactory>
    );

    return ChatContextElements

};

export default SymphonyApp;
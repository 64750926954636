import React, { useState, Fragment, useEffect } from "react";
import EditableValueInput from "../fusion/Input/EditableValueInput"
import { ReactComponent as IconEdit } from "../../images/icon-draft.svg"
import { getMsGraphPhotoUrl, getMsGraphUserPhotoUrl } from "../../services/graph";
import { useAuthentication } from "../../contexts/AuthContext";

const annualizedHours = 1880;

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

var hourlyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});
const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2
});


export const MeritChangeRow = (props) => {

  const { graphAccessToken } = useAuthentication();

  const [imgUrl, setImgUrl] = useState("");
  // const [isChecked, setIsChecked] = useState(props.data.selected ? props.data.selected : false);
  const [isChecked, setIsChecked] = useState(false);

  const [proposedBase, setProposedBase] = useState("Not Specified");
  const [increasePercentage, setIncreasePercentage] = useState("Not Specified");
  const [increaseAmount, setIncreaseAmount] = useState("Not Specified");

  const [ftePct, setFtePct] = useState(1.0);
  const [currentBase, setCurrentBase] = useState(0);
  const [compGrade, setCompGrade] = useState("");
  const [seeAll, setSeeAll] = useState(props.seeAll && (props.assignedTo?.toLowerCase() == "tom.salvaggio@cai.io"));

  const onIncreasePercentage = (newPercentage) => {
    let newIncAmt = (currentBase * (newPercentage / 100));
    let newPropBase = currentBase + newIncAmt;
    setIncreaseAmount(formatter.format(newIncAmt));
    setIncreasePercentage(percentFormatter.format(newPercentage / 100));
    setProposedBase(formatter.format(newPropBase));
    newPropBase = compGrade == "hourly" ? (newPropBase / (annualizedHours * ftePct)) : newPropBase;
    props.onSave(Number(newPercentage), props.data.id, props.assignedTo, props.data.upn, newPropBase);
  }
  const onIncreaseAmount = (newIncAmt) => {
    let newPropBase = currentBase + newIncAmt;
    let newPercentage = newIncAmt / currentBase;
    setIncreaseAmount(formatter.format(newIncAmt));
    setIncreasePercentage(percentFormatter.format(newPercentage));
    setProposedBase(formatter.format(newPropBase));
    newPropBase = compGrade == "hourly" ? (newPropBase / (annualizedHours * ftePct)) : newPropBase;
    props.onSave(Number(newPercentage * 100), props.data.id, props.assignedTo, props.data.upn, newPropBase);
  }
  const onProposedBase = (newPropBase) => {
    let newIncAmt = newPropBase - currentBase;
    let newPercentage = newIncAmt / currentBase;
    setIncreaseAmount(formatter.format(newIncAmt));
    setIncreasePercentage(percentFormatter.format(newPercentage));
    setProposedBase(formatter.format(newPropBase));
    newPropBase = compGrade == "hourly" ? (newPropBase / (annualizedHours * ftePct)) : newPropBase;
    props.onSave(Number(newPercentage * 100), props.data.id, props.assignedTo, props.data.upn, newPropBase);
  }
  const checkboxOnChangedHandler = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    // gets called on first load and anytime props.data UDPDATES  
    let localFtePct = props.data.ftePercentage ?? 1.0;
    // the data is called proposedBase but its actually storing the Percentage Increase
    let localPercentageIncrease = props.data.proposedBaseComp ?? 0.0;
    let localCurrentBase = props.data.curBaseComp ?? 0.0;
    // then use percentage increase to find proposed base comp so we can work with that in our calculations
    let localProposedBaseComp = (localCurrentBase * (localPercentageIncrease / 100)) + localCurrentBase; 

    // func here checks if employee is hourly or salary and works to make the necessary calculations
    let localCurCompGrade = props.data?.curCompGrade == "Hourly" ? "hourly" : "salary";
    if (localCurCompGrade === "hourly") {
      localCurrentBase = localCurrentBase * annualizedHours * localFtePct;
      if (localProposedBaseComp !== undefined) {
        localProposedBaseComp = localProposedBaseComp * annualizedHours * localFtePct;
      }
    }
    let localAddedAmount = localProposedBaseComp - localCurrentBase;
    
    setFtePct(localFtePct);
    setCurrentBase(localCurrentBase);
    setCompGrade(localCurCompGrade);
    
    // looks for employees who have already been reviewed and shows there values even if no increase
    // happened, rather than displaying 'not specified'
    if (props.data.reviewed === true) {
      setProposedBase(formatter.format(localProposedBaseComp));
      if (localPercentageIncrease > 0)
        setIncreasePercentage(percentFormatter.format(localPercentageIncrease / 100));
      else
        setIncreasePercentage(percentFormatter.format(0.0));
      setIncreaseAmount(formatter.format(localAddedAmount));
    }
  }, [props.data])

  useEffect(() => {
    if (props.parentIsChecked !== undefined) {
      // console.log("Parent Changed")
      setIsChecked(props.parentIsChecked);
    }
  }, [props.parentIsChecked]);

  useEffect(() => {
    if (props.onChange) {
      // console.log("Child Changed");
      props.onChange(isChecked);
    }
  }, [isChecked]);

  useEffect(() => {
    async function fetchData() {
      let newImgUrl = `https://ui-avatars.com/api/?name=${props.data.employeeName}}`
      if (props.data.upn) {
        try {
          const photoUrl = await getMsGraphUserPhotoUrl(graphAccessToken, props.data.upn);
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(photoUrl);
          newImgUrl = blobUrl;
        }
        catch (error) {
          console.log("Error getting photo of user");
          newImgUrl = `https://ui-avatars.com/api/?name=${props.data.employeeName}`
        }
      }

      setImgUrl(newImgUrl);
    }
    fetchData();
  }, [props.data, graphAccessToken]) // Added graphAccessToken to the dependency array

  // const currentBonus = props.data.currentBonus ?? 0.0;
  // let currentCommission = props.data.currentCommission ?? 0.0;
  // const proposedBonus = props.data.proposedBonus ?? 0.0;
  // let proposedCommission = props.data.proposedCommission ?? 0.0;


  // let parentAssignedUpn = props.parentAssignedUpn;


  // const currentTotalComp = currentBase + currentBonus + currentCommission;

  // let proposedTotalComp = undefined;
  // if (proposedBase !== undefined) {
  //   proposedTotalComp = proposedBase + proposedBonus + proposedCommission;
  // }

  return (
    <tr className={`o-treegrid__row o-treegrid__row--shown${props.data.isEligible && props.data.assignedToUser ? '' : ' o-treegrid__row--striped-bg'}`}>
      <td className="o-treegrid__cell o-treegrid__cell--centered">
        {/* <label className="a-checkbox a-checkbox--hidden-label">
          <span>Select [employee Name]</span>
          <input type="checkbox" />
          <div className="b-input"></div>
        </label> */}
        {/* <Checkbox className="a-checkbox--hidden-label" checked={props.data.selected || props.parentSelected} onChange={checkedChangeHandler} disabled={!props.data.isEligible || props.data.assignedToUser)} /> */}
        <label className={`a-checkbox a-checkbox--hidden-label`}>
          <span>Select {props.data.employeeName}</span>
          <input
            type="checkbox"
            checked={props.data.isEligible && props.data.assignedToUser && isChecked}
            onChange={checkboxOnChangedHandler}
            disabled={!props.data.isEligible || !props.data.assignedToUser}
          />
          <div className="b-input"></div>
        </label>
      </td>
      <td className="o-treegrid__cell">
        <div className="m-subject-highlight">
          <div className="a-avatar m-subject-highlight__avatar">
            <img
              src={imgUrl}
              alt=""
              className="a-avatar__img"
            />
            {/* <img src={`data:image/jpeg;base64,${imgUrl}`} className="a-avatar__img" /> */}
          </div>
          <div className="m-subject-highlight__content">
            <p className="a-bold-text m-subject-highlight__title">
              {props.data.employeeName}
            </p>
            <p className="a-muted-text m-subject-highlight__subtitle">
              {props.data.jobTitle}
            </p>
            <p className="a-accent-text m-subject-highlight__meta">{props.data.employeeId}</p>
          </div>
        </div>
      </td>

      {/* Perf Score */}
      <td className="o-treegrid__cell">{props.data.lastReviewLevel ? props.data.lastReviewLevel : (<span className="a-muted-text">None</span>)}</td>
      {/* Current Base */}
      {/* <td className="o-treegrid__cell">{compGrade == "hourly" ? hourlyFormatter.format(currentBase) : formatter.format(currentBase)}</td> */}
      <td className="o-treegrid__cell">{formatter.format(currentBase)}
        {compGrade == "hourly" ? <>
          <br></br>
          <span class="a-subtle-text a-muted-text">{hourlyFormatter.format(props.data.curBaseComp)}/hr</span>
        </>
          : <></>
        }
      </td>
      {(props.data.isEligible && (props.data.assignedToUser || seeAll)) ? (<>
        {/* // (proposedBase !== undefined && props.data.reviewed === true) ? (<> */}
        {/* Increase Percentage */}
        <td className="o-treegrid__cell">
          {seeAll ?
            <>{increasePercentage}</> :
            (<EditableValueInput
              initialValue={increasePercentage === "Not Specified" || increasePercentage === undefined ? "" : increasePercentage}
              onValueChange={(newValue) => onIncreasePercentage(Number(newValue))}
              validate={(value) => !isNaN(value) && value >= 0}
              onInvalid={() => alert("Invalid Value")}
              title="Modify Increase Percentage"
              unitType="percent"
            >
              {increasePercentage} <IconEdit className="a-editable-value__icon" />
            </EditableValueInput>
            )}
        </td>
        {/* Increase Amount */}
        <td className="o-treegrid__cell">
          {seeAll ?
            <>{increaseAmount}</> :
            (<EditableValueInput
              initialValue={increaseAmount === "Not Specified" || increaseAmount === undefined ? "" : increaseAmount}
              onValueChange={(newValue) => onIncreaseAmount(Number(newValue))}
              validate={(value) => !isNaN(value) && value >= 0}
              onInvalid={() => alert("Invalid Value")}
              title="Modify Increase Amount"
              unitType="dollar"
            >
              {increaseAmount} <IconEdit className="a-editable-value__icon" />
            </EditableValueInput>
            )}
        </td>
        {/* Proposed Base */}
        <td className="o-treegrid__cell">
          {seeAll ?
            <>{proposedBase}</> :
            (<EditableValueInput
              initialValue={proposedBase === "Not Specified" || proposedBase === undefined ? "" : proposedBase}
              onValueChange={(newValue) => onProposedBase(Number(newValue))}
              validate={(value) => !isNaN(value) && value >= 0}
              onInvalid={() => alert("Invalid Value")}
              title="Modify Proposed Base"
              unitType="dollar"
            >
              {proposedBase} <IconEdit className="a-editable-value__icon" />
            </EditableValueInput>
            )}
        </td>
      </>)
        // :
        //   (<td className="o-treegrid__cell" colSpan={3}>
        //     <span className="a-warning-text">No increase specified</span>
        //   </td>)
        :
        (!props.data.assignedToUser && !seeAll) ?
          (
            <td className="o-treegrid__cell" colSpan={3}>
              <strong className="">
                {`This increase is assigned to ${props.parentAssignedUpn}`}</strong>
            </td>
          ) :
          (
            <td className="o-treegrid__cell" colSpan={3}>
              <strong className="">
                {!props.data.isEligible ? "Not eligible for merit increase" : ""}</strong> {props.data.eligibilityNotes && props.data.eligibilityNotes}
            </td>
          )
      }
      <td className="o-treegrid__cell o-treegrid__cell--actions">
        {props.data.isEligible && (props.data.assignedToUser || (seeAll)) &&
          <a
            href="#"
            className="a-button a-button--outline a-button--small o-treegrid__hidden-button"
            onClick={(e) => {
              e.preventDefault();
              props.onDetailsClicked && props.onDetailsClicked();
            }}
          >
            Details
          </a>}
      </td>
    </tr>
  );
};

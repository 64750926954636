import { useEffect } from "react";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";
import FileDisplay from "../components/symphonyFileDisplay";
import { Modal } from "cai-fusion";

import { useResource } from "../contexts/ResourceContext";
import { useTransientVisibility } from "../../fusion/Contexts/VisualContext";
const AttachFileModal = () => {

    const { uploadComplete, setUploadComplete, handleFilesUpload } = useResource();
    const { hide, visibilityStates } = useTransientVisibility();

    const closeClickedHandler = () => {
        hide('AttachFileModal');
    };

    useEffect(() => {
        // Close the modal if the upload was successful
        if (uploadComplete) {
            closeClickedHandler();
            setUploadComplete(false);
        }
    }, [uploadComplete]); 

    return (
        <Modal
            title="Upload Files"
            isHidden={ !visibilityStates.AttachFileModal }
            onClose={() => closeClickedHandler()}
            id="uploadModal"
            clickOutsideToClose = { true }
        >
            <div className="l-row">
                <div className="l-one-col">
                    <p>
                        To allow Maestro to better answer your questions, you may upload
                        files such as documents or spreadsheets. Your files will only
                        be used to improve the quality of this chat session and will
                        be securely stored.
                    </p>
                    <p className="a-muted-text">
                        Supported files include .pdf, .docx, .xlsx, .pptx, .txt, .csv,
                        .jpg, .jpeg, .png, as well as any code or text-based file
                        types such as .cs, .jsx, .js, .ts, .tsx, .java, .py, .html,
                        .xml, .json, .sh, .pl, .rb, .cfg, .conf, .ini, .properties,
                        .log, .md, .yaml, .yml, and .sql.
                    </p>
                    <FileDisplay />
                    <DnDFileUpload
                        onFileUpload={handleFilesUpload}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default AttachFileModal;

import { useEffect, useRef } from "react";
import ChatEntry from "./ChatEntry";
import ChatSuggestions from "./ChatSuggestions";
import { ReactComponent as IconAttach } from "../../../images/icon-attach.svg";
import LoadingTips from "../../symphony/components/loadingTips";
import { useMessages } from "../../symphony/contexts/MessageContext";
import { useUserProfile } from "../../symphony/contexts/UserProfileContext";
import { useAccess } from "../../symphony/contexts/AccessContext";
import { useChat } from "../../symphony/contexts/ChatContext";

const Chat = ({
  chatEntryActions = {},
  iconElements,
  editingMessageId,
  setEditingMessageId,
}) => {
  const messagesStartRef = useRef(null);
  const messagesEndRef = useRef(null);
  const { canSendMessage, chatMembers } = useAccess();
  const { loadingChat } = useChat();
  const { messages, editMessage, sendMessage, chatSuggestions, assistantThinking } = useMessages();
  const { compileNameHelper } = useUserProfile();

  const userMessageRef = useRef(null);

  const scrollToUserMessage = () => {
    userMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!editingMessageId) scrollToUserMessage();
  }, [messages]);

  const handleTipAction = (action, tip) => {
    console.log(`User clicked ${action} on tip: `, tip);
  };

  const renderImageContent = (message, messageText) => {
    const imageContentItem = message.content.find(
      (contentItem) =>
        contentItem.type === "image_bytes" || contentItem.type === "imageUrl"
    );

    const imageSrc = imageContentItem
      ? imageContentItem.type === "image_bytes"
        ? `data:image/jpeg;base64,${imageContentItem.imageBytes}`
        : imageContentItem.imageUrl
      : null;

    return (
      imageSrc && (
        <button className="m-chat-entry__image-wrapper" disabled="">
          <img
            className="m-chat-entry__image"
            src={imageSrc}
            alt={imageContentItem.imageTextDescription ?? messageText}
          />
        </button>
      )
    );
  };

  const renderEventMessage = (message, messageText) => {
    let eventMessage = messageText;

    if (message.role.includes("datasource")) {
      eventMessage = (
        <>
          <IconAttach className="m-chat-event__icon" />
          <strong>{messageText}</strong>{" "}
          {messageText.split(",").length > 1 ? "were" : "was"}{" "}
          {message.role.startsWith("info") ? "included in" : "removed from"} the chat{" "}
          {chatMembers.length > 1 ? `by ${compileNameHelper(chatMembers.find(x => x.userProfileId === message.userProfileId)?.userProfile) ?? "another member."}` : ""}.
        </>
      );
    }

    return (
      <div
        className={`m-chat-event${message.role.startsWith("warn") ? " m-chat-event--removal" : ""}`}
        key={message.messageId}
      >
        <hr className="m-chat-event__line" />
        <p className="m-chat-event__label">{eventMessage}</p>
      </div>
    );
  };

  return (
    <div className="o-chat" style={{ paddingBottom: chatSuggestions?.length > 0 ? "0px" : "256px" }}>
      <div ref={messagesStartRef}></div>
      {loadingChat ? (
        <>
          {[...Array(4)].map((_, index) => (
            <ChatEntry
              key={index}
              isLoading
              chatEntryActions={[]}
              IconElement={iconElements["assistant"]}
              content={[{ type: "test" }]}
              role={index % 2 === 0 ? "user" : "assistant"}
            />
          ))}
        </>
      ) : (
        messages.sort((a, b) => a.messageDate - b.messageDate).map((message, index) => {
          const messageTexts = message.content
            .filter((contentItem) => contentItem.type === "text")
            .sort((a, b) => a.contentOrder - b.contentOrder)
            .map((contentItem) => contentItem.text);
          const messageText = messageTexts.join("\n");

          const isUserMessage = message.role === "user"; // Assuming "user" is the role for user messages
          
          if (isUserMessage) {
            return (
              <div ref={userMessageRef} key={message.messageId}>
                <ChatEntry
                  messageId={message.messageId}
                  messagePairId={message.messagePairId}
                  role={message.role}
                  content={message.content}
                  statusMessage={message.statusMessage}
                  IconElement={iconElements ? iconElements[message.role] : null}
                  isLoading={message.isLoading}
                  chatEntryActions={chatEntryActions[message.role] || []}
                  userProfileId={message.userProfileId}
                  isEditing={message.messageId === editingMessageId}
                  setEditingMessageId={setEditingMessageId}
                  resendMessage={editMessage}
                />
              </div>
            );
          } else if (message?.role?.startsWith("info") || message?.role?.startsWith("warn")) {
            if (message.role === "info-datasource-image") {
              return (
                <div className="m-chat-entry" key={index}>
                  <div className="m-chat-entry__avatar-wrapper">
                    <div className="a-avatar">
                      {iconElements ? iconElements["user"](null, message.userProfileId) : null}
                    </div>
                  </div>
                  <div className="m-chat-entry__message-wrapper">
                    <div className="m-chat-entry__message">
                      {renderImageContent(message, messageText)}
                    </div>
                  </div>
                </div>
              );
            } else {
              return renderEventMessage(message, messageText);
            }
          } else {
            return (
              <ChatEntry
                key={message.messageId}
                messageId={message.messageId}
                messagePairId={message.messagePairId}
                role={message.role}
                content={message.content}
                statusMessage={message.statusMessage}
                IconElement={iconElements ? iconElements[message.role] : null}
                isLoading={message.isLoading}
                chatEntryActions={chatEntryActions[message.role] || []}
                userProfileId={message.userProfileId}
                isEditing={message.messageId === editingMessageId}
                setEditingMessageId={setEditingMessageId}
                resendMessage={editMessage}
              />
            );
          }
        })
      )}
      <LoadingTips isLoading={assistantThinking} onTipAction={handleTipAction} />
      {canSendMessage && chatSuggestions?.length > 0 && (
        <ChatSuggestions
          suggestions={chatSuggestions}
          onSuggestionSelected={sendMessage}
        />
      )}
      <div ref={messagesEndRef}></div>
    </div>
  );
};

export default Chat;

import React, { createContext, useRef, useState, useCallback, useContext, useReducer, useEffect, useMemo, ReactNode, ReactElement } from 'react';

interface VisibilityState {
  [key: string]: boolean;
}

interface ContextValue {
  componentNames: string[];
  addComponentNames: (names: string[]) => void;
  visibilityStates: VisibilityState;
  show: (key: string) => void;
  hide: (key: string) => void;
}

interface Action {
  type: 'SHOW' | 'HIDE';
  payload: string;
}

const TransientVisibilityContext = createContext<ContextValue | undefined>(undefined);

const reducer = (state: VisibilityState, { type, payload }: Action): VisibilityState => {
  switch (type) {
    case 'SHOW':
      return { ...state, [payload]: true };
    case 'HIDE':
      return { ...state, [payload]: false };
    default:
      return state;
  }
};

interface TransientVisibilityProviderProps {
  children: ReactNode;
}

interface TransientComponentsProps {
  children: ReactNode;
}

export const TransientVisibilityProvider: React.FC<TransientVisibilityProviderProps> = ({ children }) => {
  const [componentNames, setComponentNames] = useState<string[]>([]);
  const [visibilityStates, dispatch] = useReducer(reducer, {});

  const addComponentNames = useCallback((names: string[]) => {
    setComponentNames((prevNames) => {
      const newNames = names.filter(name => !prevNames.includes(name));
      newNames.forEach(name => {
        if(!(name in visibilityStates)){
          dispatch({ type: 'HIDE', payload: name});
        }
      })
      return [...prevNames, ...newNames];
    });
  }, [visibilityStates]);

  const show = useCallback((key: string) => {
    dispatch({ type: 'SHOW', payload: key });
  }, []);

  const hide = useCallback((key: string) => {
    dispatch({ type: 'HIDE', payload: key });
  }, []);

  const values = useMemo(() => ({
    componentNames,
    addComponentNames,
    visibilityStates,
    show,
    hide
  }), [componentNames, visibilityStates, show, hide]);

  return (
    <TransientVisibilityContext.Provider value={values}>
      {children}
    </TransientVisibilityContext.Provider>
  );
};

const useTransientVisibility = (): ContextValue => {
  const context = useContext(TransientVisibilityContext);
  if (!context) {
    throw new Error('useTransientVisibility must be used within a TransientVisibilityProvider');
  }
  return context;
};

// check if a ReactNode is a ReactElement with a component type
const isReactElementWithComponentType = ( element: ReactNode): 
  element is ReactElement & { type: { name: string } } => {
    return (
      React.isValidElement(element) &&
      typeof element.type === 'function' &&
      (element.type as { name?: string }).name !== undefined
    );
}

const getDescendantComponentNames = (children: ReactNode): Set<string> => {
  const names = new Set<string>();
  
  const traverse = (element: ReactNode) => {
    React.Children.forEach(element, child => {
      if(isReactElementWithComponentType(child)){
        names.add(child.type.name);
        if(child.props.children){
          traverse(child.props.children);
        }
      }
    });
  }

  traverse(children);
  return names;
}

// Wrapper component to extract children names and store visibility states
export const TransientComponents: React.FC<TransientComponentsProps> = ({ children }) => {
  const { addComponentNames } = useTransientVisibility();

  const componentNamesSet = useMemo(() =>
    getDescendantComponentNames(children),
    [children]
  );

  const componentNames = useMemo(() => 
    Array.from(componentNamesSet),
    [componentNamesSet]
  )

  useEffect(() => {
    addComponentNames(componentNames);
  }, [componentNames, addComponentNames])

  return <>{children}</>;
};
export { useTransientVisibility };
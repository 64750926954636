import { useEffect, useRef } from "react";
import { Eyebrow } from "cai-fusion";
import ChatTemplates from "../fusion/Chat/ChatTemplates";
import SymphonyChatInput from "./components/symphonyChatInput";
import SymphonyChat from "./components/symphonyChat";
import LeaveSharedChat from "../fusion/Chat/LeaveSharedChat";

// Contexts
import { useMessages } from "./contexts/MessageContext";
import { useAccess } from "./contexts/AccessContext";
import { useNewTemplate } from "./contexts/NewTemplateContext";
import { useChat } from "./contexts/ChatContext";
import { useTransientVisibility } from "../fusion/Contexts/VisualContext";

const SymphonyMainView = ({
    lockInput,
    editingMessageId,
    setEditingMessageId,
}) => {

    const { messages } = useMessages();
    const { canSendMessage } = useAccess();
    const { isChatInContext } = useChat();
    const { chatTemplate } = useNewTemplate();
    const { show } = useTransientVisibility();

    if (isChatInContext() || (messages && messages.length > 0)) {
        return (
            <>
                <Eyebrow>Beginning of chat</Eyebrow>
                <SymphonyChat
					lockInput = {lockInput}
					editingMessageId = {editingMessageId}
					setEditingMessageId = {setEditingMessageId}
				/>
                {canSendMessage ? (
                    <SymphonyChatInput disableInput={lockInput} />
                ) : (
                    <LeaveSharedChat onLeaveChat={() => {show("LeaveSharedChatModal")}} /> 
                )}
			</>
		);
	} else {
		return (
			<>
				{
                    <>
					<ChatTemplates/> {!chatTemplate && <SymphonyChatInput disableInput={lockInput}/>}
                    </>
					
				}
			</>
		);
	}
};

const ResizingInput = ({ placeholder, value, onChange }) => {
    const inputRef = useRef(null);
    const textWidthRef = useRef(null);

    const handleInputChange = (event) => {
        textWidthRef.current.textContent = event.target.value;
        const newWidth =
            textWidthRef.current.getBoundingClientRect().width + 10;
        inputRef.current.style.width = `${newWidth}px`;
        onChange(event); // Pass event to parent handler
    };

    useEffect(() => {
        const newWidth =
            textWidthRef.current.getBoundingClientRect().width + 10;
        inputRef.current.style.width = `${newWidth}px`;
    }, [value]);

    return (
        <>
            <input
                ref={inputRef}
                type="text"
                placeholder={placeholder}
                className="o-nl-form__text-input"
                value={value}
                onChange={handleInputChange}
            />
            <div style={{ display: "none" }} ref={textWidthRef}>
                {value}
            </div>
        </>
    );
};

export default SymphonyMainView;

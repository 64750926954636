import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Button, ButtonRow, ChatBubble, Heading, TabRow } from "cai-fusion";
import { useNewTemplate } from "../contexts/NewTemplateContext";

import TemplateBasics from "./form/templateBasics";
import TemplateIncludedData from "./form/templateIncludedData";
import TemplateShortcuts from "./form/templateShortcuts";
import SymphonyFileHandler from "../components/symphonyFileHandler";

const avatarAI = require("../../../images/avatar-ai.png");

const TemplateForm = ({isEditing}) => {
    // The current viewed tab on the left-hand side of the page.
    const [tabID, setTabID] = useState("basics");
    const params = useParams();

    const { chatTemplate, setChatTemplate, chatTemplateId, setChatTemplateId, leaveTemplateForm, executeTemplate, createTemplate, updateTemplate } = useNewTemplate();

    useEffect(() => {
        console.log("[TEMPLATE] Current chat template:", chatTemplate)
        document.title = "Chat Templates - " + chatTemplate?.title
    }, [chatTemplate])

    useEffect(() => {
        if (chatTemplateId != params.TemplateId) {
            setChatTemplateId(params.TemplateId);
            document.title = "Chat Templates"
        }
    }, [])

    // For changing fields that are made from the prompt.
    const updatePromptField = (index, value) => {
        let editedChatTemplate = {...chatTemplate}
        editedChatTemplate.messageComponents[index].value = value;
        setChatTemplate({...editedChatTemplate});
    }

    const updatePromptFiles = (files) => {
        setChatTemplate({...chatTemplate, userAddedFiles: files});
    }

    return (
        <>
            <div className="o-page-section o-page-section l-mt-none l-pt-none l-pb-md">
                <div className="o-page-section__content-container o-page-section__content-container--full-width l-pt-none l-mt-neg-sm">
                    <div className="l-row l-pt-none">
                        <div className="col-md-5 offset-md-1 l-pt-md">
                            <Heading size={1}>Create Chat Template</Heading>
                            <p className="a-muted-text">These settings help you customize the way that your assistant responds, from its tone, personality, and thoroughness. It can also be used to give very specific guidance on the types of responses you'd like.</p>
                            <TabRow
                                tabTitles={["Template Basics", "Included Data", "Shortcuts"]}
                                tabIDs={["basics", "data", "shortcuts"]}
                                activeTab={tabID}
                                onTabChange={(id) => setTabID(id)}
                            />
                            <div className="row l-mt-xs">
                                { tabID === "basics" && <TemplateBasics />}
                                {tabID === "data" && <TemplateIncludedData isEditing={isEditing} />}
                                { tabID === "shortcuts" && <TemplateShortcuts />}
                            </div>
                        </div>
                        <div className="col-md-5 offset-md-1 l-pt-lg" style={{"backgroundColor": "#f7f7f7", "paddingLeft": "2rem"}}>
                            <Heading size={3}>Preview</Heading>
                            <p></p>
                            <p className="a-muted-text">This is a preview of how a user would experience a new chat using this template.</p>
                            {chatTemplate?.initialMessageText &&
                                <ChatBubble 
                                    image={<img src={avatarAI} className="a-avatar__img a-avatar__img--square" />}
                                    isAssistant
                                    isReply
                                    name="Symphony Assistant"
                                >
                                    <p>{chatTemplate?.initialMessageText}</p>
                                </ChatBubble>
                            }
                            {(chatTemplate?.messageComponents?.length > 0 || chatTemplate?.hasFileUpload) &&
                                <form className="o-nl-form l-mt-sm">
                                    <p className="o-nl-form__text">
                                        {chatTemplate?.messageComponents?.map((part, index) => {
                                            if (part.type === "input") {
                                                return (
                                                    <input
                                                        key={`template-preview-${index}`}
                                                        placeholder={part.text}
                                                        type="text"
                                                        className="o-nl-form__text-input"
                                                        value={part.value}
                                                        onChange={(e) => updatePromptField(index, e.target.value)}
                                                    />
                                                );
                                            } else if (part.type === "textarea") {
                                                return (
                                                    <textarea
                                                        key={`template-preview-${index}`}
                                                        placeholder={part.text}
                                                        className="a-textarea-input"
                                                        rows="3"
                                                        value={part.value}
                                                        onChange={(e) => updatePromptField(index, e.target.value)}
                                                    />
                                                )
                                            } else if (part.type === "select") {
                                                return (
                                                    <select 
                                                        className="o-nl-form__select" 
                                                        onChange={(e) => updatePromptField(index, e.target.value)}
                                                        key={`template-preview-${index}`}
                                                    >
                                                        {!part.defaultValue && <option value="">Select...</option>}
                                                        {part.options?.map((option) => (
                                                            <option 
                                                                value={option} 
                                                                defaultValue={option === part.defaultValue}
                                                                key={`template-preview-${index}-${option}`}
                                                            >
                                                                {option}
                                                            </option>
                                                        ))}
                                                    </select>
                                                );
                                            } else {
                                                return <span key={`template-preview-${index}`}>{part.text}</span>
                                            }
                                        })}
                                    </p>
                                    {chatTemplate?.hasFileUpload && 
                                        <SymphonyFileHandler 
                                            files={chatTemplate?.userAddedFiles}
                                            setFiles={updatePromptFiles}
                                            uploadDisplayText={chatTemplate?.fileUploadMessage}
                                        />
                                    }
                                    <ButtonRow>
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                executeTemplate();
                                            }}
                                            children={"Send Message"}
                                        />
                                    </ButtonRow>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ButtonRow className="m-button-row--bottom-fixed">
                <Button outline onClick={(e) => { e.preventDefault(); leaveTemplateForm() }}>Cancel</Button>
                <Button onClick={(e) => { e.preventDefault(); isEditing ? updateTemplate(chatTemplate) : createTemplate(chatTemplate) }}>{isEditing ? "Update" : "Save"}</Button>
            </ButtonRow>
        </>
    );
}

export default TemplateForm;
import React from 'react';

const LeaveSharedChat = ({ onLeaveChat }) => {
    return (
        <div className="o-chat-input">
            <div className="o-chat-input__container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="o-chat-input__inner">
                            <div className="row l-mt-xs">
                                <div className="col-md-9" />
                                <div className="col-md-3">
                                    <div className="m-button-row">
                                        <input
                                            type="button"
                                            className="a-button"
                                            value="Leave Chat"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                onLeaveChat();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeaveSharedChat;

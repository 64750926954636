import { useNewTemplate } from "../../contexts/NewTemplateContext";

import { Button, Modal } from "cai-fusion";
import { useEffect, useState } from "react";

const DeleteTemplateModal = ({ isHidden, onClose, clickOutsideToClose }) => {
    const { chatTemplates, chatTemplateId, deleteTemplate } = useNewTemplate();
    const [chatTemplateName, setChatTemplateName] = useState("");

    useEffect(() => {
        if(!isHidden){
            setChatTemplateName(chatTemplates.filter((dataStore) => dataStore.id === chatTemplateId)[0].name);
        }
    }, [isHidden])

    return (
        <Modal
            id="delete-modal"
            isHidden={isHidden}
            clickOutsideToClose={clickOutsideToClose}
            title="Delete Chat Template"
            onClose={(e) => {
                onClose();
                e.preventDefault();
            }}
            actions={
                <>
                    <Button outline onClick={(e) => { e.preventDefault(); onClose(); }} children="Cancel"/>
                    <Button danger onClick={(e) => { e.preventDefault(); deleteTemplate(chatTemplateId); onClose(); }} children="Delete Chat Template"/>
                </>
            }
        >
            <div className="l-row">
                <div className="l-one-col">
                    <p><strong>{`Are you sure you want to delete ${chatTemplateName ? (`"` + chatTemplateName + `"`) : "this chat template"}?`}</strong></p>
                    <p>Deleting a chat template can't be undone, so think carefully! :) </p>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteTemplateModal;
import { Button, Heading, Modal } from "cai-fusion";
import { useTransientVisibility } from "../../fusion/Contexts/VisualContext";

const PlagiarismDisclaimerModal = () => {
    const { visibilityStates, hide } = useTransientVisibility();
    return (
        <Modal
            title="Copyright & Plagiarism Considerations"
            clickOutsideToClose={ false }
            isHidden={ !visibilityStates.PlagiarismDisclaimerModal }
            onClose={()=> hide('PlagiarismDisclaimerModal') }
            actions={
                <Button children="I understand" onClick={ ()=> hide('PlagiarismDisclaimerModal') } />
            }
        >
            <Heading size={4}>Understanding the Issue</Heading>
            <p>
                Imagine Maestro as a collaborative partner, brimming with ideas drawn from an expansive array of sources. 
                While its ability to generate content is remarkable, it may occasionally produce material reminiscent of existing works. 
                Copyright protection exists to safeguard creators' rights, and using someone's content without authorization might lead to legal complications or financial penalties.
            </p>

            <Heading size={5}>What's at Stake</Heading>
            <p>
                Adhering to copyright laws is not solely a legal obligation; it's also a testament to your professionalism and the trust you earn from your audience and peers. 
                Misappropriating copyrighted material can tarnish your reputation and detract from the collective respect for creators' contributions.
            </p>
            
            <Heading size={5}>Smart Remediation Strategies</Heading>
            <p>To navigate these waters with due diligence, consider the following strategies:</p>
            <ol>
                <li><strong>Champion Originality:</strong> Utilize Maestro's output as a catalyst for your unique expression, ensuring your personal touch is evident.</li>
                <li><strong>Conduct Due Research:</strong> Employ online tools to verify the copyright status of content before its use or publication.</li>
                <li><strong>Acknowledge Inspiration:</strong> Properly attribute any direct quotations or influenced work to their rightful creators.</li>
                <li><strong>Obtain Permissions:</strong> When uncertain, proactively seek consent from the original content owner.</li>
            </ol>
            <p>
                By embracing these practices, you'll safeguard your work against copyright infringement while honoring the creative rights of others. 
                Maestro serves as a tool to enhance, not replace, your creative process.
                Armed with this knowledge, you're now ready to produce work that's not only innovative but also respectful and compliant. 
                We're excited to see your contributions to the world of creativity!
            </p>
        </Modal>
    );
}

export default PlagiarismDisclaimerModal
import { useChat } from "../contexts/ChatContext";
import { useNewTemplate } from "../contexts/NewTemplateContext";

const NewChatButton = () => {
  const { leaveChat } = useChat(); 
  const { setChatTemplate } = useNewTemplate();

  return (
    <a
      className="a-button a-button--outline l-mb-sm"
      href={`/symphony/`}
      onClick={(e) => {
        e.preventDefault();
        console.log("New Chat Started");
        setChatTemplate(undefined);
        leaveChat();
        document.title = "Maestro - New chat";
      }}
    >
      Start New Chat
    </a>
  );
};

export default NewChatButton;

import React, { useEffect, useState } from "react"
import { CollapsiblePanel, Heading, Pagination, Treegrid } from "cai-fusion";
import SymphonyFileHandler from "../../components/symphonyFileHandler";
import { useDataStore } from "../../contexts/DataStoreContext";
import { useNewTemplate } from "../../contexts/NewTemplateContext";

import { ReactComponent as IconTrash } from "../../../../images/icon-trash.svg"
import { ReactComponent as IconAdd } from "../../../../images/icon-add.svg"

import ImageSearch from "../../../../images/search-icon-light.png"

const TemplateIncludedData = ({ isEditing }) => {

    const { chatTemplate, setChatTemplate, addFileToTemplate, deleteFileFromTemplate } = useNewTemplate();

    // Regarding the data store selection.
    const { dataStores } = useDataStore();
    const [page, setPage] = useState(1);
    const [disabledDataStores, setDisabledDataStores] = useState([]);
    const NUM_PER_PAGE = 10

    // const [files, setFiles] = useState([]);

    // This useEffect is for updating the list of NOT enabled data stores.
    useEffect(() => {
        setDisabledDataStores(dataStores.filter((dataStore) => !chatTemplate?.data?.find((enabledDataStore) => enabledDataStore.dataCollectionId === dataStore.id)));
    }, [dataStores, chatTemplate.data])

    const removeDataStore = (dataStoreObj) => {
        setChatTemplate({...chatTemplate, data: chatTemplate.data.filter((dataStore) => dataStoreObj.dataCollectionId !== dataStore.dataCollectionId)})
    }

    const addDataStore = (dataStoreObj) => {
        let templateDataStores = chatTemplate.data ?? []
        setChatTemplate({...chatTemplate, data: [...templateDataStores, {...dataStoreObj, "dataCollectionId": dataStoreObj.id}]});
    }

    const setFiles = async (files) => {
        console.log("New change!", files)
        if (isEditing) {
            //Added files won't have an ID
            const addedFiles = files.filter(file => !file.resourceId);
            const removedFiles = chatTemplate.includedFiles.filter(file => files.includes(newFile => newFile.resourceId === file.resourceId))

            if (addedFiles.length > 0) {
                let resultingFiles = await Promise.all(addedFiles.map(async file => addFileToTemplate(file)));
                files = [...files.filter(file => file.resourceId), ...resultingFiles]
            }
            if (removedFiles.length > 0) {
                await removedFiles.map(async file => deleteFileFromTemplate(file.resourceId))
            }


        }
        setChatTemplate({ ...chatTemplate, includedFiles: [...files] })
    }

    return (
        <div className="col-12">
            <Heading size={2}>Included Data</Heading>
            <p className="a-muted-text l-mb-sm">These settings are for additional files and add-ons to your template.</p>
            <div className="row">
                <div className="col-12">
                    <Heading size={3}>Data Collections</Heading>
                    <p className="a-muted-text a-subtle-text">Choose which data collections you would like the assistant to be able to reference when responding to the user's first message.</p>
                    <div className="row">
                        <div className="col-12">
                            <div className="o-block">
                                <Treegrid
                                    isCompact
                                    columnSizes={["medium", "32"]}
                                    className="l-mr-sm"
                                >
                                    {chatTemplate?.data?.map((enabledDataStore) => {
                                        return (
                                            <tr className="o-treegrid__row o-treegrid__row--shown" key={enabledDataStore.name}>
                                                <td className="o-treegrid__cell"><span className="a-subtle-text"><strong>{enabledDataStore.name}</strong></span></td>
                                                <td className="o-treegrid__cell o-treegrid__cell--right-aligned">
                                                    <button className="a-icon-link a-icon-link--destructive" onClick={(e) => { e.preventDefault(); removeDataStore(enabledDataStore) }}>
                                                        <div className="a-icon-link__inner">
                                                            <div className="a-icon-link__icon-wrapper">
                                                                <IconTrash className="a-icon-link__icon"/>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </Treegrid>
                                <CollapsiblePanel
                                    isShaded
                                    label="Browse additional collections to add"
                                    className="l-mt-xs"
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <form className="a-search-input l-mb-xs l-mt-xs">
                                                <input 
                                                    className="a-search-input__text-box"
                                                    type="text"
                                                    placeholder="Search collections..."
                                                    aria-label="Search"
                                                    id="search_field"
                                                    name="search_field"
                                                />
                                                <button className="a-search-input__button" type="submit">
                                                    <img className="a-search-input__img" src={ImageSearch} alt="search button icon" />
                                                </button>
                                                <Treegrid
                                                    isCompact
                                                    columnSizes={["xxx-wide", "small"]}
                                                    className="l-mr-sm l-mt-xs"
                                                >
                                                    {disabledDataStores.slice((page - 1) * NUM_PER_PAGE, Math.min(disabledDataStores.length, page * NUM_PER_PAGE)).map((dataStore) => {
                                                        return (
                                                            <tr className="o-treegrid__row o-treegrid__row--shown" key={dataStore.id}>
                                                                <td className="o-treegrid__cell">
                                                                    <p className="a-subtle-text l-mb-none"><strong>{dataStore.name}</strong></p>
                                                                    <p className="a-subtle-text a-muted-text l-mt-none">{dataStore.description}</p>
                                                                </td>
                                                                <td className="o-treegrid__cell">
                                                                    <div className="m-button-row">
                                                                        <button className="a-icon-link" onClick={(e) => { e.preventDefault(); addDataStore(dataStore) }}>
                                                                            <div className="a-icon-link__inner">
                                                                                <div className="a-icon-link__icon-wrapper">
                                                                                    <IconAdd className="a-icon-link__icon" />
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )})
                                                    }
                                                </Treegrid>
                                                <Pagination 
                                                    numPerPage={NUM_PER_PAGE}
                                                    data={disabledDataStores}
                                                    currentPage={page}
                                                    onPageChange={(page) => setPage(page)}
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </CollapsiblePanel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row l-mt-sm">
                <div className="col-12">
                    <Heading size={3}>Included Files</Heading>
                    <p className="a-muted-text a-subtle-text">Add any additional files you would like the assistant to be able to reference when responding to the user's first message.</p>
                    <div className="row">
                        <div className="col-12">
                            <div className="o-block">
                                <SymphonyFileHandler
                                    files={chatTemplate.includedFiles}
                                    setFiles={async (param) => await setFiles(param)}
                                    noFilesDisplayText="No files currently added."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TemplateIncludedData;
import { ToastContext, ToastProvider } from "cai-fusion";
import { createContext, useContext, useEffect, useState } from "react";
import { browserName, browserVersion } from "react-device-detect";
import { supportConfig } from "../configs/supportConfig";
import { useSymphonyApiService } from "../hooks/useSymphonyApi";
import myCaiApiService from "../services/mycai-api-v1";
import { SymphonyApiService } from "../services/mycaiApis/SymphonyServiceApi";


const SettingsContext = createContext();

export const SettingContextWrapper = ({ children }) => {
    const [isSymphonyAdmin, setIsSymphonyAdmin] = useState(true);
    const [licensedUsers, setLicensedUsers] = useState([]);
    const [availableLicenses, setAvailableLicenses] = useState(null);
    const [remainingLicenses, setRemainingLicenses] = useState(0);
    const [unusableUsers, setUnusableUsers] = useState([]);
    const [isLicensedEnvironment, setIsLicensedEnvironment] = useState(false);
    const { createWarningToast, createSuccessToast } = useContext(ToastContext);
    const [isLoadingUnusables, setIsLoadingUnusables] = useState(false);
    const apiServiceClient = useSymphonyApiService();

    const reportBugHandler = (msg) => {
        const bugReportLink = `mailto:${supportConfig.supportContact}?${supportConfig.supportAuxiliary === undefined ? "" : `cc=${supportConfig.supportAuxiliary}&`}subject=MyCAI%20Issue%20Report&body=Error%20Received%20While%20Changing%20Licensing:%0D%0A${msg}%0D%0A%0D%0Aon%20${new Date().toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" })}%0D%0Ausing%20${browserName}%20v${browserVersion}%0D%0A%0D%0AmyCAI%20${process.env.REACT_APP_VERSION}%20${process.env.REACT_APP_BUILD_NUMBER}%20${process.env.REACT_APP_BUILD_TAG}`
        window.location.href = bugReportLink;
    }

    useEffect(() => {
        apiServiceClient.Licensing.isUserLicenseManager().then((res) => setIsSymphonyAdmin(res === "true" || res)).catch(setIsSymphonyAdmin(false));
        pullUsers();
        apiServiceClient.Licensing.getLicenseCount().then((res) => {
            setAvailableLicenses(+res)
        });
        apiServiceClient.Licensing.isLicensedEnvironment().then((res) => setIsLicensedEnvironment(res === "true" || res));
    }, [])

    useEffect(() => {
        if (availableLicenses === null) setRemainingLicenses(1);
        if (availableLicenses !== null && licensedUsers !== null) {
            setRemainingLicenses(availableLicenses - licensedUsers?.length);
        }
    }, [availableLicenses, licensedUsers])


    const searchUsers = (searchVal) => {
        return apiServiceClient.Licensing.searchUsersToLicense(searchVal).then((users) => { return users });
    }

    const provisionLicenseToMany = (upns) => {
        const promises = upns.map(upn => {
            return apiServiceClient.Licensing.licenseUser(upn)
        });

        Promise.allSettled(promises).then(() => pullUsers());
    }

    const provisionLicense = (upn) => {
        apiServiceClient.Licensing.licenseUser(upn).then(() => {
            pullUsers();
        })
    }

    const deprovisionLicense = (upn) => {
        apiServiceClient.Licensing.unlicenseUser(upn).then(() => {
            pullUsers();
        })
    }

    const pullUsers = () => {
        apiServiceClient.Licensing.getLicensedUsers().then((res) => setLicensedUsers(res ?? []));
        setIsLoadingUnusables(true);
        apiServiceClient.Licensing.getUnusableUsers().then((res) => {
            setUnusableUsers(res ?? []);
            setIsLoadingUnusables(false);
        });
    }

    const tryFixUser = (user) => {
        apiServiceClient.Licensing.unlicenseUser(user.identifier).then((res) =>
            apiServiceClient.Licensing.licenseUser(user.identifier).then((res2) => {
                createSuccessToast(
                    <div>
                        <h4> Corrected issue for user: {user.identifier}</h4>
                        <p></p>
                    </div>
                );
                pullUsers();
            }, (error) => {
                    createWarningToast(
                        <div>
                            <h4> Failed to correct issue for user {user.identifier}</h4>
                            <p> The operation failed stating {error.response.data} Please provide this to your Maestro admin, if applicable.</p>
                            <button onClick={(e) => { e.preventDefault(); reportBugHandler(error.response.data) }}>Report</button>
                        </div>
                    )
                }
            ), (error) => {
                createWarningToast(
                    <div>
                        <h4> Failed to correct issue for user {user.identifier}</h4>
                        <p> The operation failed stating {error.response.data} Please provide this to your Maestro admin, if applicable.</p>
                        <button onClick={(e) => { e.preventDefault(); reportBugHandler(error.response.data) }}>Report</button>
                    </div>
                )
            })
    }

    return (
        <SettingsContext.Provider
            value={{
                isSymphonyAdmin,
                licensedUsers,
                remainingLicenses,
                searchUsers,
                provisionLicense,
                deprovisionLicense,
                unusableUsers,
                isLicensedEnvironment,
                tryFixUser,
                isLoadingUnusables,
                pullUsers,
                provisionLicenseToMany
            }}
        >
            {children}
        </SettingsContext.Provider>
    )
};
export { SettingsContext };
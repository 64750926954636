import React, { useEffect, useState, useRef } from "react"
import { ChipGroup, CollapsiblePanel, Heading } from "cai-fusion";
import { useNewTemplate } from "../../contexts/NewTemplateContext";
import { useUserProfile } from "../../contexts/UserProfileContext";
import { useSettings } from "../../contexts/SettingsContext";

const TemplateBasics = () => {
    const { preparePlaceholderTemplate, chatTemplate, setChatTemplate } = useNewTemplate();
    const { userProfile } = useUserProfile();
    const { models, settings } = useSettings();

    // The prompt input field.
    const [prompt, setPrompt] = useState("As a technical recruiter, draft a job description for a [Job Title]. Some of the responsibilities will include [writing some codes]. The requirements for this job include [e.g. 10 years of experience]. The job is located in [e.g. Allentown, PA]. This job is {On-site, Hybrid, Remote}");

    // Regarding the Tags section
    // A list of all the available tags
    const availableTags = ['Content Summarization', 'Coding', 'Brainstorming', 'Data Analysis', 'Creative Writing', 'Translation', 'Question Answering', 'Text Completion', 'Text Classification', 'Paraphrasing'];
    // The state of the tag input, used for searching the above list.
    const [tagsInput, setTagsInput] = useState("")
    // The visual state to manage the autocomplete options showing.
    const [showTags, setShowTags] = useState(false);
    // A state to help with proper loading.
    const [formIsPrepared, setFormIsPrepared] = useState(false);
    // The reference to the tag input
    const tagsRef = useRef(null);

    useEffect(() => {
        if (!formIsPrepared && settings.length !== 0 && models.length !== 0) {
            preparePlaceholderTemplate();
            setFormIsPrepared(true);
            // I'll find a better fix to this later but for now this simply triggers the prompt useEffect
            setPrompt(prompt + " ");
        }
    }, [models, settings])

    useEffect(() => {
        if (chatTemplate?.messageComponents?.length !== 0) {
            let newPrompt = createStringFromPromptParts(chatTemplate?.messageComponents)
            setFormIsPrepared(true);
            setPrompt(newPrompt);
        }
    }, [])
    
    useEffect(() => {
        if (!formIsPrepared && chatTemplate?.id) {
            let newPrompt = createStringFromPromptParts(chatTemplate?.messageComponents)
            setFormIsPrepared(true);
            setPrompt(newPrompt);
        }
    }, [chatTemplate])

    const createStringFromPromptParts = (parts) => {
        let newPrompt = parts?.map((component) => {
            if (component.type === "input") {
                return `[${component.text}]`;
            } else if (component.type === "textarea") {
                return `[[${component.text}]]`
            } else if (component.type === "select") {
                return `{${
                    component.options.map((option, index) => {
                        let optionStr = option
                        if (option === component.defaultValue) {
                            optionStr += "*"
                        }
                        if (index < (component.options.length - 2)) {
                            optionStr += ", "
                        }
                        return optionStr || "";
                    })
                }}`
            } else {
                return component.text;
            }
        }).join("");

        return newPrompt;
    }

    // For updating generic data input fields, this function is called.
    const updateData = (e) => {
        // Replace the field given with the changed value
        setChatTemplate({ ...chatTemplate, [e.target.id]: e.target.value })
    }

    // For updating checkbox-like fields, this function is called instead of the above.
    const updateCheckbox = (e) => {
        setChatTemplate({ ...chatTemplate, [e.target.id]: e.target.checked })
    }

    const updateModelParameters = (index, value) => {
        let tempModelParameters = [...chatTemplate?.modelParameters] || [];
        tempModelParameters[index].value = value;
        setChatTemplate({ ...chatTemplate, modelParameters: [...tempModelParameters]});
    }

    // These two functions simply add and remove the given tag from the tags list in the form object.
    const addTag = (tag) => {
        // If it's not already in the list of used tags, add it to the list.
        if (!chatTemplate?.tags.find((tagItem) => tagItem.tagName === tag)) {
            setChatTemplate({...chatTemplate, tags: [...chatTemplate?.tags, {"tagName": tag}]})
        }
        setTagsInput("");
    }

    // When the enter key is pressed & the tags input is in focus, add that tag
    const handleTagsKey = (key) => {
        if (key === 'Enter') {
            // This will remove focus from the input.
            tagsRef.current?.blur();
            addTag(tagsInput);
        }
    }

    const removeTag = (tag) => {
        setChatTemplate({...chatTemplate, tags: [...chatTemplate?.tags.filter((tagItem) => tagItem.tagName !== tag)]})
    }

    // This useEffect will regenerate the form on the right-side of the screen when the prompt changes.
    useEffect(() => {
        const bracketRegex = /(\[\[.*?\]\]|\[.*?\]|\{.*?\})/g;

        if(!prompt) return;

        // Separating the prompt into a list of items that are either text, inputs, selects, or text areas.
        let promptPieces = prompt?.split(bracketRegex);

        let promptObj = promptPieces.map((part) => {
            if (part.startsWith('[[') && part.endsWith(']]')) {
                return { 
                    type: 'textarea', 
                    text: part.slice(2, -2),
                    value: "",
                };
            } else if (part.startsWith('[') && part.endsWith(']')) {
                return { 
                    type: 'input', 
                    text: part.slice(1, -1),
                    value: "",
                };
            } else if (part.startsWith('{') && part.endsWith('}')) {
                let selectOptions = part.slice(1, -1).split(",").map((item) => item.trim());
                return { 
                    type: 'select', 
                    options: [...selectOptions.map((option) => option.endsWith('*') ? option.slice(0, -1) : option)],
                    defaultValue: selectOptions.find((input) => input.endsWith('*'))?.slice(0, -1) || null,
                    value: selectOptions.find((input) => input.endsWith('*'))?.slice(0, -1) || "",
                };
            } else {
                return { 
                    type: 'text', 
                    text: part,
                    value: part,
                };
            }
        });

        // The above REGEX will make it so the last item will ALWAYS be a text item. If it's blank, remove it!
        if (promptObj[promptObj.length - 1].value === "") {
            promptObj = promptObj.slice(0, -1);
        }

        let newTemplateObj = {...chatTemplate}
        newTemplateObj.messageComponents = prompt === "" ? [] : [...promptObj];
        setChatTemplate({...newTemplateObj});
    }, [prompt])

    return (
        <div className="col-12">
            <Heading size={2}>Template Basics</Heading>
            <p className="a-muted-text l-mb-sm">These are the essentials for creating a chat template.</p>
            <div className="o-block">
                {/* The title field */}
                <div className="m-form-group">
                    <label htmlFor="title" className="a-label">Title</label>
                    <input 
                        className="a-text-input"
                        id="title"
                        onChange={(e) => updateData(e)}
                        value={chatTemplate?.title ?? ''}
                    />
                </div>
                {/* Description */}
                <div className="m-form-group">
                    <label htmlFor="initialMessageText" className="a-label">Description</label>
                    <p className="a-subtle-text a-muted-text">Show the user a brief synopsis of what the template accomplishes.</p>
                    <textarea
                        className="a-textarea-input" 
                        rows="3" 
                        id="description"
                        onChange={(e) => updateData(e)}
                        placeholder="Using basic requirements, create a detailed job requirement form."
                        title="Initial Assistant Message"
                        value={chatTemplate?.description || ''}
                    />
                </div>
                {/* Initial Assistant Message */}
                <div className="m-form-group">
                    <label htmlFor="initialMessageText" className="a-label">Initial Assistant Message</label>
                    <p className="a-subtle-text a-muted-text">Start the chat with an optional message from the assistant to help provide context and guidance to the user.</p>
                    <textarea
                        className="a-textarea-input" 
                        rows="6" 
                        id="initialMessageText"
                        onChange={(e) => updateData(e)}
                        placeholder="Hello! Let's get started by getting more information about your situation."
                        title="Initial Assistant Message"
                        value={chatTemplate?.initialMessageText || ''}
                    />
                </div>
                {/* Prompt */}
                <div className="m-form-group">
                    <label htmlFor="prompt" className="a-label">Prompt</label>
                    <p className="a-subtle-text a-muted-text">{`Add placeholders to your prompt that users can fill in. Use [brackets] for short text responses, [[double brackets]] for longer responses, and {curly brackets} for dropdown options. To set a default option in a dropdown, add an asterisk: {Option 1*, Option 2}. `}</p>
                    <textarea 
                        className="a-textarea-input" 
                        rows="6" 
                        id="messageComponents"
                        onChange={(e) => setPrompt(e.target.value)}
                        placeholder="I currently am having troubles with [difficult area]. Can you help?"
                        title="Prompt Text"
                        value={prompt || ''}
                    />
                </div>
                {/* File Upload Checkbox */}
                <div className="m-form-group">
                    <label className="a-checkbox" name="show-file-upload-toggle" id="show-file-upload-toggle">
                        <span>Allow users to attach files to the prompt.</span>
                        <input 
                            id="hasFileUpload"
                            type="checkbox" 
                            checked={chatTemplate?.hasFileUpload}
                            onChange={updateCheckbox}
                        />
                        <div className="b-input"></div>
                    </label>
                </div>
                { chatTemplate?.hasFileUpload &&
                    <div className="m-form-group">
                        <label htmlFor="fileUploadMessage" className="a-label">File Upload Message</label>
                        <input 
                            className="a-text-input"
                            id="fileUploadMessage"
                            onChange={(e) => updateData(e)}
                            value={chatTemplate?.fileUploadMessage || ''}
                        />
                    </div>
                }
                {/* Tags Field */}
                <div className="m-form-group">
                    <label htmlFor="addTagInput" className="a-label">Tags</label>
                    <p className="a-subtle-text a-muted-text">You can assign tags to a template to help group it with similar templates on users' new chat screen.</p>
                    <div className="m-autocomplete-input" id="tag-autocomplete">
                        <label className="visually-hidden" htmlFor="autocompleteInput"></label>
                        <input 
                            id="addTagInput" 
                            type="text" 
                            placeholder="Add a tag..." 
                            className="a-text-input"
                            value={tagsInput}
                            onChange={(e) => setTagsInput(e.target.value)}
                            onKeyDown={(e) => handleTagsKey(e.key)}
                            onFocus={() => setShowTags(true)}
                            onBlur={() => setTimeout(() => setShowTags(false), 200)}
                            ref={tagsRef}
                        />
                        <div className={`m-autocomplete-input__dropdown ${showTags ? `m-autocomplete-input__dropdown--shown` : ""}`} id="autocompleteDropdown">
                            {availableTags.filter((tag) => tag.toLowerCase().includes(tagsInput.toLowerCase())).map((tag) => (
                                <button 
                                    className="m-autocomplete-input__dropdown-item" 
                                    key={tag}
                                    onClick={() => addTag(tag)}
                                >
                                    <div className="m-autocomplete-input__dropdown-item-title">{tag}</div>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Chips associated with the selected tags */}
                <ChipGroup 
                    chipTexts={chatTemplate?.tags?.map((tag) => tag.tagName) ?? []}
                    onClose={(chipText) => removeTag(chipText)}
                />
                <CollapsiblePanel
                    label="More nerdy options"
                    className="l-mt-xs"
                >
                    <div className="row">
                        <div className="col-12">
                            <div className="m-form-group">
                                <label htmlFor="docSelector" className="a-label">Custom Instructions</label>
                                <p className="a-subtle-text a-muted-text">You can provide custom instructions that the assistant should use when responding to the user's initial message.</p>
                                <textarea
                                    id="customText"
                                    className="a-textarea-input" 
                                    rows="6"
                                    value={chatTemplate?.customText || ''}
                                    onChange={(e) => updateData(e)}
                                />
                            </div>
                            <div className="m-form-group">
                                <label htmlFor="docSelector" className="a-label">LLM Model</label>
                                <select className="a-select-input" id="modelId" onChange={(e) => updateData(e)} value={chatTemplate?.modelId}>
                                    {models?.map((model) => {
                                        return (
                                            <option key={model.engine} value={model.engine}>
                                                {model.displayName}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            {chatTemplate?.modelParameters?.map((param, index) => {
                                return (
                                    (param.modelId === chatTemplate?.modelId) && // Only show ones for the currently active model.
                                    <div key={index} className="m-form-group">
                                        <label htmlFor="" className="a-label">
                                            {param?.name}: {param.value}
                                        </label>
                                        <input
                                            id={param?.name}
                                            type="range"
                                            className="a-text-input"
                                            onChange={(e) => updateModelParameters(index, e.target.value)}
                                            step={
                                                param?.maxValue === 1 ? 0.01
                                                : param?.maxValue === 2 ? 0.1
                                                : param?.maxValue === 10 ? 0.5
                                                : param?.maxValue >= 10 ? Math.floor((param?.maxValue ?? 10000) - ((param?.minValue ?? 0) / 100))
                                                : 1 // Default step if none of the above conditions are met
                                            }
                                            min={param?.minValue ?? 0}
                                            max={param?.maxValue ?? 10000}
                                            value={param.value}
                                        />
                                        <p className="a-muted-text a-subtle-text">
                                            {param?.description}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {/* <div class="row l-mt-sm">
                        <div class="col-12">
                            <Heading size={3}>Skills</Heading>
                            <p class="a-muted-text a-subtle-text">Choose which skills you would like the assistant to be able to use when responding to the user's first message.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="o-block">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="a-checkbox">
                                            <span>Web Browsing</span>
                                            <input type="checkbox" checked />
                                            <div className="b-input"></div>
                                        </label>
                                        <label className="a-checkbox">
                                            <span>Image Creation</span>
                                            <input type="checkbox" checked />
                                            <div className="b-input"></div>
                                        </label>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="a-checkbox">
                                            <span>File Reading</span>
                                            <input type="checkbox" checked />
                                            <div className="b-input"></div>
                                        </label>
                                        <label className="a-checkbox">
                                            <span>Code Interpreter/Data Analysis</span>
                                            <input type="checkbox" checked />
                                            <div className="b-input"></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </CollapsiblePanel>
            </div>
        </div>
    );
}

export default TemplateBasics;
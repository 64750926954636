import { useEffect, useState } from "react";
import { useDataStore } from "../contexts/DataStoreContext";
import { Button, Modal } from "cai-fusion";

const NewDataStoreModal = ({ isHidden, onClose, clickOutsideToClose, isRenaming }) => {

	const [name, setName] = useState("");
    const [description, setDescription] = useState("");
	// const { chat, renameChat } = useChat();
    const { dataStores, dataStoreId, createDataStore, renameDataStore, changeDataStoreDescription } = useDataStore();

	useEffect(() => {
		if(!isHidden && dataStoreId){
			let dataStore = dataStores.find((dataStore) => dataStore.id === dataStoreId);
			setName(dataStore.name);
			setDescription(dataStore.description);
		}
	}, [isHidden])

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (!event.shiftKey) {
				event.preventDefault();
				handleSubmit();
				setName("");
			}
		}
	};

	const handleSubmit = async () => {
		onClose();
		if (isRenaming) {
			let dataStore = dataStores.find((dataStore) => dataStore.id === dataStoreId);
			if (dataStore.name !== name) {
				await renameDataStore(dataStoreId, name);
			}
			if (dataStore.description !== description) {
				await changeDataStoreDescription(dataStoreId, description);
			}
		} else {
			await createDataStore(name, description); 
		}
		setName(""); 
		setDescription("");
	}

	return (
		<Modal
			isHidden={ isHidden }
			id="rename-modal"
			clickOutsideToClose={ true }
			title={`${isRenaming ? "Rename" : "Create New"} Data Store`}
			onClose={(e) => {
				onClose();
				e.preventDefault();
			}}
			actions={
				<>
					<Button outline onClick={(e) => { e.preventDefault(); setName(""); setDescription(""); onClose(); }} children="Cancel"/>
					<Button 
                        onClick={(e) => { 
                            e.preventDefault(); 
                            handleSubmit(); 
                        }} 
                        children={`${isRenaming ? "Rename" : "Create"}`}
                    />
				</>
			}
		>
			<div className="l-row">
				<div className="l-one-col">
					<p>{`Please ${isRenaming ? "rename" : "name"} your data collection. You can optionally ${isRenaming ? "change your" : "add a"} description, and both of these fields can be changed again later.`}</p>
					<form action="" className="o-form">
						<div className="m-form-group l-mt-sm">
							<label htmlFor="" className="a-label">Data Store Name</label>
							<input 
                                type="text" 
                                value={name} 
                                onKeyDown={(e) => handleKeyPress(e)} 
                                onChange={(e) => { 
                                    setName(e.target.value); 
                                    e.preventDefault();
                                }} 
                                className="a-text-input"
                            />
						</div>
                        <div className="m-form-group l-mt-sm">
							<label htmlFor="" className="a-label">Description</label>
							<input 
                                type="text" 
                                value={description} 
                                onChange={(e) => {
                                    setDescription(e.target.value); 
                                    e.preventDefault(); 
                                }} 
                                className="a-text-input"
                            />
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default NewDataStoreModal;
import { useContext, useEffect, useRef, useState } from "react";
import { ToastContext } from "cai-fusion";

import { REJECTED_FILE_EXTENSIONS } from "./rejectedFileTypes";
import { isValidFile, validateUploadItem } from "./fileValidator";

const DnDFileUpload = ({
    onFileUpload = null, //the function files get sent into after processing
    rejectedFileTypes = REJECTED_FILE_EXTENSIONS,
    allowFolders = true,
    displayText = 'Choose file or drag files here',
    omni = false,
    readErrorMsg = {
        heading: "Unsupported File Type",
        description: "Sorry I can't read that, try saving it as a different extension!"
      }
    }) => {
    const {createWarningToast, createErrorToast} = useContext(ToastContext);
    const badFilesRef = useRef([]);
    const [dragActive, setDragActive] = useState(false);
    const fileUploadInputRef = useRef(null);
    
    const handleButtonClick = (e) => {
        e.preventDefault();
        if (fileUploadInputRef.current) {
            fileUploadInputRef.current.click();
        }
    };

    //Converts a raw File object into a ProcessedFile format. 
    function processFile(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            
            reader.onload = (e) => {
                resolve({
                    resourceName: file.name,
                    content: e.currentTarget.result,
                    uploading: true,
                    type: file.type,
                    size: file.size,
                });
            };
            
            reader.onerror = () => {
                reject(new Error('Failed to read file'));
            };
            
            reader.readAsDataURL(file);
        });
    }

    const getFileItems = (event) => {
        if ("dataTransfer" in event) {
            // If there are multiple files in files, use them directly.
            if (event.dataTransfer.files && event.dataTransfer.files.length > 1) {
            return Array.from(event.dataTransfer.files).map(file => ({
                kind: "file",
                getAsFile: () => file,
            }));
            }
            return Array.from(event.dataTransfer.items);
        } else {
            return Array.from(event.target.files || []).map(file => ({
            kind: "file",
            getAsFile: () => file,
            }));
        }
    };

    // TODO, move all of this to theContext.. drag and drop should just call events with file arrays
    // but the app should handle the rest
    
    async function handleFileUpload(event) {
        event.preventDefault();
        badFilesRef.current = [];
        const processedFiles = [];

        const items = getFileItems(event);

        try {
            for (const item of items) {
                if (item.kind === 'file') {
                    if (!validateUploadItem(item, allowFolders, false, badFilesRef.current)) {
                        continue;
                    }

                    // Process valid files
                    if ('webkitGetAsEntry' in item) {
                        const entry = item.webkitGetAsEntry();
                        if (entry?.isDirectory) {
                            await handleDirectory(entry, processedFiles);
                        } else if (entry?.isFile) {
                            const file = await getFileFromEntry(entry);
                            await handleFile(file, processedFiles);
                        }
                    } else {
                        const file = item.getAsFile();
                        if (file) {
                            await handleFile(file, processedFiles);
                        }
                    }
                }
            }
           
            if (badFilesRef.current.length > 0) {
                createWarningToast(
                    <div>
                    <h4> {readErrorMsg.heading} </h4>
                    <p> {readErrorMsg.description} </p>
                        <ul>
                        {badFilesRef.current.map((badFile, index) => (
                            <li key={index}>
                            <strong>File Name: </strong> {badFile.name}<br />
                            </li>
                        ))}
                        </ul>
                    </div>
                );
            }
            if (processedFiles.length > 0) {
                if(onFileUpload){
                    onFileUpload(processedFiles);
                }else{
                    console.log("[ERROR]: DnDFileUpload is missing onFileUpload function");
                }
                
            }
        } catch (error) {
            createErrorToast("Unexpected error during upload. Please try again.");
        }
    }

    //Recursively processes directory contents using the FileSystem API
    async function handleDirectory(entry, processedFiles) {
        const reader = entry.createReader();
        const entries = await new Promise((resolve, reject) => {
            reader.readEntries(resolve, reject);
        });
       
        for (const entry of entries) {
            if(entry.isDirectory) {
                await handleDirectory(entry, processedFiles);
            } else if(entry.isFile) {
                const file = await new Promise((resolve, reject) => {
                    entry.file(resolve, reject);
                });
                const processedFile = await processFile(file);
                if (processedFile) {
                    processedFiles.push(processedFile);
                }
            }
        }
    }

    //Utility method that converts a FileSystemFileEntry to a File object.
    function getFileFromEntry(entry) {
        return new Promise((resolve, reject) => {
            entry.file(resolve, reject);
        });
    }

    //Processes individual files
    async function handleFile(file, processedFiles) {
        try {
            // Validate the file before processing
            if (!isValidFile(file, badFilesRef.current, undefined, rejectedFileTypes)) {
                return;
            }

            // Handle regular files
            const processedFile = await processFile(file);
            processedFiles.push(processedFile);
        
        } catch (error) {
            badFilesRef.current.push({
                name: file.name,
                reason: `Processing failed: ${error instanceof Error ? error.message : 'Unknown error'}`
            });
        }
    }

    //resetFileInput: Allows to upload the same file even if it is bad file (mainly saves time for bad file testing)
    const resetFileInput = () => {
        if(fileUploadInputRef.current){
            fileUploadInputRef.current.value = "";
        }
    };
    useEffect(() => {
        resetFileInput();
    }, []);
    
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };


    const dragAndDropProps = {
        onDragEnter: handleDrag,
        onDragLeave: handleDrag,
        onDrop: handleFileUpload,
        onDragOver: handleDrag
    };

    return (
        <>
            {/* Always render the omni full-screen drop area */}
            <div 
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    pointerEvents: dragActive ? 'auto' : 'none', 
                    zIndex: 999,
                }}
                {...dragAndDropProps}
            />

            {/* Only show the visual upload box when omni is false */}
            {!omni && (
                <div className="m-file-uploader">
                    <button className="m-file-uploader__dialog-btn"
                        onClick={handleButtonClick}
                        {...dragAndDropProps}
                    >
                        {displayText}
                    </button>
                </div>
            )}
            
            <input
                type="file"
                id="file"
                ref={fileUploadInputRef}
                style={{ display: "none" }}
                multiple
                onChange={handleFileUpload}
                accept="*/*"
            />
        </>
    )
}
export default DnDFileUpload
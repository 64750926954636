import React, { createContext, useContext, useEffect, useState } from "react";
import { useAdminApiService } from "../hooks/useAdminApi";

const AppContext = createContext();

function AppProvider({ children }) {

    const apiServiceClient = useAdminApiService();
    // Reference to the root styles so we can edit them when colors are loaded.
    const rootStyle = document.documentElement.style;

    const [brandLogo, setBrandLogo] = useState(null)

    useEffect(() => {
        console.log("[COLORS] Updating colors...");
        updateColors();
    }, [])

    const updateColors = async () => {
        let colors = await apiServiceClient.Styles.getColors()
        console.log("[COLORS] Updating local colors:", colors);


        if(process.env.REACT_APP_HARDCODE_STYLES !== "yes"){
            // Grab the list of all fields.
            let colorKeys = Object.keys(colors);
            // For each field, update the CSS variable with the same value.
            colorKeys.forEach(element => {
                //console.log("[COLORS] Changing", element, "to", colors[element]);
                rootStyle.setProperty(("--color-" + element), colors[element]);
            });
        }
    }

    const getLogo = async () => {

    }

    return (
		<AppContext.Provider
			value={{
                // States
                brandLogo,
                // Functions
                updateColors,
                getLogo,
			}}
		>
			{children}
		</AppContext.Provider>
	);
}

// Hook to use the ChatContext in a component
function useApp() {
	const context = useContext(AppContext);
	if (context === undefined) {
		throw new Error(
			"useAdmin must be used within a AdminProvider. I seriously do not know how you got this error."
		);
	}
	return context;
}

export { AppProvider, useApp };


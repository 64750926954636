import { Heading, Drawer, HeadingRow } from "cai-fusion";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDataStore } from "../contexts/DataStoreContext";

import DataStoreFileView from "./dataStoreFileView";
import DataStoreTextNotes from "./dataStoreTextNotes"

import { ReactComponent as IconFolder } from "../../../images/icon-folder.svg";
import { ReactComponent as IconText } from "../../../images/icon-text.svg";


const MenuOption = ({ id, name, isActive, onClick, SVGObject }) => {
    // Instance 3 of not turning this into a fusion component for literally no reason
    return (
        <li className={`m-vertical-tabs__item ${isActive ? "m-vertical-tabs__item--active" : ""}`}
            id={`tab${id}`}
            role="tab"
            tabIndex={id}
            aria-controls={`tabpanel${id}`}
            aria-selected="true"
        >
            <a className="m-vertical-tabs__link" onClick={onClick}>
                <div className="m-vertical-tabs__icon-wrapper">
                    <SVGObject className="m-vertical-tabs__icon"/>
                </div>
                <span className="m-vertical-tabs__label">{name}</span>
            </a>
        </li>
    )
}

const SymphonyDataStoreView = () => {

    const { dataStoreId, setDataStoreId, dataStore } = useDataStore(); 

    const params = useParams();

    const [pageID, setPageID] = useState(1);

    useEffect(() => {
        console.log("[DATASTORES] Current data store:", dataStore)
        document.title = "Data Collections - " + dataStore?.name
    }, [dataStore])

    useEffect(() => {
        if (dataStoreId != params.DataStoreId) {
            setDataStoreId(params.DataStoreId);
            document.title = "Data Collections"
        }
    }, [])

    return (
        <>
            <div className="o-page-section l-mt-md l-pt-none">
                <div className="o-page-section__content-container o-page-section__content-container--med-wide-fixed-width l-pt-none l-mt-neg-sm">
                    <div className="l-row">
                        <div className="col-12">
                            <HeadingRow 
                                title={dataStore?.name ?? ""}
                                eyebrow="Data Collection"
                            />
                        </div>
                    </div>
                    <div className="l-row l-pt-sm">
                        <Drawer 
                            className="o-drawer--left-narrow col-md-3"
                            type="hybrid"
                            header={
                                <div className="o-drawer-heading-row">
                                    <div className="o-drawer-title">
                                        <Heading size={3}>Data Collections</Heading>
                                    </div>
                                </div>
                            }
                            children={
                                <nav className="m-vertical-tabs">
                                    <ul className="m-vertical-tabs__list" role="tablist" aria-orientation="vertical">
                                        <MenuOption id={1} name="Files" isActive={pageID === 1} onClick={() => setPageID(1)} SVGObject={IconFolder}/>
                                        {/* <MenuOption id={2} name="Websites" isActive={pageID === 2} onClick={() => setPageID(2)} SVGObject={IconOrganization}/> */}
                                        <MenuOption id={3} name="Text Notes" isActive={pageID === 3} onClick={() => setPageID(3)} SVGObject={IconText}/>
                                        {/* <MenuOption id={4} name="Integrations" isActive={pageID === 4} onClick={() => setPageID(4)} SVGObject={IconConnector}/>    */}
                                    </ul>
                                </nav>
                            }
                        />
                        <div className="col-md-9">
                            {
                                pageID === 1 ? <DataStoreFileView /> :
                                // pageID === 2 ? <></> :
                                pageID === 3 ? <DataStoreTextNotes /> :
                                // pageID === 4 ? <></> :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SymphonyDataStoreView;

import { Button, ButtonRow, Heading, ToastContext } from "cai-fusion";
import { useEffect, useContext, useState } from "react";
import { useUserProfile } from "./contexts/UserProfileContext";

const SymphonyUserSettings = () => {

    const { createSuccessToast } = useContext(ToastContext);
    const { userProfile, updateUserProfile } = useUserProfile();

    const [formObj, setFormObj] = useState({ ...userProfile }); 
    const [profileLoading, setProfileLoading] = useState(true);

    useEffect(() => {
        setProfileLoading(true);
        setFormObj({...userProfile});
        setProfileLoading(false);
    }, [userProfile])

    const updateData = (e) => {
        e.preventDefault();
        // Replace the field given with the changed value
        setFormObj({...formObj, [e.target.id]: e.target.value})
    }

    const submitChanges = async (verbose = false) => {
        if(verbose) {
            console.log("[USERPROFILE] Attempting to update user settings...");
            console.log("[USERPROFILE] UserProfile Obj:", formObj);
        }
        setProfileLoading(true);
        await updateUserProfile({...formObj});
        createSuccessToast("User Profile successfully updated.")
        setProfileLoading(false);
    }

    return (
        <>
            <Heading size={1}>Your Profile</Heading>
            <div className="row l-mt-sm">
                <div className="col-12">
                    <Heading size={3}>Personalization Info</Heading>
                    <p className="a-muted-text">Telling Maestro more about yourself can help your assistants better tailor their responses to you! By understanding your role, the type of work you do, and the ways in which you like to do it, Maestro will be better equipped to collaborate effectively.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="o-block">
                        <div className="m-form-group">
                            <label htmlFor="preferredName" className="a-label">Preferred Name</label>
                            <p className="a-subtle-text a-muted-text">How would you like Maestro to address you?</p>
                            <input 
                                id="preferredName" 
                                type="text" 
                                className="a-text-input" 
                                defaultValue={formObj.preferredName} 
                                onChange={(e) => updateData(e)} 
                                placeholder=""
                                disabled={profileLoading}
                            />
                        </div>
                        <div className="m-form-group">
                            <label htmlFor="preferredPronouns" className="a-label">Preferred Pronouns</label>
                            <p className="a-subtle-text a-muted-text">What pronouns would you like Maestro to refer to you with?</p>
                            <input 
                                id="preferredPronouns" 
                                type="text" 
                                className="a-text-input" 
                                defaultValue={formObj.preferredPronouns} 
                                onChange={(e) => updateData(e)} 
                                placeholder=""
                                disabled={profileLoading}
                            />
                        </div>
                        <div className="m-form-group">
                            <label htmlFor="jobTitle" className="a-label">Job Title</label>
                            <p className="a-subtle-text a-muted-text">Telling Maestro about your job title will give it a basic overview of what type of work you're expected to do.</p>
                            <input 
                                id="jobTitle" 
                                type="text" 
                                className="a-text-input" 
                                defaultValue={formObj.jobTitle} 
                                onChange={(e) => updateData(e)} 
                                placeholder="Client Services Manager"
                                disabled={profileLoading}
                            />
                        </div>
                        <div className="m-form-group">
                            <label htmlFor="rolesResponsibilities" className="a-label">Your Roles & Responsibilities</label>
                            <p className="a-subtle-text a-muted-text">Telling Maestro about the specifics of your role can help it provide the most appropriate responses. Telling it the type of work you do, the roles of people you work with, and the ways in which you prefer to work can all be valuable information!</p>
                            <textarea 
                                id="rolesResponsibilities" 
                                className="a-textarea-input" 
                                rows="8" 
                                defaultValue={formObj.rolesResponsibilities} 
                                onChange={(e) => updateData(e)} 
                                placeholder="For example, 'I am a client services manager at a software company. I work with clients to understand their needs and communicate them to the development team. I also manage a team of 5 people. I prefer to work in a collaborative environment and use agile methodologies.'"
                                disabled={profileLoading}
                            />
                        </div>
                        <div className="m-form-group">
                            <label htmlFor="communicationPreference" className="a-label">What are your preferences for communications?</label>
                            <p className="a-subtle-text a-muted-text">This will help Maestro understand how to most effectively communicate with you.</p>
                            <select id="communicationPreference" className="a-select-input" value={formObj.communicationPreference} onChange={(e) => updateData(e)} disabled={profileLoading}>
                                <option value="N/A">No Preference</option>
                                <option value="short">Short and sweet</option>
                                <option value="long">Detailed and thorough</option>
                            </select>
                        </div>
                        <div className="m-form-group">
                            <label htmlFor="technologyComfortabilityLevel" className="a-label">Technological Comfortability Level</label>
                            <p className="a-subtle-text a-muted-text">How well-versed in technology would you say you are? This will help Maestro give directions that make the most sense to you.</p>
                            <select id="technologyComfortabilityLevel" className="a-select-input" value={formObj.technologyComfortabilityLevel} onChange={(e) => updateData(e)} disabled={profileLoading}>
                                <option value="N/A">No Preference</option>
                                <option value="Newcomer to Technology">Newcomer - I use computers for basic tasks, but need help beyond that.</option>
                                <option value="Casual User">Casual User - Comfortable with everyday software and basic troubleshooting.</option>
                                <option value="Proficient User">Proficient User - I manage files efficiently and can handle software installation and updating.</option>
                                <option value="Skilled User">Skilled User - I am adept at advanced software, networking basics, and simple coding.</option>
                                <option value="Advanced User">Advanced User - Strong in software development, network understanding, and scripting.</option>
                                <option value="Expert in Technology">Expert User - Extensive knowledge in IT infrastructure and complex software solutions.</option>
                            </select>
                        </div>
                        <div className="m-form-group">
                            <label htmlFor="preferredSoftware" className="a-label">Preferred Software</label>
                            <p className="a-subtle-text a-muted-text">This helps Maestro provide guidance that's specific to the tools that you use every day.</p>
                            <textarea 
                                id="preferredSoftware" 
                                className="a-textarea-input" 
                                rows="5"
                                defaultValue={formObj.preferredSoftware} 
                                onChange={(e) => updateData(e)} 
                                placeholder="For example, 'I use Visual Studio Code for development and Google Chrome as a web browser. I also use the Microsoft 365 suite for documents.'"
                                disabled={profileLoading}
                            />
                        </div>
                        <div className="m-form-group">
                            <label htmlFor="preferredFormatsLangauges" className="a-label">Preferred Formats/Languages for Data and Code</label>
                            <p className="a-subtle-text a-muted-text">If there are particular programming languages or data formats you prefer to work with, let Maestro know so that your assistants are on the same page!</p>
                            <textarea 
                                id="preferredFormatsLangauges" 
                                className="a-textarea-input"
                                rows="5"
                                defaultValue={formObj.preferredFormatsLangauges} 
                                onChange={(e) => updateData(e)}
                                placeholder="If you're not sure how to answer this one, we recommend you leave it blank. Otherwise, provide information such as 'I use React Javascript and Typescript, as well as SCSS. I like my data formatted as JSON.'"
                                disabled={profileLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Web Pages section */}
            {/* <div className="row l-mt-sm">
                <div className="col-12">
                    <Heading size={3}>Web Pages</Heading>
                    <p className="a-muted-text">Are there any web pages that might help your assistant better understand you and your role? Maybe a LinkedIn profile or a profile page on your company's intranet?</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="o-block">
                        <div className="m-form-group">
                            <label for className="a-label visually-hidden">Website URL</label>
                            <input type="text" className="a-text-input" placeholder="Add a URL..." />
                        </div>
                    </div>
                </div>
            </div> */}

            <ButtonRow className="l-mt-md">
                <Button onClick={submitChanges} disabled={profileLoading}>Save Changes</Button>
            </ButtonRow>
        </>
    )
}

export default SymphonyUserSettings;
import { Heading } from "cai-fusion";
import { useEffect, useState } from "react";

const ChatQuickActions = ({ quickActions = [], onQuickActionSelected }) => {
    
    const [isExpanded, setIsExpanded] = useState(false);
    const [displayedQuickActions, setDisplayedQuickActions] = useState([]);

    useEffect(() => {
        if (quickActions.length > 2 && !isExpanded) {
            setDisplayedQuickActions([...quickActions.slice(0, 2)]);
        } else {
            setDisplayedQuickActions([...quickActions]);
        }
    }, [quickActions, isExpanded])

    useEffect(() => {
        setIsExpanded(false);
    }, [quickActions])

    return (
        <div className="m-chat-suggestions">
            <Heading size={4} className="l-mb-xs">
                Quick Actions
            </Heading>
            <ul className="m-chat-suggestions__list">
                {displayedQuickActions?.map((quickAction, index) => (
                    <li className="m-chat-suggestions__suggestion" key={index}>
                        <button
                            className="m-chat-suggestions__button"
                            onClick={(e) => {
                                e.preventDefault();
                                if (onQuickActionSelected) onQuickActionSelected(quickAction?.prompt ?? "");
                            }}
                            children={quickAction?.name ?? ""}
                        />
                    </li>
                ))}
                {(quickActions.length > 2 && !isExpanded) && 
                    <li className="m-chat-suggestions__suggestion" key="suggestion-expand-button">
                        <button
                            className="m-chat-suggestions__button"
                            onClick={() => setIsExpanded(true)}
                            children="Show More"
                        />
                    </li>
                }
            </ul>
        </div>
    );
};

export default ChatQuickActions;

export const REJECTED_FILE_EXTENSIONS = [
    // Executable and Script files
    '.exe',  // Windows executable
    '.bat',  // Windows batch file
    '.cmd',  // Windows command script
    '.scr',  // Screen saver executable
    '.com',  // DOS command file
    '.ps1',  // PowerShell script
    '.sh',   // Shell script
    '.vbs',  // Visual Basic script
    '.msi',  // Windows installer
    '.dll',  // Dynamic link library
    
    // Mobile/Browser Apps
    '.apk',  // Android package
    '.appx', // Windows app package
    '.crx',  // Chrome extension
    
    // Package Management
    '.deb',  // Debian package
    '.rpm',  // Red Hat package
    
    // Documents and Media
    '.doc',  // Word document
    '.mp3',  // Audio file
    '.wav',  // Audio file
    '.m4a',  // Audio file
    '.mp4',  // Video file
    '.avi',  // Video file
    '.mov',  // Video file
    '.wmv',  // Video file
    '.webm', // Video file
    '.gif',  // Animated image
    
    // System files
    '.lnk',  // Windows shortcut

    // Archive formats
    '.zip',  // ZIP archive
    '.7z',   // 7-Zip archive
    '.rar'   // RAR archive
];

export function createRejectedFileExtensions({
    add = [],
    remove = []
  } = {}): string[] {
    // Start with the default extensions
    const extensionsSet = new Set(REJECTED_FILE_EXTENSIONS);
  
    // Remove any extensions specified by the consumer
    remove.forEach(ext => extensionsSet.delete(ext));
  
    // Add any extra extensions requested
    add.forEach(ext => extensionsSet.add(ext));
  
    return Array.from(extensionsSet);
  }
//Purpose: Allows for customizing the rejected file types by adding or removing extensions
//Example usage: 
//   import { createRejectedFileExtensions } from "./rejectedFileTypes";

//   const customRejectedFileTypes = createRejectedFileExtensions({
//     remove: ['.mp4'],          // allow mp4 videos
//     add: ['.json']             // also reject JSON files
//   });

//   // Then pass it as a prop:
//   <DnDFileUpload
//     rejectedFileTypes={customRejectedFileTypes}
//     onFileUpload={handleUpload}
//   />
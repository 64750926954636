import React, { useEffect, useState } from "react";

const searchIcon = require("../../../images/search-icon-light.png");

const ChatSearch = ({onSearchChats}) => {
    const [searchText, setSearchText] = useState("");
    const [chipText, setChipText] = useState("");
    const [chipVisibility, setChipVisibility] = useState(false);

    const handleInputChange = (e) => {
        setSearchText(e.target.value);
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            if (!event.shiftKey) {
                event.preventDefault();
                handleIconPress(event);
            }
        }
    };

    const handleIconPress = (event) => {
        event.preventDefault();
        let trimmedQuery = searchText?.trim() ?? searchText
        setChipVisibility(trimmedQuery);
        handleSend();
    }

    const handleSend = () => {
        onSearchChats(searchText);
        setChipText(searchText);
        setSearchText("");
    };

    const closeChip = () => {
        setChipVisibility(false);
        setSearchText("");
        handleSend();
    }

    useEffect(() => {
        console.log("ChatSearch.jsx: searchText changed: " + searchText);
        console.log("ChatSearch.jsx: chipText changed: " + chipText);
        console.log("ChatSearch.jsx: chipVisibility changed: " + chipVisibility);
    }, [searchText, chipText, chipVisibility]);

    return (
        <div>
            <form className="a-search-input l-mb-xs"> 
				<textarea 
					className="a-search-input__text-box" 
					type="text" 
					placeholder="Search past chats..."
					aria-label="Search"
					id="search_field"
                    rows="1"
					name="search_field"
                    value={searchText}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                ></textarea>
				<button className="a-search-input__button" type="submit" onClick={handleIconPress}>
				<img 
					className="a-search-input__img"
					src={searchIcon}
					alt="search button icon">
				</img>
				</button>
      		</form>
              {chipVisibility && (<div className="a-chip">
                    <p className="a-chip__label">
                        <span className="a-chip__category">Search:</span>
                        "{chipText}"
                    </p>
                    <button className="a-chip__remove-btn" onClick={closeChip}>
                        <svg 
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 307.2 307.2"
                            data-inject-url="https://synthesisstorage.blob.core.windows.net/dist/assets/toolkit/images/cancel.svg"
                            className="a-chip__icon"
                            >
                            <path d="M307.2 24.136L283.064 0 153.6 129.469 24.136 0 0 24.136 129.464 153.6 0 283.064 24.136 307.2 153.6 177.736 283.064 307.2l24.136-24.136L177.736 153.6z"></path>
                        </svg>
                    </button>
                </div>)}
        </div>
    );
};

export default ChatSearch;
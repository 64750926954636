
export function isValidFile(file: File, badFiles: BadFile[], maxFileSize: number | undefined, rejectedFileTypes: string[]): boolean {
    
    // Check if the file type or extension is rejected
    const rejectedType = rejectedFileTypes.find(type => file.name.toLowerCase().endsWith(type.toLowerCase()) || file.type === type);
    if (rejectedType) {
        badFiles.push({
            name: file.name,
            reason: `File type '${rejectedType}' is not allowed`
        });
        return false;
    }
    
    // Check if the file exceeds the maximum size
    if (maxFileSize && file.size > maxFileSize) {
        badFiles.push({
            name: file.name,
            reason: `File exceeds maximum size of ${maxFileSize} bytes (actual size: ${file.size} bytes)`
        });
        return false;
    }

    return true;
}

export function validateUploadItem(
    item: DataTransferItem,
    allowFolders: boolean,
    allowZips: boolean,
    badFiles: BadFile[]
): boolean {
    // Validate folders and zip files
    if ('webkitGetAsEntry' in item) {
        const entry = item.webkitGetAsEntry();
        if (!allowFolders && (
            entry?.isDirectory || 
            (entry?.isFile && item.getAsFile()?.type === 'application/zip')
        )) {
            badFiles.push({
                name: entry?.name || 'Unknown',
                reason: 'Folder uploads are not allowed'
            });
            return false;
        }

        if (!allowZips && (
            entry?.isFile && item.getAsFile()?.type === 'application/zip'
        )) {
            badFiles.push({
                name: entry?.name || 'Unknown',
                reason: 'ZIP file uploads are not allowed'
            });
            return false;
        }

        // Additional validation for ZIP files when they are allowed
        if (allowZips && entry?.isFile) {
            const file = item.getAsFile();
            if (file?.name.toLowerCase().endsWith('.zip') && file.type !== 'application/zip') {
                badFiles.push({
                    name: file.name,
                    reason: 'Invalid ZIP file format'
                });
                return false;
            }
        }

    }

    return true;
}

export interface BadFile {
    name: string;
    reason: string;
}
import { ReactComponent as IconCancel } from "../images/cancel.svg";
import { ReactComponent as IconCancelWhite } from "../images/cancel-white.svg";
import { ReactComponent as IconCheck } from "../images/icon-check.svg";
import { ReactComponent as IconCheckmark } from "../images/icon-checkmark.svg";
import { ReactComponent as IconClose } from "../images/icon-close.svg"
import { ReactComponent as IconCopy } from "../images/icon-copy (1).svg";
import { ReactComponent as IconEllipsis } from "../images/icon-ellipsis.svg";
import { ReactComponent as IconError } from "../images/icon-chat-error.svg";
import { ReactComponent as IconInfo } from "../images/icon-info.svg"
import { ReactComponent as IconSettings } from "../images/icon-settings.svg";
import { ReactComponent as IconSuccess } from "../images/icon-check.svg";
import { ReactComponent as IconWarning } from "../images/warning.svg";

import GIFSpinner from '../images/icon-loading-spinner.gif';

export const symphonySVGs = {
    GIFSpinner,

    IconCancel,
    IconCancelWhite,
    IconCheck,
    IconCheckmark,
    IconClose,
    IconCopy,
    IconEllipsis,
    IconError,
    IconInfo,
    IconSettings,
    IconSuccess,
    IconWarning,

    // Define unique component-specific icons using the generic key "Icon{Component}{Type}".
    // See the component documentation for which icon keys are used.
    // Ex: IconSnackbarSuccess
}
import React, { useState } from "react";
import { Heading, Button, ContextMenu, ContextMenuItem, ButtonRow } from "cai-fusion";
import { useNewTemplate } from "../../contexts/NewTemplateContext";

import { ReactComponent as IconTrash } from "../../../../images/icon-trash.svg";
import { ReactComponent as IconDraft } from "../../../../images/icon-draft.svg";

const TemplateShortcuts = () => {
    const { chatTemplateShortcuts, createTemplateShortcut, editTemplateShortcut, deleteTemplateShortcut } = useNewTemplate();

    const [isCreatingShortcut, setIsCreatingShortcut] = useState(false);
    const [createdShortcutName, setCreatedShortcutName] = useState("");
    const [createdShortcutPrompt, setCreatedShortcutPrompt] = useState("");

    return (
        <div className="col-12">
            <Heading size={2}>Shortcuts</Heading>
            <p className="a-muted-text l-mb-sm">Want some optional next steps for the user to choose from? Add them here!</p>
            <Button 
                outline
                onClick={() => setIsCreatingShortcut(true)}
                children="Add Shortcut"
            />
            {isCreatingShortcut &&
                <div className="o-block l-mt-sm l-mb-sm">
                    <div className="row">
                        <div className="col-12">
                            <div className="m-form-group">
                                <label htmlFor="createName" className="a-label">Name</label>
                                <input 
                                    className="a-text-input"
                                    id="createName"
                                    onChange={(e) => setCreatedShortcutName(e.target.value)}
                                    value={createdShortcutName || ''}
                                />
                            </div>
                            <div className="m-form-group">
                                <label htmlFor="createPrompt" className="a-label">Prompt</label>
                                <textarea 
                                    className="a-textarea-input"
                                    rows={3}
                                    id="createPrompt"
                                    onChange={(e) => setCreatedShortcutPrompt(e.target.value)}
                                    value={createdShortcutPrompt || ''}
                                />
                            </div>
                            <ButtonRow>
                                <Button 
                                    outline
                                    children="Cancel"
                                    onClick={() => {
                                        setIsCreatingShortcut(false);
                                        setCreatedShortcutName("");
                                        setCreatedShortcutPrompt("");
                                    }}
                                />
                                <Button
                                    children="Create"
                                    onClick={() => {
                                        setIsCreatingShortcut(false);
                                        createTemplateShortcut(createdShortcutName, createdShortcutPrompt);
                                        setCreatedShortcutName("");
                                        setCreatedShortcutPrompt("");
                                    }}
                                />
                            </ButtonRow>
                        </div>
                    </div>
                </div>
            }
            {chatTemplateShortcuts.length > 0 ?
                chatTemplateShortcuts.filter((shortcut) => !(shortcut.isDeleted ?? false)).map((shortcut, index) => 
                    <TemplateShortcutItem 
                        key={index}
                        name={shortcut.name}
                        prompt={shortcut.prompt}
                        index={index}
                        saveShortcut={editTemplateShortcut}
                        deleteShortcut={deleteTemplateShortcut}
                    />
                ) :
                <div className="o-block l-mt-sm">
                    <p>You currently have no shortcuts. Use the Add Shortcut button to create one!</p>
                </div>
            }
        </div>
    )
}


const TemplateShortcutItem = ({name, prompt, index, saveShortcut, deleteShortcut}) => {
    const [isEditing, setIsEditing] = useState(false); 
    const [contextMenuIsHidden, setContextMenuIsHidden] = useState(true);  
    
    const [editName, setEditName] = useState(name ?? "");
    const [editPrompt, setEditPrompt] = useState(prompt ?? "");

    if (!isEditing) {
        // The regular view of quick actions, display only.
        return (
            <div className="o-block l-mt-sm" key={index}>
                <div className="row">
                    <div className="col-10">
                        <Heading size={4}>{name}</Heading>
                        <p>{prompt}</p>
                    </div>
                    <div className="col-2 h-vert-center-right">
                        <ContextMenu
                            isHidden={contextMenuIsHidden}
                            onClick={() => setContextMenuIsHidden(!contextMenuIsHidden)}
                            onBlur={() => setContextMenuIsHidden(true)}
                        >
                            <ContextMenuItem 
                                Icon={IconDraft}
                                name="Edit Shortcut"
                                onClick={() => {
                                    setEditName(name);
                                    setEditPrompt(prompt);
                                    setIsEditing(true);
                                    setContextMenuIsHidden(true);
                                }}
                            />
                            <ContextMenuItem
                                Icon={IconTrash}
                                danger
                                name="Delete Shortcut"
                                onClick={() => {
                                    deleteShortcut(index);
                                    setContextMenuIsHidden(true);
                                }}
                            />
                        </ContextMenu>
                    </div>
                </div>
            </div>
        )
    }
    else {
        // The editing view of quick actions.
        return (
            <div className="o-block l-mt-sm" key={index}>
                <div className="row">
                    <div className="col-12">
                        <div className="m-form-group">
                            <label htmlFor="editName" className="a-label">Name</label>
                            <input 
                                className="a-text-input"
                                id="editName"
                                onChange={(e) => setEditName(e.target.value)}
                                value={editName || ''}
                            />
                        </div>
                        <div className="m-form-group">
                            <label htmlFor="editPrompt" className="a-label">Prompt</label>
                            <textarea 
                                className="a-textarea-input"
                                rows={3}
                                id="editPrompt"
                                onChange={(e) => setEditPrompt(e.target.value)}
                                value={editPrompt || ''}
                            />
                        </div>
                    </div>
                </div>
                <ButtonRow>
                    <Button 
                        outline
                        onClick={() => setIsEditing(false)}
                        children="Cancel"
                    />
                    <Button 
                        onClick={() => {
                            saveShortcut(editName, editPrompt, index);
                            setIsEditing(false);
                        }}
                        children="Save"
                    />
                </ButtonRow>
            </div>
        )
    }
}

export default TemplateShortcuts;
import { Heading, Drawer, HeadingRow, Button, ContextMenu, ContextMenuItem } from "cai-fusion";
import React, { useContext, useEffect, useState } from "react";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";
import { useDataStore } from "../contexts/DataStoreContext";

import NewDataStoreSubfolderModal from "../overlay/newDataStoreSubfolderModal";
import RenameDataStoreItemModal from "../overlay/renameDataStoreItemModal";

import { ReactComponent as IconFolder } from "../../../images/icon-folder.svg";
import { ReactComponent as IconFileOutline } from "../../../images/icon-file-outline.svg";
import { ReactComponent as IconTrash } from "../../../images/icon-trash.svg";
import { ReactComponent as IconRename } from "../../../images/icon-rename.svg";
import { ReactComponent as IconDownload } from "../../../images/icon-download.svg";
import { ReactComponent as RippleLoading } from "../../../images/ripple-loading.svg";

const DataStoreFileView = ({dataStoreObj}) => {

    const { setDataStore, addContentToDataStore, deleteDataStoreContent, renameDataStoreItem, isLoading } = useDataStore();

    // Overarching file tree
    const [fileTree, setFileTree] = useState({});
    // Current position in the tree
    const [currTree, setCurrTree] = useState({});
    // List of the folders we are currently in. Empty when at root.
    const [currPath, setCurrPath] = useState([]);

    // Modal toggle states
    const [hideFolderModal, setHideFolderModal] = useState(true);
    const [hideRenameModal, setHideRenameModal] = useState(true);
    // For rename, download, delete, etc.
    const [selectedFile, setSelectedFile] = useState({});

    // Creates a tree data structure when passed in a list of files.
    const createFileStructure = (files) => {
        // Start with an empty tree
        let fileTree = {};

        files.forEach((file) => {
            if(file.source === "file"){
            // Break apart the path into each piece, we will navigate down each one.
            // path, resourceName
                const pathParts = file?.path?.split('/').filter(Boolean) ?? [];
                let curr = fileTree;
                
                // Navigate down each piece, and create the folder if needed.
                pathParts.forEach((part) => {
                    // If the file or folder doesn't exist at this point, create it.
                    if(!curr[part]) {
                        curr[part] = {
                            // If this is at the end of the path, it is a file, otherwise it is a folder.
                            "type": "folder",
                            // Establish a list of children for further folders.
                            "children": {}
                        }
                    }
                    // Navigate into the sub-folder. If there are no more parts to traverse, this will simply do nothing.
                    curr = curr[part].children;
                });
                // Append to the tree with type "file" to designate it as a file.
                curr[file?.resourceName] = {...file, "type": "file"};
            }
        })

        return fileTree;
    }

    /*
        Navigation - Changing currPath and currTree to enter and exit various folders.
    */

    // Starting from root, go to (index) of the tree.
    const goToPath = (index) => {
        let newTree = fileTree;
        for (let i = 0; i < index; i++){
            newTree = newTree[currPath[i]].children;
        }
        // Update currPath and currTree as needed.
        setCurrPath(currPath.slice(0, index));
        setCurrTree(newTree);
    }

    // Starting from the current folder, go down one level.
    const routeInSubfolder = (folderName) => {
        // Append the folder to the current path.
        setCurrPath([...currPath, folderName]);
        console.log("[DATASTORES] Navigating down, new path:", [...currPath, folderName]);
        // Update the tree by going down the current tree's folder.
        setCurrTree(currTree[folderName].children);
    }

    // Resets path and tree to initial states.
    const resetPath = () => {
        setCurrPath([]);
        setCurrTree(fileTree);
    }

    /*
        File Management - Handling of files and folders with API calls, while keeping the local data structure up to date.
    */

    // Adding files to the current position in the file tree.
    const newFile = async (files) => {
        // Add new items to the data store and redo the tree once files are all uploaded.
        let newItems = [];
        let curr = fileTree;
        currPath.forEach((piece) => {
            curr = curr[piece].children;
        })

        // Turn the uploading into a list of promises.
        const uploads = files.map(async (file) => {
            // Pre-add the item to the list, to show loading state.
            curr[file.resourceName] = {"type": "loading"}
            // Do the API call to add the content item
            let newItem = await addContentToDataStore(dataStoreObj.id, {...file, "path": currPath.join('/')});
            // ...and add the new file to the tree.
            // NOTE: This overrides anything at this tree position. Something to note for files named the same in the same folder.
            if (newItem) {
                // Replace the loading file with a regular file visual.
                curr[newItem.resourceName] = {...newItem, "type": "file"}
                setCurrTree({...currTree});
                // Append to the new item list.
                newItems.push(newItem);
            } else {
                // If the document that was uploaded errored, don't add it.
                curr[file.resourceName] = {"type": "deleted"}
                setCurrTree({...currTree});
            }
        });

        // Wait until all the uploads are done, then update the state with the new files.
        await Promise.all(uploads);
        console.log("Out of loop", newItems)
        setDataStore({...dataStoreObj, contentItems: [...dataStoreObj.contentItems, ...newItems]});
    }

    // Adding a folder to the current position in the file tree.
    const newFolder = (folderName) => {
        let curr = fileTree;
        currPath.forEach((piece) => {
            curr = curr[piece].children;
        })
        if(curr[folderName] && curr[folderName].type != "deleted"){
            console.log("This file already exists at this location.")
        }
        else {
            curr[folderName] = {
                "type": "folder",
                "children": {}
            }
            setCurrTree({...curr});
        }
    }

    const deleteFile = async (fileObj, currPathOverride) => {
        let curr = fileTree;
        let path = currPathOverride || currPath
        path.forEach((piece) => {
            curr = curr[piece].children
        });
        // We use a placeholder for type. This will make it not visible (i.e. still in the data structure), but the API call will make sure it never comes back.
        curr[fileObj.resourceName].type = "deleted"
        setCurrTree({...currTree})
        setDataStore({...dataStoreObj, contentItems: dataStoreObj.contentItems.filter((item) => item.id != fileObj.id)})

        await deleteDataStoreContent(dataStoreObj.id, fileObj.id);
    };

    const deleteFolder = async (folderName, currPathOverride) => {
        let curr = fileTree;
        let path = currPathOverride || currPath
        console.log("[DATASTORE] Deleting folder with path", path, "and folder name", folderName);
        path.forEach((piece) => {
            curr = curr[piece].children
        });
        // Step 1, delete all files in every subfolder
        // While we could very easily do this by filtering all files in the dataStoreObj by looking for the prefix of the folder, we don't have all those files there silly!
        let folder = curr[folderName].children;
        Object.entries(folder).forEach(async ([name, obj]) => {
            console.log(obj.type);
            if(obj.type === "file") {
                await deleteFile(obj, [...path, folderName]);
            } else if (obj.type === "folder") {
                await deleteFolder(name, [...path, folderName]);
            }
        })

        curr[folderName].type = "deleted"
        setCurrTree({...currTree})
    }

    const renameFile = async (fileObj, newName) => {
        console.log("Changing filename");
        // We're doing both delete and rename in this bitch because AAAAAAAAAAAAAAAAAAAAAAA
        let curr = fileTree;
        currPath.forEach((piece) => {
            curr = curr[piece].children
        });

        curr[fileObj.resourceName].type = "deleted"
        curr[newName] = {...fileObj, "resourceName": newName, "type": "file"}

        await renameDataStoreItem(dataStoreObj.id, fileObj.id, newName);
    }

    useEffect(() => {
        if (dataStoreObj?.contentItems) {
            let newTree = createFileStructure(dataStoreObj.contentItems);
            // let newTree = createFileStructure(files);
            setFileTree(newTree);

            // Navigating as far as we can down the tree with the new copy.
            if(currPath.length != 0){
                let newCurr = newTree;
                // Using a for loop instead of a forEach to support break.
                for (const [index, piece] of currPath.entries()) {
                    // Stop if the folder doesn't exist
                    if (!newCurr[piece]) {
                        setCurrPath(currPath.slice(0, index));
                        break;
                    }
                    newCurr = newCurr[piece].children
                }
                setCurrTree(newCurr);
            } else {
                setCurrTree(newTree);
            }
            console.log("[DATASTORE]", newTree);
        }
    }, [dataStoreObj]);

    return (
        <>
            <HeadingRow
                className="m-heading-row--secondary"
                title="Files"
                size={3}
            >
                <div className="m-icon-row m-icon-row--right-align">
                    <button className="a-icon-link m-icon-row__icon" onClick={() => setHideFolderModal(false)}>
                        <div className="a-icon-link__inner">
                            <div className="a-icon-link__icon-wrapper">
                                <IconFolder className="a-icon-link__icon" />
                            </div>
                            <span className="a-icon-link__text">Add Sub-Folder</span>
                        </div>
                    </button>
                </div>
            </HeadingRow>
            <div className="row">
                <div className="col-12">
                    <div className="o-block">
                        { isLoading ?
                        <div className="text-center" aria-colspan={4}>
                            <RippleLoading className="a-icon__img" style={{maxHeight: 50}}/>
                            <p className="a-muted-text">Loading content for this data collection...</p>   
                        </div> :
                        <>
                            { currPath.length > 0 && 
                                <nav className="m-breadcrumbs l-mb-xs">
                                    <ul className="m-breadcrumbs__trail">
                                        <li className="m-breadcrumbs__trail">
                                            <a className="m-breadcrumbs__link" href="#" onClick={() => resetPath()}><strong>Files</strong></a>
                                        </li>
                                        {currPath.map((folder, index) => {
                                            if(index === currPath.length - 1){
                                                return (
                                                    <li className="m-breadcrumbs__item m-breadcrumbs__item--active" key={index}>
                                                        <span className="m-breadcrumbs__link">{folder}</span>
                                                    </li>
                                                )
                                            }
                                            return (
                                                <li className="m-breadcrumbs__trail">
                                                    <a className="m-breadcrumbs__link" href="#" onClick={() => goToPath(index + 1)}><strong>{`/${folder}`}</strong></a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </nav>
                            }
                            <div style={{ marginBottom: "20px" }}>
                                <DnDFileUpload 
                                    overwriteDisplayText={`Choose file or drag files here to add to this ${currPath.length === 0 ? "data collection" : "folder"}.`}
                                    overwriteHandleFiles={newFile}
                                />
                            </div>
                            {Object.entries(currTree).filter(([name, dataItem]) => (dataItem.type === "file" || dataItem.type === "folder" || dataItem.type === "loading")).length === 0 ? 
                            <div className="col-md-12 text-center" aria-colspan={4}>
                                <p className="a-muted-text">{`You have no files in this ${currPath.length === 0 ? "data collection" : "folder"}. Feel free to upload some!`}</p>   
                            </div>
                            :
                            <table className="o-treegrid o-treegrid--compact l-mr-sm">
                                <colgroup className="o-treegrid__colgroup">
                                    <col className="o-treegrid__col o-treegrid__col--48" span={1} />
                                    <col className="o-treegrid__col o-treegrid__col--large" span={1} />
                                    <col className="o-treegrid__col o-treegrid__col--48" span={1} />
                                    <col className="o-treegrid__col o-treegrid__col--48" span={1} />
                                </colgroup>
                                <tbody>
                                    {/* Display folders first in alphabetical order */}
                                    {Object.entries(currTree).sort(([a], [b]) => a.localeCompare(b)).map(([name, obj]) => {
                                        if(obj.type === "folder") {
                                            return (
                                                <tr className="o-treegrid__row o-treegrid__row--shown" key={name}>
                                                    <td className="o-treegrid__cell">
                                                        <span className="a-icon">
                                                            <IconFolder className="a-icon__img" />
                                                        </span>
                                                    </td>
                                                    <td className="o-treegrid__cell">
                                                        <span className="a-subtle-text">
                                                            <strong>
                                                                <a href="#" onClick={() => routeInSubfolder(name)}>{ name }</a>
                                                            </strong>
                                                        </span>
                                                    </td>
                                                    <td className="o-treegrid__cell"/>
                                                    <td className="o-treegrid__cell">
                                                        <button className="a-icon-link a-icon-link--destructive" onClick={() => deleteFolder(name)}>
                                                            <div className="a-icon-link__inner">
                                                                <div className="a-icon-link__icon-wrapper">
                                                                    <IconTrash className="a-icon-link__icon" />
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                    {/* Display files next in alphabetical order */}
                                    {Object.entries(currTree).sort(([a], [b]) => a.localeCompare(b)).map(([name, obj]) => {
                                        if(obj.type === "file" || obj.type === "loading") {
                                            return (
                                                <FileItem key={name}
                                                    name={name} 
                                                    obj={obj} 
                                                    deleteFile={() => deleteFile(obj)}
                                                    renameFile={() => {
                                                        setSelectedFile(obj);
                                                        setHideRenameModal(false);
                                                    }}
                                                />
                                            );
                                        }
                                    })}
                                </tbody>
                            </table>
                            }
                        </>}
                    </div>
                </div>
            </div>
            <NewDataStoreSubfolderModal 
                isHidden={hideFolderModal}
                onClose={() => setHideFolderModal(true)}
                clickOutsideToClose
                onSubmit={(name) => newFolder(name)}
            />
            <RenameDataStoreItemModal 
                isHidden={hideRenameModal}
                onClose={() => setHideRenameModal(true)}
                clickOutsideToClose
                onSubmit={(fileObj, name) => renameFile(fileObj, name)}
                fileItem={selectedFile}
            />
        </>
    )
}

const FileItem = ({name, obj, deleteFile, renameFile}) => {
    const [menuIsHidden, setMenuIsHidden] = useState(true);

    return (
        <tr className="o-treegrid__row o-treegrid__row--shown">
            <td className="o-treegrid__cell">
                <span className="a-icon">
                    { obj.type === "file" ? 
                        <IconFileOutline className="a-icon__img" /> :
                        <RippleLoading className="a-icon__img" style={{maxHeight: 50}}/>
                    }
                </span>
            </td>
            <td className="o-treegrid__cell">
                <span className="a-subtle-text">
                    <strong>{ name }</strong>
                </span>
            </td>
            <td className="o-treegrid__cell">
                { obj.type === "file" &&
                    <ContextMenu
                        isHidden={menuIsHidden}
                        onClick={() => setMenuIsHidden(!menuIsHidden)}
                        onBlur={() => setMenuIsHidden(true)}
                    >
                        <ContextMenuItem 
                            Icon={IconRename}
                            name="Rename File"
                            onClick={renameFile}
                        />
                        {/* <ContextMenuItem 
                            Icon={IconDownload}
                            name="Download File"
                        /> */}
                    </ContextMenu>
                }
            </td>
            <td className="o-treegrid__cell">
                { obj.type === "file" &&
                    <button className="a-icon-link a-icon-link--destructive" onClick={deleteFile}>
                        <div className="a-icon-link__inner">
                            <div className="a-icon-link__icon-wrapper">
                                <IconTrash className="a-icon-link__icon" />
                            </div>
                        </div>
                    </button>
                }
            </td>
        </tr>
    )
}


export default DataStoreFileView;
import { Chat } from "../../fusion/Chat";
import { ReactComponent as IconRegenerate } from "../../../images/icon-regenerate.svg";
import { ReactComponent as IconCopy } from "../../../images/icon-copy (1).svg";
import { ReactComponent as IconLike } from "../../../images/icon-like.svg";
import { ReactComponent as IconDislike } from "../../../images/icon-dislike.svg";
import { ReactComponent as IconEmail } from "../../../images/icon-write-email.svg";
import { ReactComponent as IconDraft } from "../../../images/icon-draft.svg";
import { ReactComponent as IconReview } from "../../../images/icon-review.svg";
import { ReactComponent as IconChatError } from "../../../images/icon-chat-error.svg";
import { ReactComponent as IconSimplify } from "../../../images/icon-simplify.svg";
import { ReactComponent as IconTargetArrow } from "../../../images/icon-target-arrow.svg";
import { ReactComponent as IconSweatshirt } from "../../../images/icon-sweatshirt.svg";
import { ReactComponent as IconJustify } from "../../../images/icon-justify-paragraph.svg";
import { ReactComponent as IconCreativity } from "../../../images/icon-creativity.svg";
import { ReactComponent as IconBriefcase } from "../../../images/icon-briefcase.svg";
import { ReactComponent as IconSubject } from "../../../images/icon-subject.svg";

import myCaiApiService from "../../../services/mycai-api-v1";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useMessages } from "../contexts/MessageContext";
import { useAccess } from "../contexts/AccessContext";
import { useProfilePictures } from "../contexts/ProfilePictureContext";
import { Avatar } from "../../fusion";
import { marked } from "marked";
import { useTransientVisibility } from "../../fusion/Contexts/VisualContext";
import { copyMarkdownToClipboard } from "../../fusion/Utilities/CopyContent";

const avatarAI = require("../../../images/avatar-ai-classic.png");

const SymphonyChat = ({
	lockInput,
	editingMessageId,
	setEditingMessageId,
}) => {
	let { graphData, impersonating, apiAccessToken } = useAuthentication();
	let { userProfileImages } = useProfilePictures();
	const { addTemporaryAssistantMessage, getAuditLog } = useMessages();
	const { canSendMessage } = useAccess();
	const { regenerateMessage } = useMessages();	
	const { show } = useTransientVisibility();

	return (
		<Chat
			lockInput={lockInput}
			editingMessageId={editingMessageId}
			setEditingMessageId={setEditingMessageId}
			// let the Application define the actions, useful for reusing Chat across types of apps
			chatEntryActions={{
				user: canSendMessage
					? [
							{
								icon: IconDraft,
								alt: "Edit Message",
								onClick: (
									messageId,
									messagePairId,
									text,
									chatFunctions
								) => {
									setEditingMessageId(messageId);
								},
							},
							{
								icon: IconCopy,
								alt: "Copy Message",
								onClick: (
									messageId,
									messagePairId,
									text,
									chatFunctions
								) => copyMarkdownToClipboard(text), 
							},
					  ]
					: [
							{
								icon: IconCopy,
								alt: "Copy Message",
								onClick: (
									messageId,
									messagePairId,
									text,
									chatFunctions
								) => copyMarkdownToClipboard(text), //navigator.clipboard.writeText(text),
							},
					  ],
				assistant: canSendMessage
					? [
							{
								icon: IconRegenerate,
								alt: "Regenerate Message",
								// onClick: (messageId, messagePairId, text, chatFunctions) => {
								//   regenerateMessage(messageId);
								// },
								options: [
									{
										className: "m-context-menu__item",
										buttonClassName:
											"a-icon-link a-icon-link--block",
										modalTarget: "rename-modal",
										text: "Shorter",
										IconElement: (
											<IconSubject className="a-icon-link__icon" />
										),
										actionFunction: (messageId) =>
											regenerateMessage(
												messageId,
												"Shorter"
											),
									},
									{
										className: "m-context-menu__item",
										buttonClassName:
											"a-icon-link a-icon-link--block",
										modalTarget: "rename-modal",
										text: "Longer",
										IconElement: (
											<IconJustify className="a-icon-link__icon" />
										),
										actionFunction: (messageId) =>
											regenerateMessage(
												messageId,
												"Longer"
											),
									},
									{
										className: "m-context-menu__item",
										buttonClassName:
											"a-icon-link a-icon-link--block",
										modalTarget: "rename-modal",
										text: "Simpler",
										IconElement: (
											<IconSimplify className="a-icon-link__icon" />
										),
										actionFunction: (messageId) =>
											regenerateMessage(
												messageId,
												"Simpler"
											),
									},
									{
										className: "m-context-menu__item",
										buttonClassName:
											"a-icon-link a-icon-link--block",
										modalTarget: "rename-modal",
										text: "More Casual",
										IconElement: (
											<IconSweatshirt className="a-icon-link__icon" />
										),
										actionFunction: (messageId) =>
											regenerateMessage(
												messageId,
												"More Casual"
											),
									},
									{
										className: "m-context-menu__item",
										buttonClassName:
											"a-icon-link a-icon-link--block",
										modalTarget: "rename-modal",
										text: "More Professional",
										IconElement: (
											<IconBriefcase className="a-icon-link__icon" />
										),
										actionFunction: (messageId) =>
											regenerateMessage(
												messageId,
												"More Professional"
											),
									},
									{
										className: "m-context-menu__item",
										buttonClassName:
											"a-icon-link a-icon-link--block",
										modalTarget: "rename-modal",
										text: "More Creative",
										IconElement: (
											<IconCreativity className="a-icon-link__icon" />
										),
										actionFunction: (messageId) =>
											regenerateMessage(
												messageId,
												"More Creative"
											),
									},
									{
										className: "m-context-menu__item",
										buttonClassName:
											"a-icon-link a-icon-link--block",
										modalTarget: "rename-modal",
										text: "More Focused",
										IconElement: (
											<IconTargetArrow className="a-icon-link__icon" />
										),
										actionFunction: (messageId) =>
											regenerateMessage(
												messageId,
												"More Focused"
											),
									},
									{
										className: "m-context-menu__item",
										buttonClassName:
											"a-icon-link a-icon-link--block",
										modalTarget: "rename-modal",
										text: "Just Try Again",
										IconElement: (
											<IconRegenerate className="a-icon-link__icon" />
										),
										actionFunction: (messageId) =>
											regenerateMessage(
												messageId,
												"Just Try Again"
											),
									},
									{
										className: "m-context-menu__item",
										buttonClassName:
											"a-icon-link a-icon-link--block a-icon-link--disabled",
										modalTarget: "rename-modal",
										text: "Other Instruction",
										helpText: "Coming Soon",
										IconElement: <></>,
										actionFunction: (e) => {
											e.preventDefault();
										},
									},
								],
							},
							{
								icon: IconCopy,
								alt: "Copy Message",
								onClick: (
									messageId,
									messagePairId,
									text,
									chatFunctions
								) => {
									copyMarkdownToClipboard(text);
									show('PlagiarismSnackbar');
								},
							},
							//   {
							//     icon: IconLike,
							//     alt: "Like Message",
							//     onClick: (messageId, messagePairId, text, chatFunctions) => {
							//       myCaiApiService.SymphonyApiService.RateMessage(
							//         apiAccessToken,
							//         impersonating,
							//         messagePairId,
							//         true // true for like
							//       )
							//         .then((response) => {
							//           console.log(response);
							//           // update UI or state based on response
							//         })
							//         .catch((error) => {
							//           console.log(error);
							//           // handle error
							//         });
							//     },
							//   },
							{
								icon: IconDislike,
								alt: "Dislike Message",
								onClick: (
									messageId,
									messagePairId,
									text,
									chatFunctions
								) => {
									myCaiApiService.SymphonyApiService.RateMessage(
										apiAccessToken,
										impersonating,
										messagePairId,
										false // false for dislike
									)
										.then((response) => {
											console.log(response);
											// update UI or state based on response
											// TODO, make an API call with the assistant instruction only if the user dislikes the message
											// this will tune the response to the assistant, and possibly have more context
											addTemporaryAssistantMessage(
												"I'm really sorry to hear that my response didn't meet your expectations. I'm here to assist you in the best way possible. Could you please clarify or provide more details on how I can assist you better? Your feedback will greatly help me in improving our interaction."
											);
										})
										.catch((error) => {
											console.log(error);
											// handle error
										});
								},
							},
							{
								icon: IconReview,
								alt: "View Audit Trail",
								onClick: async (
									messageId,
									messagePairId,
									text,
									chatFunctions
								) => {
									getAuditLog(messageId);
									show('AuditLogDrawer');
								},
							},
					  ]
					: [
							{
								icon: IconCopy,
								alt: "Copy Message",
								onClick: (
									messageId,
									messagePairId,
									text,
									chatFunctions
								) => copyMarkdownToClipboard(text), //navigator.clipboard.writeText(text),
							},
					  ],
				error: canSendMessage
					? [
							{
								icon: IconEmail,
								alt: "Email Error",
								onClick: (
									messageId,
									messagePairId,
									text,
									chatFunctions
								) => {
									const bugReportLink = `mailto:Christopher.Zumberge@cai.io?cc=eae5321e.cai.io@amer.teams.ms&subject=Error%20Report%20from%20Maestro%20Chat&body=Please%20feel%20free%20to%20add%20any%20additional%20details%20or%20notes%20here%3B%0D%0A%0D%0AReported%20On%3A%20${new Date().toLocaleString(
										"en-US",
										{
											month: "short",
											day: "numeric",
											year: "numeric",
											hour: "numeric",
											minute: "numeric",
										}
									)}%0D%0AReported%20By%3A%20${
										graphData.displayName
									}%0D%0A%0D%0AMessageId%3A%20${messageId}%0D%0AMessagePairId%3A%20${messagePairId}%0D%0A`;
									window.location.href = bugReportLink;
								},
							},
							{
								icon: IconRegenerate,
								alt: "Regenerate Message",
								onClick: (
									messageId,
									messagePairId,
									text,
									chatFunctions
								) => {
									regenerateMessage(messageId);
								},
							},
					  ]
					: [],
				info: [],
			}}
			iconElements={{
				user: (lottieRef, UserId) => {
					return <Avatar src={userProfileImages[UserId] ?? null} />;
				},
				assistant: (lottieRef, UserId) => {
					return (
						// <Lottie
						// 	lottieRef={lottieRef}
						// 	loop={true}
						// 	options={{
						// 		rendererSettings: {
						// 			preserveAspectRatio: "xMidYMid slice",
						// 		},
						// 	}}
						// 	animationData={chatLoadingAnimation}
						// />
						<img src={avatarAI} className="a-avatar__img a-avatar__img--square" /> 
					);
				},
				error: (lottieRef, UserId) => {
					return (
						<IconChatError className="a-avatar__img a-avatar__img--square" />
					);
				},
			}}
		/>
	);
};
export default SymphonyChat;

import { createContext, useState, useContext, useEffect, useRef } from "react";
import { ToastContext } from "cai-fusion";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useUserProfile } from "./UserProfileContext";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useNavigate, useParams } from "react-router-dom";
const DataStoreContext = createContext();

function DataStoreProvider({ children }) {
    const apiServiceClient = useSymphonyApiService("v2");
    const { apiAccessToken, isAuthenticated } = useAuthentication();
    const { userProfile } = useUserProfile();
    const { createToast } = useContext(ToastContext);

    const [dataStoreId, setDataStoreId] = useState(null);

    // All data stores as confined by the search criteria.
    const [dataStores, setDataStores] = useState([]); // List of DataStores
    // All, every.
    const [allDataStores, setAllDataStores] = useState([]);

    const [dataStore, setDataStore] = useState(null); // Single DataStore (the user's current chosen one)
    const [contentItems, setContentItems] = useState([]);
    const [contentItem, setContentItem] = useState(null); //A chosen Single Content Item could be used in future
    
    const [sharedUsers, setSharedUsers] = useState([]); //used to show what users (and their role) for a given DataStore
    //use sharedUsers.membership to get role
    
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [usersharedUsership, setUsersharedUsership] = useState(0);
    const [ canShare, setCanShare] = useState(true);
    const membershipLevels = {
        1: "Manager",
        2: "Contribute",
        3: "View Only"
    };

    const searchUsers = async (query) => {
        return await apiServiceClient.UserProfiles.searchUsers(query);
    }

    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            getDataStores();
        }
    }, [isAuthenticated, apiAccessToken]);

    useEffect(() => {
        const theUser = sharedUsers.find(user => user.userProfileId === userProfile.userProfileId)
        const userMembership = theUser?.membership
        setCanShare(userMembership < 2);
    }, [userProfile, usersharedUsership, sharedUsers])

    // When ID is updated
    useEffect(() => {
        if(dataStoreId && isAuthenticated){
            // If we already have the object, what's the point in refetching it?
            if(dataStore?.id != dataStoreId){
                fetchDataStore();
            }
        }
    }, [dataStoreId, isAuthenticated]);

    const fetchDataStore = async () =>{
        if(!dataStoreId || !isAuthenticated) return;
        try {
            console.log("[DATASTORE] Attempting to fetch data store...")
            const response = await apiServiceClient.DataStore.getDataStore(dataStoreId);
            console.log("[DATASTORE] Recieved data store:", response)
            const { contentItems, accesses } = response;
            // Updating states
            setDataStore(response);
            setContentItems(contentItems);
            setSharedUsers(accesses);
        } catch (error) {
            createToast("Failed to fetch data store","error");
        } finally {
           // setIsLoading(false);
        }
    };

    const selectDataStore = async (destDataStoreId) => {
        console.log("[DATASTORES] Entering data store with ID", destDataStoreId);
        setDataStoreId(destDataStoreId);
        await fetchDataStore();
        navigate(`/symphony/data/${destDataStoreId}`);
    }

    //The list of DataStores
    const getDataStores = async () => {
        setIsLoading(true);
        try {
            const newDataStores = await apiServiceClient.DataStore.getDataStores();
            setDataStores(newDataStores ?? []);
            setAllDataStores([...newDataStores] ?? []);
            console.log("[DATASTORES] Found data stores!", newDataStores);
        } catch (error) {
            console.log("[DATASTORES] Error when grabbing data stores:", error);
        }
        setIsLoading(false);
    }

    const searchDataStores = async (query, silent = false) => {
        // Handling the event of an empty search query
        if(!query || !query.trim()){
            setDataStores(allDataStores);
            return;
        }

        // Setting loading state for UI loading components
        if(!silent) setIsLoading(true);

        console.log("[DATASTORES] Searching data stores with query", query);
		let respDataStores = await apiServiceClient.DataStore.getDataStores(query);
        // NOTE: chats is the *filtered* list of chats, allChats is all of them.
        console.log("[DATASTORES] Results:", respDataStores);
		setDataStores([...respDataStores]);

        setIsLoading(false);
    }

    const createDataStore = async (name, description = undefined) => {
        setIsLoading(true);
        console.log("[DATASTORES] Creating a new data store named", name, "...");
        try {
            // Create a temporary data store, the ID being "loadingitem" controls conditional rendering.
            setDataStores([...dataStores, {"id": "loadingitem", "name": name, "description": description}]);
            // Construct the data store object from the name and description
            let result = await apiServiceClient.DataStore.createDataStore({ name: name, description: description });
            // Filter out the temporary data store and add the new fully assembled data store.
            setDataStores([...dataStores.filter((dataStore) => dataStore.id !== "loadingitem"), result]);
            createToast("Data collection created successfully.", "success");
        } catch (error) {
            console.log("[DATASTORES] Error creating a new data store:", error);
            createToast("There was a problem creating your data collection.", "error");
        }
        setIsLoading(false);
    }

    const getDataStoreContentItem = async (dataStoreId, contentItemId) => {
        setIsLoading(true);
        try {
            const contentItem = await apiServiceClient.DataStore.getDataStoreContentItem(dataStoreId, contentItemId);
            setContentItem(contentItem);
        } catch (error) {
            setError(error);
            createToast("Error fetching content item", "error" );
        } finally {
            setIsLoading(false);
        }
    };

    const removeUserDataStoreAccess = async (userId) => {
        setIsLoading(true);
        try {
            await apiServiceClient.DataStore.removeUserDataStoreAccess(dataStoreId, userId.userProfileId);
            const response = await apiServiceClient.DataStore.getDataStore(dataStoreId); //could optimize by creating a getActive users only api call
            setSharedUsers(response.accesses);
            createToast("User access removed successfully", "success" );
        } catch (error) {
            setError(error);
            createToast("Error removing user access", "error" );
        } finally {
            setIsLoading(false);
        }
    };

    const updateDataStoreAccess = async (userId, membership) => {
        setIsLoading(true);
        try {
            await apiServiceClient.DataStore.updateDataStoreAccess(dataStoreId, userId.userProfileId,  membership);
            const response = await apiServiceClient.DataStore.getDataStore(dataStoreId);
            replaceUser(userId.userProfileId, response.accesses);
            createToast("DataStore access updated successfully", "success" );
        } catch (error) {
            setError(error);
            createToast("Error updating DataStore access", "error" );
        } finally {
            setIsLoading(false);
        }
    };

    const replaceUser = (userId, accessList) =>{
        const updatedUsers = accessList.map(user => user.userProfileId === userId.userProfileId ? userId : user)
        setSharedUsers(updatedUsers);
    }
    const addContentToDataStore = async (dataStoreId, file) => {
        setIsLoading(true);
        try {
            const result = await apiServiceClient.DataStore.addContentToDataStore(dataStoreId, file);
            console.log("[DATASTORES] Upload finished. New item:", result);
            if (!result.isSuccess) {
                throw new Error("File upload failure.");
            }
            createToast("File was added to collection successfully.", "success" );
            setIsLoading(false);
            return result.result;
        } catch (error) {
            setError(error);
            createToast("An error occurred when adding your file. File was not added to collection.", "error" );
            setIsLoading(false);
            return undefined;
        }
    };

    const addNoteToDataStore = async (dataStoreId, name, text) => {
        setIsLoading(true);
        const result = await apiServiceClient.DataStore.addContentToDataStore(dataStoreId, {resourceName: name, text});
        console.log(result);
        setIsLoading(false);
        return result?.result;
    }

    const getDataStoreContents = async (dataStoreId) => {
        setIsLoading(true);
        try {
            const contentItems = await apiServiceClient.DataStore.getDataStoreContents(dataStoreId);
            setContentItems(contentItems);
        } catch (error) {
            setError(error);
            createToast("Error fetching DataStore contents", "error" );
        } finally {
            setIsLoading(false);
        }
    };

    const renameDataStore = async (dataStoreId, newName) => {
        console.log("[DATASTORE] Changing name for data store...");
        // Local update of the list of data stores
        let changedDataStore = dataStores.find((dataStore) => dataStore.id === dataStoreId);
        changedDataStore.name = newName;
        setDataStores([...dataStores]);
        // Backend/API update of the list of data stores
        await apiServiceClient.DataStore.renameDataStore(dataStoreId, newName);

    }

    const changeDataStoreDescription = async (dataStoreId, newDescription) => {
        console.log("[DATASTORE] Changing description for data store...");
        // Local update of the list of data stores
        let changedDataStore = dataStores.find((dataStore) => dataStore.id === dataStoreId);
        changedDataStore.description = newDescription;
        setDataStores([...dataStores]);
        // Backend/API update of the list of data stores
        await apiServiceClient.DataStore.updateDataStoreDescription(dataStoreId, newDescription);
    }

    const deleteDataStore = async () => {
        setIsLoading(true);
        try {
            console.log("[DATASTORES] Attempting to delete data store...")
            await apiServiceClient.DataStore.deleteDataStore(dataStoreId);
            setDataStores(dataStores.filter((dataStore) => dataStore.id != dataStoreId));
            setAllDataStores(allDataStores.filter((dataStore) => dataStore.id != dataStoreId));
        } catch (error) {
            console.log("[DATASTORES] Error when deleting data store.")
        }
        setIsLoading(false);
    }

    const deleteDataStoreContent = async (dataStoreId, contentId) => {
        console.log("[DATASTORES] Deleting a file with ID", contentId);
        await apiServiceClient.DataStore.removeDataStoreContent(dataStoreId, contentId);
    }

    const shareDataStore = async (user, membership) =>{
        if (!dataStoreId){
            console.error("[SHAREDATASTORE] NO DATASTORE HERE DUDE");
            return;
        }

        if(user){
            console.log("[SHAREDATASTORE] Sharing datastore with a user.")
            const resp = await apiServiceClient.DataStore.shareDataStore(dataStoreId, undefined, user.identifier, membership);
            //const resp = await apiServiceClient.DataStore.getActiveUsers(dataStoreId);
            setSharedUsers([...sharedUsers, resp]);
        }
    };

    // Get the chat members of the current chat.
    const getDataStoreMembers = async () => {
        try {
            let members = dataStore?.accesses;
            console.log("[ACCESS] Get chat members: ", members);
            setSharedUsers(members ?? []);

            const membership = members.find(
                (x) => x.userProfileId === userProfile?.userProfileId
            );

            if(membership) {
                setUsersharedUsership(membership.membership);
            }
            else {
                //It should never be possible to get here, since the backend won't return the chat if the user doesn't have access, but including for security sake
                console.error("[DATASTORE] User does not have access to this chat.");
            }
        }
        catch(error){
            console.error("[DATASTORE] Error getting chat members", error);
            // possible leaveChat
        }
        finally {
            return sharedUsers;
        }
        
    }

    // Check if a user is a member of the current chat
    const isMember = async (user) => {
        console.log("[SHARECHAT] " + user.identifier + " being checked for membership");
        await getDataStoreMembers();
        const membership = sharedUsers.find(
            (x) => x.userProfileId === user?.userProfileId
        );
        return membership;
    }

    const toggleFavoriteDataStore = async (dataStoreId, isFavorited) => {
        let currDataStore = dataStores.find((dataStore) => dataStore.id === dataStoreId);
        console.log("[DATASTORES] Toggling favorite for ID", dataStoreId, "to", !isFavorited);
        if(isFavorited){
            await apiServiceClient.DataStore.unfavoriteDataStore(dataStoreId);
        } else {
            await apiServiceClient.DataStore.favoriteDataStore(dataStoreId);
        }
        currDataStore.isFavorited = !currDataStore.isFavorited;
        // Proc update to the data store list to update rendering
        setDataStores([...dataStores]);
    }

    const renameDataStoreItem = async (dataStoreId, contentId, newName) => {
        console.log("[DATASTORE] Renaming file...");
        await apiServiceClient.DataStore.renameDataStoreContent(dataStoreId, contentId, newName);
    }

    return (
        <DataStoreContext.Provider
            value={{
                // States
                dataStoreId,
                setDataStoreId,
                dataStores,
                allDataStores,
                dataStore,
                setDataStore,
                contentItem,
                contentItems,
                sharedUsers, //List of users who have access to this, you can revoke and adjust access levels by using sharedUsers props (membership, userprofileid)
                isLoading,
                error,
                canShare,
                usersharedUsership,
                membershipLevels,
                // Funcs
                searchUsers,
                isMember,
                getDataStores,
                searchDataStores,
                selectDataStore,
                fetchDataStore,
                createDataStore,
                deleteDataStore,
                deleteDataStoreContent,
                getDataStoreContentItem,
                removeUserDataStoreAccess,
                updateDataStoreAccess,
                addContentToDataStore,
                addNoteToDataStore,
                getDataStoreContents,
                renameDataStore,
                changeDataStoreDescription,
                shareDataStore,
                toggleFavoriteDataStore,
                renameDataStoreItem,
            }}
        >
            {children}
        </DataStoreContext.Provider>
    );
}

function useDataStore() {
    const context = useContext(DataStoreContext);
    if (!context) throw new Error("useDataStore must be used within a DataStoreProvider");
    return context;
}

export { DataStoreProvider, useDataStore };

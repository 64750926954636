import { Button, ButtonRow, Heading, ToastContext } from "cai-fusion";
import { useEffect, useContext, useState } from "react";
import InputColor from "react-input-color";
import DnDFileUpload from "../fusion/FileUpload/DragAndDrop";
import { useAdminApiService } from "../../hooks/useAdminApi";

const ColorPicker = ({id, onUpdate, display, value}) => {

    const handleUpdate = (e) => {
        onUpdate(id, e.hex);
    }

    return (
        <div className="col-md-6" key={id}>
            <div className="m-form-group">
                <label htmlFor={id} className="a-label a-label--required">{display}</label>
                <InputColor
                    id={id}
                    onChange={handleUpdate}
                    initialValue={value}
                />
            </div>
        </div>
    )
}

const AdminCustomizationSettings = () => {

    const { createSuccessToast, createErrorToast } = useContext(ToastContext);
    const apiServiceClient = useAdminApiService();

    // Placeholder object.
    const [formObj, setFormObj] = useState({}); 

    // List of all the color IDs - used to create each individual color input
    const [colorIds, setColorIds] = useState(Object.keys(formObj) ?? []);

    // Revert state so that you can undo your changes
    const [revertColors, setRevertColors] = useState({...formObj});
    // Loading state for getting and setting via the API (NFI, TODO)
    const [colorsLoading, setColorsLoading] = useState(false);

    // Reference to the root styles so we can edit them as the user edits the colors.
    const rootStyle = document.documentElement.style;

    // Since each row holds 2 color pickers, this divides by 2. Riveting technology.
    const rowNum = Math.ceil((colorIds?.length ?? 0) / 2);

    useEffect(() => {
        getColors();
    }, [])

    const getColors = async () => {
        setColorsLoading(true);
        try {
            let colorstest = await apiServiceClient.Styles.getColors();
            console.log("[COLORS] Colors recieved:", colorstest);
            setFormObj({...colorstest});
            setRevertColors({...colorstest});
            setColorIds(Object.keys(colorstest))
        }
        catch {
            console.log("[COLORS] Get color failed.")
        }
        setColorsLoading(false);
    }

    const handleLogoChange = (file) => {
        // Icon stuff NFI
        console.log("hi :D");
    }

    const updateData = (field, val) => {
        // Replace the field given with the changed value
        setFormObj({...formObj, [field]: val})
        if(!colorsLoading){
            rootStyle.setProperty(("--" + field), val);
        }
    }

    const submitChanges = async (verbose = false) => {
        if(verbose) {
            console.log("[COLORS] Attempting to save color changes...");
            console.log("[COLORS] Color change saving not implemented yet. Here's what we got:", formObj);
        }

        // Save impl
        // setColorsLoading(true);
        try {
            console.log("[COLORS] Attempting to save colors:", formObj);
            await apiServiceClient.Styles.setColors(formObj);
            createSuccessToast("Colors updated successfully.")
        }
        catch(error) {
            createErrorToast("ERROR: Colors were unable to be saved properly.")
        }
        // setColorsLoading(false);
    }

    // Format an ID (ex: "action-color" => "Action Color")
    const formatName = (colorId) => {
        // Split the string into an array of words, capitalize the first letter of each, then paste it back together
        return colorId.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '); 
    }

    return (
        <>
            <Heading size={1}>Theme Customization</Heading>
            {/* <div className="row l-mt-sm">
                <div className="col-12">
                    <Heading size={3}>Logo</Heading>
                    <p className="a-muted-text">Update the logo displayed in the top-level navigation.</p>
                </div>
            </div> */}
            {/* <div className="row">
                <div className="col-12">
                    <div className="o-block">
                        <div className="row">
                            <div className="col-3">
                                <div className="m-form-group">
                                    <label className="a-label">Current Logo</label>
                                    <img style={{maxHeight:'50px'}} src="../../images/pitt-shield.png" alt="Current Logo"></img>
                                </div>
                            </div>
                            <div className="col-9">
                                <DnDFileUpload
                                    onFileUpload={handleLogoChange}
                                    rejectedFileTypes={['.exe', '.bat', '.cmd', '.scr', '.com', '.ps1', 
                                        '.sh', '.vbs', '.msi', '.dll', '.apk', '.appx', '.crx', 
                                        '.deb', '.rpm', '.doc', '.mp3', '.wav', '.m4a', '.mp4', '.avi', '.mov', '.wmv', '.webm',
                                        '.zip', '.gif', '.7z', '.zip', '.rar', 'lnk']}
                                    displayText="Choose or drag a file here to replace the logo."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row l-mt-sm">
                <div className="col-12">
                    <Heading size={3}>Colors</Heading>
                    <p className="a-muted-text">Choose colors to best represent your brand across myCAI. Please ensure that the colors you choose meet accessibility guidelines for contrast!</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="o-block">
                        {/* Split all the colors into groups of 2. Fill each row up with 2 color pickers */}
                        {colorsLoading ? 
                            <div className="l-row">
                                <div className="col-md-12 text-center">
                                    <Heading size={5}>Loading colors...</Heading>
                                </div>
                            </div>
                        :
                        Array.from({length: rowNum}).map((_, rowIndex) => (
                            <div className="row" key={rowIndex}>
                                {colorIds.slice(rowIndex * 2, rowIndex * 2 + 2).map((item) => (
                                    <ColorPicker
                                        key={item}
                                        id={item}
                                        onUpdate={updateData}
                                        display={formatName(item)}
                                        value={formObj[item]}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <ButtonRow className="l-mt-md">
                <Button outline onClick={() => setFormObj({...revertColors})}>Revert Changes</Button>
                <Button onClick={() => submitChanges(true)}>Save Changes</Button>
            </ButtonRow>
        </>
    )
}

export default AdminCustomizationSettings;
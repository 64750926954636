import { useChat } from "../contexts/ChatContext";
import { useTransientVisibility } from "../../fusion/Contexts/VisualContext";
import { Button, Modal } from "cai-fusion";

const LeaveSharedChatModal = () => {
    const { chat, leaveChat } = useChat();
    const { visibilityStates, hide } = useTransientVisibility();

    return (
        <Modal
            id="leave-modal"
            isHidden={!visibilityStates.LeaveSharedChatModal}
            clickOutsideToClose={true}
            title="Leave Shared Chat"
            onClose={(e) => {
                hide('LeaveSharedChatModal');
                e.preventDefault();
            }}
            actions={
                <>
                    <Button outline onClick={(e) => { e.preventDefault(); hide('LeaveSharedChatModal'); }} children="Cancel"/>
                    <Button danger onClick={(e) => { e.preventDefault(); leaveChat(true); hide('LeaveSharedChatModal'); }} children="Leave Chat"/>
                </>
            }
        >
            <div className="l-row">
                <div className="l-one-col">
                    <p><strong>{`Are you sure you want to leave ${chat?.name ? (`"` + chat.name + `"`) : "this shared chat"}?`}</strong></p>
                    <p>Once you leave, you'll need to get this chat shared to you again. Please confirm!</p>
                </div>
            </div>
        </Modal>
    );
}

export default LeaveSharedChatModal;
import React, { useEffect } from "react";
import { Badge, Progress } from "../../fusion";
import { Button, Treegrid } from "cai-fusion";
import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";

import { ReactComponent as IconTrash } from "../../../images/icon-trash.svg"

// Both displays and handles file uploads.
const SymphonyFileHandler = ({files = [], setFiles, noFilesDisplayText, uploadDisplayText}) => {

    // These two functions are what I needed to do to not immediately navigate when uploading a file.
    const deleteFiles = (fileInd) => {
        let filesCopy = [...files];
        filesCopy = [...filesCopy.slice(0, fileInd), ...filesCopy.slice(fileInd + 1)]
        setFiles([...filesCopy]);
    }

    const handleFileUpload = async (newFiles) => {
        setFiles([...files, ...newFiles]);
        console.log("[FILEHANDLER] Added files. New file list:", ([...files], [...newFiles]));
    }

    const appendTimestampToFileName = (fileName) => {
        const timestamp = new Date()
            .toISOString()
            .replace(/[:\-]/g, "")
            .replace(/\..*$/, "");
        const nameWithoutExt = fileName.replace(/\.[^/.]+$/, "");
        const ext = fileName.split(".").pop();
        return `${nameWithoutExt}_${timestamp}.${ext}`;
    };

    const handleFiles = (pastedFiles) => {
        const fileObjects = pastedFiles.map((file) => {
            return new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.onload = (e) => {
                    let fileName = file.name.toLowerCase();
                    if (
                        fileName === "image.png" ||
                        fileName === "image.jpg" ||
                        fileName === "image.jpeg"
                    ) {
                        fileName = appendTimestampToFileName(file.name);
                    }
                    resolve({
                        resourceName: fileName,
                        content: e.currentTarget.result,
                        uploading: true, 
                        type: file.type
                    });
                };
                reader.onerror = () => {
                    reject(new Error(`Failed to read file ${file.name}.`));
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(fileObjects).then((objs) => handleFileUpload(objs));
    }

    const handlePaste = (event) => {
        const clipboardItems = event.clipboardData.items;
        const files = [];
        for (const item of clipboardItems) {
            if (item.kind === 'file') {
                const file = item.getAsFile();
                if (file) {
                    files.push(file);
                }
            }
        }
        if (files.length > 0) {
            handleFiles(files);
        }
    }

    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <>
            {/* File display portion */}
            <Treegrid
                columnSizes={["medium", "32"]}
                isCompact
                className="l-mr-sm"
            >
                { files?.length === 0
                    ? noFilesDisplayText && 
                        <tr className="o-treegrid__row o-treegrid__row--shown">
                            <td className="o-treegrid__cell">{noFilesDisplayText}</td>
                        </tr>
                    : files?.map((file, index) => (
                        <tr className="o-treegrid__row o-treegrid__row--shown" key={index}>
                            <td className="o-treegrid__cell">
                                <span className="a-subtle-text">
                                    <strong>{file.resourceName}</strong>
                                </span>
                                {file?.pendingUpload && (
                                    <span style={{ marginLeft: "20px" }}>
                                        <Badge hue="primary-500" value="New" />
                                    </span>
                                )}
                            </td>
                            <td className="o-treegrid__cell o-treegrid__cell--right-aligned">
                                <button className="a-icon-link a-icon-link--destructive" onClick={(e) => { e.preventDefault(); deleteFiles(index) }}>
                                    <div className="a-icon-link__inner">
                                        <div className="a-icon-link__icon-wrapper">
                                            <IconTrash className="a-icon-link__icon"/>
                                        </div>
                                    </div>
                                </button>
                            </td>
                        </tr>
                    ))
                }
            </Treegrid>
            <div style={{ marginBottom: "20px" }}>
                {/* TODO: Overhaul DnDFileUpload */}
                <DnDFileUpload
                    onFileUpload={handleFileUpload}
                    displayText={uploadDisplayText}
                />
            </div>
        </>
    )
}

export default SymphonyFileHandler;

// Package imports
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink, Navigate, useParams } from "react-router-dom";
import { Button, Modal } from "cai-fusion";

import "./styles/toolkit.css";

// Services, Configs & Contexts
import { callMsGraphUsers } from "./services/graph";
import myCaiApiService from "./services/mycai-api-v1";
import { useAuthentication } from "./contexts/AuthContext";
import { SettingContextWrapper } from "./contexts/SettingsContext";

// App Config Wrapper
import { useAppConfig } from "./contexts/AppConfigContext";

// Local Fusion component imports & Toolbar stuff
import { Overlay, OverlayTitle } from "./components/fusion";
import { Toolbar, ToolbarAppLauncher, ToolbarLogo } from "./components/fusion/Toolbar";
import { ToolbarLinks, ToolbarRight } from "./components/fusion/Toolbar/ToolbarLink";
import { Form, FormGroup } from "./components/fusion/Input";
import { Option, Select } from "./components/fusion/Select";
import { Progress } from "./components/fusion/Progress";
import ReportBugLink from "./components/toolbarLinks/ReportBugLink";
import SettingsLink from "./components/toolbarLinks/SettingsLink";
import NotificationOverlay from "./components/pages/notificationOverlay";

// Pages & Apps
import BonusPlanningApp from "./components/pages/bonusPlanning";
import AdminSettingsPage from "./components/pages/adminSettingsPage";
import SettingsPage from "./components/pages/settingsPage";
import HomePage from "./components/pages/homePage";
import PayrollPlanningApp from "./components/pages/payrollPlanningApp";
import SymphonyApp from "./components/symphony/symphonyApp";
import FormsApp from "./components/forms/formsApp";

import { mainProviders, settingsProviders, adminSettingsProviders, symphonyProviders, formsProviders } from "./utilities/ProviderFactory";
import NavbarFactory from "./utilities/NavBarFactory";
import { myCAIApplications } from "./constants/constants";
import ProviderFactory from "./utilities/ProviderFactory";
import { ReactComponent as IconNotification } from "./images/icon-notification.svg"
import { useNotification } from "./contexts/NotificationContext";
import { TransientComponents, TransientVisibilityProvider } from "./components/fusion/Contexts/VisualContext";

// Images
const imgLogo = require("./images/myCAI-white.svg").default;
// const imgLogo = "https://www.pitt.edu/themes/custom/pitt_default/logo.svg";
const imgGo = require("./images/icon-go-white.svg").default;

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */

const App = () => {
    const {
        graphData,
        imgUrl,
        impersonating,
        graphAccessToken,
        apiAccessToken,
        setImpersonating,
    } = useAuthentication();

    // const [hideImpersonateModal, setHideImpersonateModal] = useState(true);
    const [userQuery, setUserQuery] = useState("");
    const [userQueryOptions, setUserQueryOptions] = useState([]);
    const [selectedUserIndex, setSelectedUserIndex] = useState(-1);
    const [impersonationInProgress, setImpersonationInProgress] = useState(false);
    const [hideNotificationOverlay, setHideNotificationOverlay] = useState(true);

    const [showAppLauncher, setShowAppLauncher] = useState(false);

    const { hideImpersonateModal, setHideImpersonateModal } = useAppConfig();
    const { unreadCount } = useNotification();

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        let value = target.value;

        if (name === "userQuery") setUserQuery(value);
    };

    const RedirectWithParams = ({redirectTo}) => {
        // Redirect to another URL at the same level, and maintain all parameters
        const params = useParams();
        const urlParameters = params['*'];

        return <Navigate to={`/${redirectTo}/${urlParameters}`} replace />;
    }

    useEffect(() => {
        if (hideImpersonateModal) {
            setSelectedUserIndex(-1);
            setUserQueryOptions([]);
            setUserQuery("");
            setImpersonationInProgress(false);
        }
    }, [hideImpersonateModal]);

    useEffect(() => {
        if (userQuery) {
            callMsGraphUsers(graphAccessToken, userQuery).then((data) => {
                // bind to select
                //console.log(data);
                setUserQueryOptions(data.value);
                setSelectedUserIndex(0);
            });
        }
    }, [userQuery]);

    return (
        <TransientVisibilityProvider>
            <TransientComponents>
                <Router>
                    <Toolbar>
                        <ToolbarLogo imgSrc={imgLogo} />
                        <ToolbarRight>
                            <ToolbarLinks>
                                <ReportBugLink reportedBy={graphData && graphData.displayName} />
                                    <SettingContextWrapper>
                                        <SettingsLink />
                                    </SettingContextWrapper>
                                <li className="o-top-menu__link-item">
                                    <a href="#" className="o-top-menu__link" onClick={(e) => {
                                        e.preventDefault();
                                        setHideNotificationOverlay(false);
                                    }}>
                                        <IconNotification className="o-top-menu__icon" />
                                        {unreadCount > 0 && <span className="a-badge a-badge--negative o-top-menu__badge">{unreadCount}</span>}
                                    </a>
                                </li>
                            </ToolbarLinks>
                            <ToolbarAppLauncher
                                onClick={() => {
                                    setShowAppLauncher(true);
                                }}
                            />
                        </ToolbarRight>
                    </Toolbar>

                    <Routes>

                        <Route
                            exact
                            path="/"
                            element={
                                <ProviderFactory providers={mainProviders}>
                                <NavbarFactory navbarKey="default" />
                                    <HomePage
                                        onHomePageLoad={() => setShowAppLauncher(true)}
                                        onHomePageUnload={() => setShowAppLauncher(false)}
                                    />
                                </ProviderFactory>
                            }
                        />
                        
                        <Route
                            path="/bonus/*"
                            element={
                                <ProviderFactory providers={mainProviders}>
                                    <NavbarFactory navbarKey="bonus" />
                                    <BonusPlanningApp
                                        imgUrl={imgUrl}
                                        accessToken={apiAccessToken}
                                        graphAccessToken={graphAccessToken}
                                        impersonation={impersonating}
                                        upn={
                                            impersonating
                                                ? impersonating.userPrincipalName
                                                : graphData?.userPrincipalName
                                        }
                                    />
                                </ProviderFactory>
                            }
                        />
                        
                        <Route
                            path="/payroll/*"
                            element={
                                <ProviderFactory providers={mainProviders}>
                                    <NavbarFactory navbarKey="payroll" />
                                    <PayrollPlanningApp
                                        imgUrl={imgUrl}
                                        accessToken={apiAccessToken}
                                        graphAccessToken={graphAccessToken}
                                        impersonation={impersonating}
                                        upn={
                                            impersonating
                                                ? impersonating.userPrincipalName
                                                : graphData?.userPrincipalName
                                        }
                                    />
                                </ProviderFactory>
                            }
                        />

                        <Route
                            path="/symphony/*"
                            element={<RedirectWithParams redirectTo="maestro" />}
                        />
                        
                        <Route
                            path="/maestro/*"
                            element={
                                <ProviderFactory providers={symphonyProviders}>
                                    <NavbarFactory navbarKey="maestro" />
                                    <SymphonyApp />
                                </ProviderFactory>
                            }
                        />
                    
                        <Route
                            path="/settings/admin/*"
                            element={
                                <ProviderFactory providers={adminSettingsProviders}>
                                    <NavbarFactory navbarKey="adminSettings" />
                                    <AdminSettingsPage />
                                </ProviderFactory>
                            }
                        />
                    
                        <Route
                            path="/settings/*"
                            element={
                                <ProviderFactory providers={settingsProviders}>
                                    <NavbarFactory navbarKey="settings" />
                                    <SettingsPage />
                                </ProviderFactory>
                            }
                        />
                    
                        <Route
                            path="/forms/*"
                            element={
                                <ProviderFactory providers={formsProviders}>
                                    <NavbarFactory navbarKey="forms" />
                                    <FormsApp />
                                </ProviderFactory>
                            }
                        />
                    
                        <Route
                            path="*"
                            element={
                                <>
                                <NavbarFactory navbarKey="default" />
                                <HomePage />
                                </>
                            }
                        />
                    </Routes>


                    
                    <Modal
                        isHidden={hideImpersonateModal}
                        clickOutsideToClose
                        onClose={() => setHideImpersonateModal(true)}
                        title="Impersonate"
                        actions={<>
                            <Button outline children="Cancel" onClick={() => setHideImpersonateModal(true)} />
                            <Button children="Confirm" onClick={() => {
                                setImpersonationInProgress(true);
                                //console.log(selectedUserIndex);
                                let selectedUser = userQueryOptions[selectedUserIndex];
                                myCaiApiService
                                    .ConvertUPNtoEmployeeId(
                                        apiAccessToken,
                                        selectedUser.userPrincipalName
                                    )
                                    .then((data) => {
                                        setImpersonating({
                                            displayName: selectedUser.displayName,
                                            employeeId: data["employeeId"],
                                            userPrincipalName: selectedUser.userPrincipalName,
                                        });
                                        setHideImpersonateModal(true);
                                    });
                            }} />
                        </>}
                    >
                        <div className="l-row">
                            {!impersonationInProgress && (
                                <div className="l-one-col">
                                    <p>Who would you like to impersonate?</p>
                                    <Form>
                                        <FormGroup>
                                            <label htmlFor="" className="a-label a-label--required">
                                                New Active User
                                            </label>
                                            <input
                                                type="text"
                                                className="a-text-input"
                                                name="userQuery"
                                                placeholder="Begin typing a name..."
                                                value={userQuery}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                            />
                                        </FormGroup>
                                        {userQueryOptions && userQueryOptions.length > 0 && (
                                            <FormGroup>
                                                <label htmlFor="" className="a-label">
                                                    Select Here
                                                </label>
                                                <Select
                                                    name="reassignSelector"
                                                    id="reassignSelector"
                                                    onChange={(e) => {
                                                        setSelectedUserIndex(e.target.selectedIndex);
                                                    }}
                                                >
                                                    {userQueryOptions.map((e) => (
                                                        <Option value={e.id} key={e.id}>
                                                            {`${e.displayName}`}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </FormGroup>
                                        )}
                                    </Form>
                                </div>
                            )}
                            {impersonationInProgress && (
                                <div className="l-one-col">
                                    <p>Impersonation in Progress</p>
                                    <Progress>Loading New User Settings</Progress>
                                </div>
                            )}
                        </div>
                    </Modal>
                    <NotificationOverlay 
                        isHidden={hideNotificationOverlay}
                        onClose={() => setHideNotificationOverlay(true)}
                    />
                


                    <Overlay
                        show={showAppLauncher}
                        onClose={() => {
                            setShowAppLauncher(false);
                        }}
                    >
                        <OverlayTitle>{process.env.REACT_APP_BRANDING_APP_NAME ?? "My CAI"} Apps</OverlayTitle>
                        <div className="o-app-launcher__list">
                            {myCAIApplications.map((app, index) => {
                                return (
                                    <NavLink
                                        className="o-app-launcher__app"
                                        to={app.path}
                                        onClick={() => {
                                            setShowAppLauncher(false);
                                        }}
                                        key={index}
                                    >
                                        <img className="o-app-launcher__app-icon" src={app.imgSrc} />
                                        <span className="o-app-launcher__app-title">{app.appName}</span>
                                        <img className="o-app-launcher__go-icon" src={imgGo} />
                                    </NavLink>
                                );
                            })}
                        </div>
                    </Overlay>
                </Router>
            </TransientComponents>
        </TransientVisibilityProvider>
    );
};


export default App;

import { createContext, useContext, useEffect, useState, useCallback, useRef } from "react";
import { ToastContext } from "cai-fusion";
import { useNotificationApiService } from "../hooks/useNotificationApi";
import { useAuthentication } from "./AuthContext";
import * as signalR from "@microsoft/signalr";
import { signalRConfig } from "../configs/signalRConfig";

const NotificationContext = createContext();

function NotificationProvider({ children }) {
    const notificationServiceApi = useNotificationApiService();
    const { createInfoToast } = useContext(ToastContext);
    const { apiAccessToken } = useAuthentication();
    const [notifications, setNotifications] = useState([]);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hubConnect, setHubConnect] = useState({});
    const notificationsRef = useRef();


    useEffect(() => {
        loadNotifications();
        initializeSignalR();
    }, []);

    useEffect(() => {
        notificationsRef.current = notifications;
        setUnreadCount(notifications?.filter((notif) => !notif.markedReadUtc).length);
        
    }, [notifications]);

    useEffect(() => {
        connectSignalR();
        if (!hubConnect.on){
            return;
        }
        
        hubConnect?.on("ReceiveNotification", async (notifID) => {
            console.log(`[SIGNALR] Received SignalR notification mapped to ReceiveNotification, notifID: `, notifID);
            await retrieveNotification(notifID);
            notificationToast(notifID);
        });
        hubConnect?.on("ReceiveNotificationRead", (notifIDs) => {
            console.log(`[SIGNALR] Received SignalR notification mapped to ReceiveNotificationRead, notifIDs: `, notifIDs);
            console.log("[NOTIF] Notification IDs to Mark as Read: ", notifIDs, " length: ", notifIDs.length);
            console.log("[NOTIF] current notifications: ", notifications);
            setNotifications([...notificationsRef.current.map((notif) => notifIDs.includes(notif.id)
                ? {...notif, markedReadUtc : Date.now()}
                : notif )]);
        });
        hubConnect?.on("ReceiveNotificationDeletion", (notifIDs) => {
            console.log(`[SIGNALR] Received SignalR notification mapped to ReceiveNotificationDeletion, notifIDs: `, notifIDs);
            console.log("[NOTIF] Notification IDs to Delete: ", notifIDs, " length: ", notifIDs.length);
            console.log("[NOTIF] current notifications: ", notifications);
            setNotifications(notificationsRef.current.filter((notif) => !notifIDs.includes(notif.id)));
        });
    }, [hubConnect])


    const initializeSignalR = () => {
        
        setHubConnect(new signalR.HubConnectionBuilder()
            .withUrl(`${signalRConfig.baseHubUrl}notif`, {
                accessTokenFactory: () => apiAccessToken,
            })
            .withAutomaticReconnect()
            .build());

    }
    
    const connectSignalR = (transport = null) => {
        const connectionMethod = transport ?? signalRConfig.overrideConnection ?? ['webSockets', 'longPolling', 'serverSentEvents', 'foreverFrame']
        try {
            console.log(`[SIGR] Attempting to connect signalR with connection method: ${connectionMethod}`)
            hubConnect.start({ transport: connectionMethod}).then(() => {
                console.log("[SIGR] Connection successful!");
            });
        } catch (error) {
            console.log(
                "[SIGR] Error while establishing connection with the SignalRChatHub" +
                error.message +
                "Connection Method: " + connectionMethod
            );
            if (!transport) connectSignalR('longPolling');
        }
        
    }

    const notificationToast = (notifID) => {
        try {
            const notification = notificationsRef.current.find((notif) => notif.id == notifID);
            console.log("[NOTIF] Notification Object: " + notification);
            createInfoToast(
                <div>
                        <h4>{notification.title}</h4>
                        <p>{notification.body}</p>
                </div>
            );
        } catch (error) {
            console.error("[NOTIFS] An error occurred when sending a notification toast for notification ", notifID, error);
        }
        
    }

    const retrieveNotification = useCallback(async (notifID) => {
        setLoading(true);
        setError(null);
        try {
            const notif = await notificationServiceApi.getNotification(notifID);
            const newNotif = [...notificationsRef.current, ...notif];
            setNotifications(newNotif);
            notificationsRef.current = newNotif;
        } catch (error) {
            console.error("[NOTIFS] An error occurred when loading notification ", notifID, " from the backend.", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [notificationServiceApi]);

    const loadNotifications = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await notificationServiceApi.getNotifications();
            console.log("[NOTIF LOAD] response: ", response);
            setNotifications(response);
        } catch (error) {
            console.error("[NOTIFS] An error occurred when loading notifications from the backend.", error);
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [notificationServiceApi]);

    const toggleSelectNotification = useCallback((notifID) => {
        setSelectedNotifications((prevSelected) =>
            prevSelected.includes(notifID)
                ? prevSelected.filter((id) => id !== notifID)
                : [...prevSelected, notifID]
        );
    }, []);

    const markNotificationsAsRead = useCallback(async (notifs) => {
        const notificationsToBeRead = notifs ?? selectedNotifications;
        console.log("[NOTIF] toberead: ", notificationsToBeRead);
        console.log("[NOTIF] all notifications: ", notifications);
        try {
            await notificationServiceApi.markNotificationsAsRead(notificationsToBeRead);
            setNotifications((prevNotifications)            =>
                prevNotifications.map((notif) =>
                    notificationsToBeRead.includes(notif.id) ? { ...notif, markedReadUtc: Date.now() } : notif
                )
            );
            setSelectedNotifications([]);
        } catch (error) {
            console.error("[NOTIFS] Something went wrong when trying to mark notifications as read!", error);
            setError(error);
        }
    }, [selectedNotifications, notifications, setNotifications, notificationServiceApi]);

    const deleteNotifications = useCallback(async (notifs) => {
        const notificationsToBeDeleted = notifs ?? selectedNotifications;
        console.log("[NOTIF] tobedeleted: ", notificationsToBeDeleted);
        console.log("[NOTIF] all notifications: ", notifications);
        try {
            await notificationServiceApi.deleteNotifications(notificationsToBeDeleted);
            setNotifications((prevNotifications) =>
                prevNotifications.filter((notif) => !notificationsToBeDeleted.includes(notif.id))
            );
            setSelectedNotifications([]);
        } catch (error) {
            console.error("[NOTIFS] Something went wrong when trying to delete notifications!", error);
            setError(error);
        } 
    }, [selectedNotifications, notifications, setNotifications, notificationServiceApi]);

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                selectedNotifications,
                unreadCount,
                loading,
                error,
                loadNotifications,
                toggleSelectNotification,
                markNotificationsAsRead,
                deleteNotifications,
                connectSignalR,
                notificationToast
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
}

function useNotification() {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error("useNotification must be used within a NotificationProvider.");
    }
    return context;
}

export { NotificationProvider, useNotification };
import { createContext, useState, useContext, useEffect, useRef } from "react";
import { useAuthentication } from "../../../contexts/AuthContext";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useChat } from './ChatContext';
import { useAssistant } from "./AssistantContext";
import { useResource } from "./ResourceContext";
import { useSettings } from "./SettingsContext";
import { useSkills } from "./SkillsContext";
import { useSignalR } from "./SignalRContext";
import { useUserProfile } from "./UserProfileContext";
import { useAccess } from "./AccessContext";
import { useChatHistory } from "./ChatHistoryContext";

const MessagesContext = createContext();

function MessagesProvider({ children }) {
    const { apiAccessToken } = useAuthentication();

    const apiServiceClient = useSymphonyApiService("v2");

    // referenced context components
    const { chat, fetchChatId, chatId, systemReservedTokens, isChatInContext, chatIdRef, silentLoadChats } = useChat();
    const { assistant, assistantTokenCount } = useAssistant();
    const { dataSources, resourceTokenCount, enabledDataStores } = useResource();
    const { model, settings, modelEncoding } = useSettings();
    const { getEnabledSkillIds, skillTokenCount } = useSkills();
    const { canSendMessage } = useAccess();
    const { loadChats } = useChatHistory();

    const { addMappingMethod } = useSignalR();
    const { userProfile } = useUserProfile();

    // data
    const [messages, setMessages] = useState([]);
    const [requestedTokens, setRequestedTokens] = useState(0);
    const selfMessageId = "00000000-0000-0000-0000-000000000000";
    const assistantMessageId = "00000000-0000-0000-0000-000000000001";
    const [messageTokenCount, setMessageTokenCount] = useState(0);
    const [chatSuggestions, setChatSuggestions] = useState([]);
    const [auditLog, setAuditLog] = useState([]);

    // statuses
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [assistantThinking, setAssistantThinking] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);

    // refs for signalR
    const messagesRef = useRef();

    // Loading all messages for the current chat.
    const loadMessages = async (existingChatId = null) => {

        // Load from the predetermined chat ID, and default to the current one in context if not.
        let loadId = existingChatId ?? chatId;
        if (loadId && !assistantThinking) {
            try {
                setLoadingMessages(true);
                let messages = apiAccessToken ? await apiServiceClient.Chats.getChatMessages(loadId) : []

                if (!messages || messages.length === 0) {
                    setMessages([]);
                } else {
                    const sortedMessages = messages.sort((a, b) => new Date(a.MessageDate) - new Date(b.MessageDate));
                    setMessages(sortedMessages);

                    //initial function passes 0 in it and goes through another function, originally setChatRecommendationsTimer(0)
                    generateChatSuggestions();
                }
                setLoadingMessages(false);
            }
            catch (error) {
                console.error("[MESSAGE/LOAD] Error getting chat");
            }
        }
        else {
            return;
        }
    }

    // Sends message to the current chat.
    // If given, messageTextOverride is the text that will show in the chat message, instead of message
    const sendMessage = async (message, messageTextOverride = null) => {

        // set assistant thinking state
        setAssistantThinking(true);
        clearChatSuggestions();
        setThinkingConversation(messageTextOverride ?? message);
        setSendSuccess(false);

        // get chat Id of existing chat, or create one if it does not exist
        let id;
        try {
            id = await fetchChatId();
        }
        catch (error) {
            console.error("[MESSAGE/SEND] Failed to create or fetch chat:", error);
            setAssistantThinking(false);
            return;
        }

        let mappedDataSources = dataSources
            .filter((ds) => ds.included)
            .map((ds) => ds.dataSourceId);

        console.log("[MESSAGE/SEND] Data Sources:", mappedDataSources)

        try {
            const sendMessageResponse = await apiServiceClient.Messages.sendMessage(
                id,
                message,
                assistant,
                model,
                settings,
                mappedDataSources,
                getEnabledSkillIds(),
                messageTextOverride,
                enabledDataStores.map(x => x.id)
            );
    
            if (!sendMessageResponse?.isSuccess) {
                setErrorConversation();
                return;
            }
    
            const responseMessage = sendMessageResponse.result;
            updateMessagesWithResponse(sendMessageResponse.headers.messageId, message);
            receiveMessage(responseMessage);
            setSendSuccess(true);
        } catch (error) {
            setErrorConversation();
        } finally {
            await generateChatSuggestions();
            setAssistantThinking(false);
        }
    }
    // Helper function to update messages state with the correct messageId from the response
    const updateMessagesWithResponse = (messageId, message) => {
        const ind = messagesRef.current.findIndex(
            (x) => x.content.some((y) => y.text === message)
        );

        if (ind !== -1) {
            const newMessages = [...messagesRef.current];
            newMessages[ind].messageId = messageId;
            setMessages(newMessages);
        }
    };

    // Throwing a message back to the AI to regenerate it.
    const regenerateMessage = async (messageId, tuning = undefined) => {
        // Store the data of the prompt and the response, delete those messages via deleteMessage, 
        // and send an adjustment back via sendMessage.
        console.log("[MESSAGE/REGEN] Regenerating the message with ID", messageId, "to be:", tuning);
        const messageIndex = messages.findIndex(
            (msg) => msg.messageId === messageId
        );
        if (messageIndex === -1) return; 
        // Find the last 'user' message before the current one
        const lastUserMessageIndex = messages
            .slice(0, messageIndex)
            .findLastIndex((msg) => msg.role === "user");
        
        if (lastUserMessageIndex === -1) return;
        // store the content of the last user message to be resent
        let lastUserMessageContent = messages[lastUserMessageIndex].content;

        // store the content of the message being regenerated
        let regenerateMessageContent = messages[messageIndex].content;

        // Truncate messages up to and including the last 'user' message
        // but leave behind all messages that are not 'user' or 'assistant' messages
        // const newMessages = messages.slice(0, lastUserMessageIndex);
        const newMessages = truncateMessages(messages, lastUserMessageIndex);

        // Get the message pairs to be deleted including the last'user' message
        // only select the messages that are user or assistant messages
        const deletedMessages = messages.slice(lastUserMessageIndex).filter((msg) => msg.role === "user" || msg.role === "assistant");

        const deletionPromises = deletedMessages.map((msg) => deleteMessage(msg.messageId));

        // Delete the messages from the backend
        try {
            await Promise.all(deletionPromises);
            setMessages(newMessages);
    
            // Prepare the text content from the message contents
            let lastUserMessageText = extractTextContent(lastUserMessageContent);
            let regenerateMessageText = extractTextContent(regenerateMessageContent);
    
            if (tuning) {
                sendMessage(
                    `Given the following user request and assistant response, rewrite the assistant response but ${tuning}. Do not write anything but the new version of the response, do not address that you're even rewriting the response. Just create a new verison of the response.:\n###start user request\n${lastUserMessageText}\n###end user request\n\n###start assistant response\n${regenerateMessageText}\n###end assistant response`,
                    lastUserMessageText,
                );
            } else {
                sendMessage(lastUserMessageText);
            }
        } catch (error) {
            console.error("[MESSAGE/REGEN] Error deleting messages:", error);
        }
    }

    // Deletes a given message.
    const deleteMessage = async (messageId) => {
        console.log("[MESSAGE/DEL] Starting to delete messages, " + messageId);
        return await apiServiceClient.Messages.deleteMessage(messageId);
    }

    // Creates a list of messages that is cut off at a certain index.
    const truncateMessages = (messages, truncateIndex) => {
        // Keep all messages up to truncateIndex and append non-user/assistant messages
        return messages
            .slice(0, truncateIndex)
            .concat(messages.slice(truncateIndex).filter((message) => message.role !== "user" && message.role !== "assistant"));
    };

    // For editing or resending a message.
    const editMessage = async (messageId, newContent) => {
        // edit message runs on the user message, so can grab it and delete all
        const messageIndex = messages.findIndex(
            (msg) => msg.messageId === messageId
        );

        if (!chatId) {
            console.error("[MESSAGE/EDIT] Error deleting messages, chat not found");
        }
        if (messageIndex !== -1) {
            // Truncate messages up to the edited one
            // const newMessages = messages.slice(0, messageIndex);
            const newMessages = truncateMessages(messages, messageIndex);
            setMessages(newMessages);
            // Get the message pairs to be deleted
            // only select the messages that are user or assistant messages
            let deletedMessages = messages
                .slice(messageIndex)
                .filter((msg) => msg.role === "user" || msg.role === "assistant");
            // Delete the message pairs from the backend
            deletedMessages.forEach(async (message) => {
                await apiServiceClient.Messages.deleteMessage(message.messageId);
            })

            // Don't need to add the edited message because send message will do that
            // newMessages.push({...messages[messageIndex], content: newContent});
            // Re-send the edited message
            sendMessage(newContent);
        }
    }

    // Retrieves the audit log for a given message.
    const getAuditLog = async (messageId) => {
        console.log("[MESSAGE/AUDIT] Grabbing audit trail...");
        let trail = await apiServiceClient.Messages.getMessageAuditTrail(messageId);
        setAuditLog(trail);
        console.log("[MESSAGE/AUDIT] Returned from call.");
        console.log("[MESSAGE/AUDIT]", trail);
    }

    // When a message is recieved, this function is called.
    const receiveMessage = (message) => {
        console.log("[MESSAGE] Message recieved:", message)
        if (message.chatId !== chatIdRef.current) return;
        // if the message was sent from current client, return
        if (messagesRef.current.filter(
            (x) =>
                JSON.stringify(x.content) === JSON.stringify(message.content) &&
                (x.messageId === selfMessageId || message.messageId === x.messageId)
        ).length > 0
        ) {
            console.log("[MESSAGE/RECEIVE] message sent by this client");
            const ind = messagesRef.current.findIndex(
                (x) =>
                    JSON.stringify(x.content) === JSON.stringify(message.content) &&
                    (x.messageId === selfMessageId || message.messageId === x.messageId)
            )
            messagesRef.current[ind] = message
            setMessages([...messagesRef.current])
            return;
        }
        else {
            console.log("[MESSAGE/RECEIVE] Message not sent by this client");
            let newMessages = [...messagesRef.current, message];
            if (message.role === "user") {
                if (message.messageId === selfMessageId) {
                    //Message was sent by this user
                    newMessages.push({
                        messageId: assistantMessageId,
                        role: "assistant",
                        content: [{ type: "text", contentOrder: 0, text: "" }],
                        isLoading: true,
                    });
                }
                else {
                    //Message was sent by another user in shared chat
                    newMessages.push({
                        messageId: assistantMessageId,
                        role: "assistant",
                        content: [{ type: "text", contentOrder: 0, text: "" }],
                        isLoading: true,
                    });
                }
            }
            else if (message.role === "assistant") {
                //Message sent by assistant
                console.log("[MESSAGE/RECEIVE] Message sent by assistant");
            }

            // organize messages by most recent
            setMessages(newMessages.filter((x) => x !== undefined && x?.isLoading !== true));
            console.log("[MESSAGE/RECEIVE] Setting messages...");
        }
    }

    const receiveMessageStatus = (statusMessage) => {
        console.log("[MESSAGE/STATUS] " + statusMessage.content);

        if (statusMessage.chatId !== chatIdRef.current) return;
        const newMessages = [...messagesRef.current];
        var loadingMessageInd;
        //If the provided statusMessage does not define what it is responding to
        if (
            statusMessage.respondingTo === undefined ||
            statusMessage.respondingTo === null
        ) {
            //Set loadingMessageInd to the last index of a loading message
            loadingMessageInd = newMessages.lastIndexOf((x) => x.isLoading);
        } else {
            var respondingToIndex = newMessages.findLastIndex(
                (x) =>
                    JSON.stringify(x.content) ===
                    JSON.stringify(statusMessage.respondingTo.content)
            );
            respondingToIndex = respondingToIndex === -1 ? 0 : respondingToIndex;
            loadingMessageInd =
                respondingToIndex +
                newMessages.slice(respondingToIndex).findIndex((x) => {
                    return x.isLoading === true;
                });
        }

        if (loadingMessageInd === -1) return;

        newMessages[loadingMessageInd].statusMessage = statusMessage.content;

        setMessages(newMessages);
    }

    // Generates reccomendations for follow-up questions based on messages in this chat.
    const generateChatSuggestions = async () => {
        // Call to generate suggestions based on the current chat ID.
        console.log("[MESSAGE/SUG] Generating chat reccomendations...");
        let recommendations = undefined
        let id  = await fetchChatId();
        if (id) {
            recommendations = await apiServiceClient.Chats.getChatRecommendations(id);
        }
        else {
            console.error("[MESSAGE/SUG] Unable to generate chat suggestions")
        }
        // If suggestions return, update them.
        if (recommendations) setChatSuggestions(recommendations);
    }

    // Removes current chat suggestions.
    const clearChatSuggestions = () => {
        setChatSuggestions([]);
    }

    // Helper function that sets a timer for when recommendations should be generated.
    const setChatRecommendationsTimer = (timeout) => {
        if (canSendMessage) {
            setTimeout(async () => {
                if (chatId) {
                    await generateChatSuggestions();
                }
            }, timeout);
        }
    }

    // Helper function to extract only the text from a message.
    const extractTextContent = (messageContent) => {
        return messageContent
            .filter((contentItem) => contentItem.type === "text")
            .sort((a, b) => a.contentOrder - b.contentOrder)
            .map((contentItem) => contentItem.text)
            .join("\n");
    };

    // Helper function to set conversation to loading response
    const setThinkingConversation = async (txt = "") => {
        var cnv = [
            {
                messageId: selfMessageId,
                role: "user",
                content: [{ type: "text", contentOrder: 0, text: txt }],
                isLoading: false,
                userProfileId: userProfile.userProfileId,
            },
            {
                messageId: assistantMessageId,
                role: "assistant",
                content: [],
                isLoading: true,
            },
        ];
        setMessages((current) => [...current, ...cnv]);
    }

    // Helper function to set conversation to placeholder error response
    const setErrorConversation = async () => {
        var cnv = [
            ...messages,
            {
                messageId: selfMessageId,
                role: "user",
                content: [
                    { type: "text", contentOrder: 0, text: "" },
                ],
                isLoading: false,
                userProfileId: userProfile.userProfileId,
            },
            {
                messageId: assistantMessageId,
                role: "error",
                content: [
                    {
                        type: "text",
                        contentOrder: 0,
                        text: "Error sending message, please copy your message text, refresh the page, and try again.",
                    },
                ],
                isLoading: false,
            },
        ];
        setMessages(cnv);
    }

    // Helper function to add an assistant message if one is not present.
    const addTemporaryAssistantMessage = (message) => {
        // only if cnv does not have a message with messageId of 00000000-0000-0000-0000-000000000001
        if (messages.find((msg) => msg.messageId === assistantMessageId)) {
            return;
        }
        else {
            var cnv = [
                ...messages,
                {
                    messageId: assistantMessageId,
                    role: "assistant",
                    content: [
                        { type: "text", contentOrder: 0, text: message },
                    ],
                },
            ];
            setMessages(cnv);
        }
    }

    // messageTokenCount calculation
    useEffect(() => {
        let total = 0;
        const messageEncodings = messages.map(
            (message) =>
                message.messageContentEncodings?.[modelEncoding] ?? 0
        );
        if (messageEncodings) {
            messageEncodings.forEach((x) => {
                total += x;
            });
        }
        setMessageTokenCount(total);
    }, [messages])

    // Update messages based on chat object
    useEffect(() => {
        if (!(isChatInContext() && (chat?.messages?.length ?? 0) === 0)) {
            setMessages([...chat?.messages ?? []]);
            clearChatSuggestions();
            setChatRecommendationsTimer(10000);
        }
    }, [chat]);

    // upon first assistant message in a new chat, silent load chat history
    useEffect(() => {
        if(sendSuccess && messages.filter(x => x.role === "user").length <= 1){
            console.log("[MESSAGE] silent load chats")
            loadChats(true);
        }
    }, [sendSuccess]);

    // update ref for signalR
    useEffect(() => {
        messagesRef.current = messages;
    }, [messages]);

    // global token calculation
    useEffect(() => {
        setRequestedTokens(
            systemReservedTokens +
            (messageTokenCount ?? 0) +
            (assistantTokenCount ?? 0) +
            (resourceTokenCount ?? 0) +
            // (modelTokenCount ?? 0) +
            (skillTokenCount ?? 0)
        );
    }, [messageTokenCount, assistantTokenCount, resourceTokenCount, skillTokenCount])

    // mapping methods to signalR
    useEffect(() => {
        addMappingMethod(
            {
                //This method will be called when a new message is received from the user. This message with have an empty GUID since it is not yet finalized in the database.
                TextName: "ReceiveMessage",
                Method: receiveMessage,
            }
        );
        addMappingMethod(
            {
                //This method will be called when a new message is received from the user. This message with have an empty GUID since it is not yet finalized in the database.
                TextName: "ReceiveMessageStatus",
                Method: receiveMessageStatus,
            }
        );
    }, [])

    return (
        <MessagesContext.Provider
            value={{
                // Data
                messages,
                messageTokenCount,
                requestedTokens,
                loadingMessages,
                assistantThinking,
                chatSuggestions,
                auditLog,
                sendSuccess,

                // Functions
                getAuditLog,
                setThinkingConversation,
                addTemporaryAssistantMessage,
                setMessages,
                setLoadingMessages,
                setAssistantThinking,
                setErrorConversation,
                loadMessages,
                sendMessage,
                regenerateMessage,
                deleteMessage,
                truncateMessages,
                editMessage,
                setChatRecommendationsTimer,
                clearChatSuggestions,
                generateChatSuggestions
            }}
        >
            {children}
        </MessagesContext.Provider>
    );
}

// Hook to use the MessagesContext in a component
function useMessages() {
    const context = useContext(MessagesContext);
    if (context === undefined) {
        throw new Error(
            "useMessages must be used within a MessagesProvider"
        );
    }
    return context;
}

export { MessagesProvider, useMessages };

import { IAdminApiConfig } from "../../configs/IAdminApiConfig";
import { MyCAIApiService } from "../MyCAIApiService";

export class AdminApiService extends MyCAIApiService {
    public Styles: StylesService;

	constructor(config: IAdminApiConfig) {
		super(config);

        this.Styles = new StylesService(this);		
	}
}

class StylesService {
    private apiService: AdminApiService;

    constructor(apiService: AdminApiService) {
		this.apiService = apiService;
	}

    // Gets the entire list of colors.
    public async getColors(): Promise<any> {
        const endpoint = `/Style/${
			this.apiService.getConfig().apiVersion
		}/Style/colors`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
    }

    // Gets an individual color with the given ID.
    public async getColor(identifier: string): Promise<any> {
        const endpoint = `/Admin/${
			this.apiService.getConfig().apiVersion
		}/Style/colors/${identifier}`;
		return this.apiService.sendRequest({
			method: "get",
			endpoint,
		});
    }
    
    // Sends and updates all sent colors to the stored list.
    public async setColors(obj: any): Promise<any> {
        const endpoint = `/Admin/${
			this.apiService.getConfig().apiVersion
		}/Style/colors`;
		return this.apiService.sendRequest({
			method: "put",
			endpoint,
            data: obj
		});
    }

    // Adds all sent colors to the stored list.
    public async addColors(obj: any): Promise<any> {
        const endpoint = `/Admin/${
			this.apiService.getConfig().apiVersion
		}/Style/colors`;
		return this.apiService.sendRequest({
			method: "post",
			endpoint,
            data: obj
		});
    }
}

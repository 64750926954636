import React, { useEffect, useState, useRef} from "react";

import { ReactComponent as IconAttach } from "../../../images/icon-attach.svg";
import { ReactComponent as IconJobDescription } from "../../../images/icon-job-description.svg";
import { ReactComponent as IconSummary } from "../../../images/icon-summary.svg";
import { ReactComponent as IconOutline } from "../../../images/icon-outline.svg";
import { ReactComponent as IconProofreading } from "../../../images/icon-large-proofreading.svg";
import { ChipGroup, Headingimport, Button, ButtonRow, ChatBubble, Heading, TabRow } from "cai-fusion";
import { useTemplate } from "../../symphony/contexts/TemplateContext";
import { usedTemplates } from "../../../constants/constants";
import { useNewTemplate } from "../../symphony/contexts/NewTemplateContext";
import SymphonyFileHandler from "../../symphony/components/symphonyFileHandler";
import SymphonyChatInput from "../../symphony/components/symphonyChatInput";

//Legacy static templates
import AnalyzeRFP from "../../symphony/templates/analyzeRFP";

const searchIcon = require("../../../images/search-icon-light.png");
const avatarAI = require("../../../images/avatar-ai.png");



// Search bar for templates that filters from description and title, also by tag
const TemplateSearch = ({onSearchTemplates}) => {
    const [searchText, setSearchText] = useState("");
    const searchTimeoutRef = useRef();

    const handleInputChange = (e) => {
        setSearchText(e.target.value);

        // Set a new timeout
        searchTimeoutRef.current = setTimeout(() => {
            handleSend(e.target.value);
        }, 200);
    };

    const handleKeyPress = (event) => {
        console.log("[CHATTEMPLATE] KEY PRESSED");
        if (event.key === "Enter") {
            if (!event.shiftKey) {
                event.preventDefault();
                handleIconPress(event);
            }
        }
    };

    const handleIconPress = (event) => {
        event.preventDefault();
        handleSend(searchText);
    }

    const handleSend = (query) => {
        onSearchTemplates(query);
    };

    useEffect(() => {
        console.log("ChatTemplate.jsx: searchText changed: " + searchText);
    }, [searchText]);

    return (
        <div>
            <form className="a-search-input l-mb-xs"> 
                <textarea 
                    className="a-search-input__text-box" 
                    type="text" 
                    placeholder="Search for a chat template..."
                    aria-label="Search"
                    id="search_field"
                    rows="1"
                    name="search_field"
                    value={searchText}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                ></textarea>
                <button className="a-search-input__button" type="submit" onClick={handleIconPress}>
                <img 
                    className="a-search-input__img"
                    src={searchIcon}
                    alt="search button icon">
                </img>
                </button>
            </form>
        </div>
    );
};

const ChatTemplateItem = ({
    IconElement,
    templateId,
    templateName,
    templateDescription,
    templateCallToAction,
    onClick
}) => {
    const { setTemplateId } = useTemplate();

    const handleClick = (e) => {
        e.preventDefault();
        setTemplateId(templateId);
        console.log(`Template ${templateId} clicked`);
        onClick();
      };

    const isActivated = () => {
        //console.log("TEMPLATES: ", usedTemplates)
        return usedTemplates.includes(templateId)
    }

    if (isActivated()) {
        return (
            <button className="m-tile o-tile-grid__tile" onClick={handleClick} key={templateId ?? templateName}>
                <div className="m-tile__icon-wrapper">
                    {IconElement && <IconElement className="m-tile__icon" />}
                </div>
                <Heading size={5} className="m-tile__title">
                    {templateName}
                </Heading>
                <p className="m-tile__description">{templateDescription}</p>
                <span className="m-tile__cta">{templateCallToAction}</span>
            </button>
        );
    }
    else {
        return (<></>);
    }

};

const ChatTemplates = () => {
    const { allChatTemplates, chatTemplate, setChatTemplate, chatTemplates, searchTemplates, showRFPItem, executeTemplate, fetchTemplate } = useNewTemplate();

    const [tags, setTags] = useState(["All Chat Templates", "Favorites"]);
    const [selectedTag, setSelectedTag] = useState("All Chat Templates");
    const [filteredTemplates, setFilteredTemplates] = useState(chatTemplates);
    const [isRFPSelected, setIsRFPSelected] = useState(false);

    useEffect(() => {
        setChatTemplate(undefined);
    }, [])

    useEffect(() => {
        console.log("[CHATTEMPLATE] Selected template changed to: ", chatTemplate);
    }, [chatTemplate])

    function getSortedTagsByFrequency(data) {
        const tagCount = {};

        // Count occurrences of each tag
        data.forEach(item => {
            item.tags.forEach(tag => {
                const tagName = tag.tagName;
                if (tagCount[tagName]) {
                    tagCount[tagName]++;
                } else {
                    tagCount[tagName] = 1;
                }
            });
        });

        // Convert to an array of [tagName, count] pairs and sort by count
        const sortedTags = Object.entries(tagCount).sort((a, b) => b[1] - a[1]);

        // Extract the tag names
        return sortedTags.map(([tagName]) => tagName);
    }

    useEffect(() => {
        setTags([...["All Chat Templates", "Favorites"] , ...getSortedTagsByFrequency(allChatTemplates)])
        //try{
        //    console.log("[TAGS] allChatTemplates: ", allChatTemplates);
        //    console.log("[TAGS] allChatTemplates.flatMap: ", allChatTemplates.flatMap(x => x.tags));
        //    const tagNames = allChatTemplates.flatMap(x => x.tags.map(tag => tag.tagName));
        //    // Count the frequency of each tag
        //    const tagCount = tagNames.reduce((acc, tagName) => {
        //        acc[tagName] = (acc[tagName] ?? 0) + 1
        //    }, {});
    
        //    // Sort tags by frequency and select the top 6
        //    const mostCommonTags = Object.entries(tagCount)
        //        .sort(([, countA], [, countB]) => countB - countA)
        //        .slice(0, 6)
        //        .map(([tagName]) => tagName);
    
        //    // Update the tags state with predefined and most common tags
        //    setTags(["All Chat Templates", "Favorites", ...mostCommonTags]);
        //} catch(error) {console.log("[CHATTEMPLATE] error when loading tags",error)}
       
    }, [allChatTemplates]);
    
    useEffect(() => {
        if (selectedTag === "All Chat Templates") {
            setFilteredTemplates(chatTemplates);
        } else if (selectedTag === "Favorites"){
            setFilteredTemplates(chatTemplates.filter(template => template.isFavorited));
        } else {
            setFilteredTemplates(chatTemplates.filter(template => template.tags && template.tags.find((tag) => tag.tagName === selectedTag)));
        }
    }, [selectedTag, chatTemplates]);

    const handleTagSelect = (tagName) => {
        setSelectedTag(tagName);
    }

    const handleTemplateSelect = async (template) => {
        const respTemplate = await fetchTemplate(template.id);
        console.log("[CHATTEMPLATE] setting selected template", respTemplate);
        setChatTemplate(respTemplate);
        setIsRFPSelected(false);
    };

    const handleRFPSelect = () => {
        setChatTemplate({});
        setIsRFPSelected(true);
    }

    const updatePromptField = (index, value) => {
        let editedChatTemplate = {...chatTemplate}
        editedChatTemplate.messageComponents[index].value = value;
        setChatTemplate({...editedChatTemplate});
    }

    const updatePromptFiles = (files) => {
        setChatTemplate({...chatTemplate, userAddedFiles: files});
    }

    return (
        <>
            {!chatTemplate && (
            <>
            <div className="row">
                <div className="col-md-6">
                    <Heading size={2}>Choose a template or type a message</Heading>
                </div>
                <div className="col-md-6">
                    <TemplateSearch onSearchTemplates={(message) => searchTemplates(message)}/>
                </div>
            </div>
            
            <ChipGroup isSelectable selected={selectedTag} chipTexts={tags} onClose={handleTagSelect}/>
            
            <div className="o-tile-grid l-mt-sm l-pb-xl">
                {filteredTemplates.map((template, index=0) => (
                    <ChatTemplateItem
                        key={index}
                        IconElement={IconOutline}
                        templateId={index+1}
                        templateName={template.title}
                        templateDescription={template.description}
                        templateCallToAction={"Get Started"}
                        onClick={() => {handleTemplateSelect(template)}}
                    />
                ))}
                {(selectedTag === "All Chat Templates") && (window.location.href.indexOf("cai") > -1 || window.location.href.indexOf("localhost") > - 1) && showRFPItem && (<>
                    <ChatTemplateItem
                        key="-1"
                        IconElement={IconOutline}
                        templateId={filteredTemplates.length + 1}
                        templateName={"Perform a Standard RFP Review"}
                        templateDescription={"Analyze an RFP and get a first review on the document's details."}
                        templateCallToAction={"Get Started"}
                        onClick={() => {handleRFPSelect()}}
                    />
                </>)}
                {filteredTemplates.length === 0 && !showRFPItem &&
                    <p className="a-muted-text text-center l-mt-sm">Sorry! There are no matching templates for this criteria.</p>
                }
            </div>
            </> )}

            {chatTemplate && !isRFPSelected && (
                <div className="col-md-12 l-mt-sm l-pb-xl">
                    <Heading size={3}>{chatTemplate.title} </Heading>
                    <p></p>
                    <p className="a-muted-text">{chatTemplate.description}</p>
                    {chatTemplate.initialMessageText &&
                        <ChatBubble 
                            image={<img src={avatarAI} className="a-avatar__img a-avatar__img--square" />}
                            isAssistant
                            isReply
                            name="Symphony Assistant"
                        >
                            <p>{chatTemplate.initialMessageText}</p>
                        </ChatBubble>
                    }
                    {(chatTemplate?.messageComponents?.length > 0 || chatTemplate.hasFileUpload) &&
                        <form className="o-nl-form l-mt-sm">
                            <p className="o-nl-form__text">
                                {chatTemplate?.messageComponents?.map((part, index) => {
                                    if (part.type === "input") {
                                        return (
                                            <input
                                                key={`template-preview-${index}`}
                                                placeholder={part.text}
                                                type="text"
                                                className="o-nl-form__text-input"
                                                value={part.value}
                                                onChange={(e) => updatePromptField(index, e.target.value)}
                                            />
                                        );
                                    } else if (part.type === "textarea") {
                                        return (
                                            <textarea
                                                key={`template-preview-${index}`}
                                                placeholder={part.text}
                                                className="a-textarea-input"
                                                rows="3"
                                                value={part.value}
                                                onChange={(e) => updatePromptField(index, e.target.value)}
                                            />
                                        );
                                    } else if (part.type === "select") {
                                        return (
                                            <select 
                                                className="o-nl-form__select" 
                                                onChange={(e) => updatePromptField(index, e.target.value)}
                                                key={`template-preview-${index}`}
                                            >
                                                {!part.defaultValue && <option value="">Select...</option>}
                                                {part.options?.map((option) => (
                                                    <option 
                                                        value={option} 
                                                        defaultValue={option === part.defaultValue}
                                                        key={`template-preview-${index}-${option}`}
                                                    >
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        );
                                    } else {
                                        return <span key={`template-preview-${index}`}>{part.text}</span>
                                    }
                                })}
                            </p>
                            {chatTemplate.hasFileUpload && 
                                <SymphonyFileHandler 
                                    files={chatTemplate.userAddedFiles}
                                    setFiles={updatePromptFiles}
                                    uploadDisplayText={chatTemplate.fileUploadMessage}
                                />
                            }
                            <ButtonRow>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        executeTemplate();
                                    }}
                                    children={"Send Message"}
                                />
                            </ButtonRow>
                        </form>
                    }
                    
                </div>
            )}
            {isRFPSelected && <AnalyzeRFP />}
        </>
    );
};

export default ChatTemplates;

import { Modal } from "cai-fusion";

const UnlicensedModal = ({ isHidden = false }) => {

	return (
		<Modal
			id="eula"
			title="No License Found"
			onClose={(e) => {
				e.preventDefault();
			}}
			isHidden={isHidden}
		>
			<div className="l-row">
				<div className="l-one-col">
					Oops, it looks like you don't have a license assigned. Please reach out to your Maestro admin for help resolving this.
				</div>
			</div>
		</Modal>
	)
};

export default UnlicensedModal;
import { MyCAIApiService } from "../MyCAIApiService";
import { IMyCAIApiConfig } from "../../configs/IMyCAIApiConfig";

export class NotificationServiceApi extends MyCAIApiService {
    constructor(config: IMyCAIApiConfig) {
        super(config);
    }

    public async getNotifications(): Promise<any> {
        const endpoint = `/communications/notifications`;
        return this.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async getNotification(notificationId: string): Promise<any> {
        const endpoint = `/communications/notifications?$filter=Id eq ${notificationId}`;
        return this.sendRequest({
            method: "get",
            endpoint,
        });
    }

    public async markNotificationsAsRead(notificationIds: string[]): Promise<any> {
        const endpoint = `/communications/notifications/mark-as-read`;
        return this.sendRequest({
            method: "put",
            endpoint,
            data: notificationIds,
        });
    }

    public async deleteNotifications(notificationIds: string[]): Promise<any> {
        const endpoint = `/communications/notifications`;
        return this.sendRequest({
            method: "delete",
            endpoint,
            data: notificationIds,
        });
    }
}

import { Snackbar, Button } from 'cai-fusion';
import { useTransientVisibility } from '../../fusion/Contexts/VisualContext';

const PlagiarismSnackbar = () => {
    const { show, hide, visibilityStates } = useTransientVisibility();

    return (
        <Snackbar
            isHidden={!visibilityStates.PlagiarismSnackbar}
            onClose={() => hide('PlagiarismSnackbar') }
            type="warning"
            primaryMessage="Plagiarism Warning"
            secondaryMessage="You should be aware about the risks of plagiarism from copying AI-generated responses."
         >
            <Button
                children="Learn More"
                onClick={() => {
                    show('PlagiarismDisclaimerModal')
                    hide('PlagiarismSnackbar')
                }}
            />
         </Snackbar>
    )
    
};

export default PlagiarismSnackbar;
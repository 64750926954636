import { useEffect, useState } from "react";
import { useNewTemplate } from "../../contexts/NewTemplateContext";
import { Button, Modal } from "cai-fusion";

const RenameTemplateModal = ({ isHidden, onClose }) => {

	const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const { chatTemplates, chatTemplateId, renameChatTemplate } = useNewTemplate();

	useEffect(() => {
		if(!isHidden && chatTemplateId){
			let chatTemplate = chatTemplates.find((template) => template.id === chatTemplateId);
			setName(chatTemplate.name);
			setDescription(chatTemplate.description);
		}
	}, [isHidden])

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			if (!event.shiftKey) {
				event.preventDefault();
				handleSubmit();
				setName("");
			}
		}
	};

	const handleSubmit = async () => {
		onClose();

        let chatTemplate = chatTemplates.find((template) => template.id === chatTemplateId);
        if (chatTemplate.name !== name) {
            await renameChatTemplate(chatTemplateId, name);
        }
        // if (chatTemplate.description !== description) {
        // 	await changeTemplateDescription(chatTemplateId, description);
        // }

		setName(""); 
		setDescription("");
	}

	return (
		<Modal
			isHidden={ isHidden }
			id="rename-modal"
			clickOutsideToClose={ true }
			title={"Rename Chat Template"}
			onClose={(e) => {
				onClose();
				e.preventDefault();
			}}
			actions={
				<>
					<Button outline onClick={(e) => { e.preventDefault(); setName(""); setDescription(""); onClose(); }} children="Cancel"/>
					<Button onClick={(e) => { e.preventDefault(); handleSubmit(); }} children="Rename"/>
				</>
			}
		>
			<div className="l-row">
				<div className="l-one-col">
					<p>{`Please rename your chat template. You can optionally change your description, and both of these fields can be changed again later.`}</p>
					<form action="" className="o-form">
						<div className="m-form-group l-mt-sm">
							<label htmlFor="" className="a-label">Chat Template Name</label>
							<input 
                                type="text" 
                                value={name} 
                                onKeyDown={(e) => handleKeyPress(e)} 
                                onChange={(e) => { 
                                    setName(e.target.value); 
                                    e.preventDefault();
                                }} 
                                className="a-text-input"
                            />
						</div>
                        {/* <div className="m-form-group l-mt-sm">
							<label htmlFor="" className="a-label">Description</label>
							<input 
                                type="text" 
                                value={description} 
                                onChange={(e) => {
                                    setDescription(e.target.value); 
                                    e.preventDefault(); 
                                }} 
                                className="a-text-input"
                            />
						</div> */}
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default RenameTemplateModal;
import { useRef, useState } from "react";
import { ReactComponent as IconCloseSquare } from "../../../images/close-square.svg";
import { useUserProfile } from "../contexts/UserProfileContext";
import { Drawer, HeadingRow, Heading } from "cai-fusion";
import { useDataStore } from "../contexts/DataStoreContext";

const ShareDataStoreDrawer = ({ isHidden, clickOutsideToClose, onClose }) => {
    const { compileNameHelper } = useUserProfile();
    const searchTimeoutRef = useRef();
    const [users, setUsers] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    //sharedUsers = chatMembers same concept
    const { membershipLevels, searchUsers, isMember, canShare, sharedUsers, removeUserDataStoreAccess, updateDataStoreAccess, shareDataStore } = useDataStore(); //Add a search for users

    const handleInputChange = (e) => {
        const currentValue = e.target.value;
        setSearchValue(e.target.value);
        if (e.target.value === "" || e.target.value === undefined) {
            setUsers([]);
        } else {
            if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current);
            }

            // Set a new timeout
            searchTimeoutRef.current = setTimeout(() => {
                // Use currentValue directly
                searchUsers(currentValue).then((resp) => setUsers(resp));
            }, 200);
        }
    }

    return (
        <Drawer
            clickOutsideToClose={clickOutsideToClose}
            isHidden={isHidden}
            id="sharing-drawer"
            onClose={onClose}
            header={
                <HeadingRow
                    title="Chat Sharing"
                    eyebrow="Settings"
                    size={3}
                >
                    <div className="m-icon-row m-icon-row--right-align">
                        <a
                            id="drawerCloseBtn"
                            className="a-icon m-icon-row__icon"
                            title="Close Associate Details"
                            onClick={onClose}
                        >
                            <IconCloseSquare className="a-icon-link__icon">
                                {" "}
                            </IconCloseSquare>
                        </a>
                    </div>
                </HeadingRow>
            }
        >
            <form className="o-form l-mt-sm">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row l-mb-xs">
                            <div className="col-12">
                                <Heading size={3}>People Included in this Chat</Heading>
                                <p className="a-muted-text">The following people have been invited to this conversation. They will be able to read all messages in the chat. They'll have access to any information included in this chat, including data sources and files.</p>
                            </div>
                            <div className="col-12">
                                <div className="o-block">
                                    <table id="sharedUsers" className="o-treegrid o-treegrid--compact l-mr-sm">
                                        <colgroup className="o-treegrid__colgroup">
                                            <col className="o-treegrid__col o-treegrid__col--small" span="1" />
                                            <col className="o-treegrid__col o-treegrid__col--small" span="1" />
                                            <col className="o-treegrid__col o-treegrid__col--small" span="1" />
                                        </colgroup>
                                        <tbody>
                                            {/* Existing Chat members */}
                                            {
                                                sharedUsers?.map((member) => {
                                                    return (
                                                        <tr className="o-treegrid__row o-treegrid__row--shown" key={member?.userProfileId}>
                                                            <td className="o-treegrid__cell">
                                                                <span className="a-subtle-text">
                                                                    <strong>{member?.userProfile?.givenName ?? member?.userProfile?.identifier} {(member?.userProfile?.familyName ?? "")}</strong>
                                                                </span>
                                                            </td>
                                                            <td className="o-treegrid__cell o-treegrid__cell--right-aligned">
                                                                <label className="visually-hidden" htmlFor="permissions-1">Permissions</label>
                                                                <select id="permissions-1" className="a-select-input" onChange={(e) => { e.preventDefault(); console.log("[MEMBER] value = " + Number.parseInt(e.target.value)); isMember(member.userProfile) ? updateDataStoreAccess(member.userProfile, Number.parseInt(e.target.value)) : shareDataStore(member.userProfile, Number.parseInt(e.target.value)); }} value={member.membership} disabled={ !canShare || member.membership === 0 }>
                                                                    {
                                                                        Object.keys(membershipLevels).map((level) => 
                                                                            <option value={level} key={level}>{membershipLevels[level]}</option>
                                                                        )
                                                                    }
                                                                    {member.membership===0 ? <option value="0">Owner</option> : <></>}
                                                                </select>
                                                            </td>
                                                            {canShare && member.membership !== 0 ? <td className="o-treegrid__cell"><button className="a-button a-button--outline a-button--small  a-button--full-width" onClick={(e) => { e.preventDefault(); removeUserDataStoreAccess(member); }}>Remove</button></td> : <><td className="o-treegrid__cell"></td></>}
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {/* Add Chat members box */}
                                            {   canShare &&
                                                <tr className="o-treegrid__row o-treegrid__row--no-border o-treegrid__row--shown">
                                                    <td className="o-treegrid__cell o-treegrid__cell" colSpan="3">
                                                        <div className="m-autocomplete-input">
                                                            <label className="visually-hidden" htmlFor="autocompleteInput"></label>
                                                            <input id="autocompleteInput" type="text" value={searchValue} placeholder="Add another person..." className="a-text-input l-mt-xs" onChange={(e) => handleInputChange(e)} />
                                                            <div className="m-autocomplete-input__dropdown m-autocomplete-input__dropdown--shown" id="autocompleteDropdown">
                                                                {
                                                                    searchValue !== "" ? users?.map(user => {
                                                                        return (
                                                                            <button className="m-autocomplete-input__dropdown-item" type="button" key={compileNameHelper(user)} onClick={(e) => { e.preventDefault(); setSearchValue(""); setUsers([]); shareDataStore(user); }}>
                                                                                <div className="m-autocomplete-input__dropdown-item-title">{user?.givenName ?? user?.identifier} {(user?.familyName ?? "")}</div>
                                                                                <div className="m-autocomplete-input__dropdown-item-meta">{user.jobTitle ?? user?.identifier}</div>
                                                                            </button>
                                                                        )
                                                                    }) : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Drawer>
    )
}; export default ShareDataStoreDrawer;
import React, { useState, Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink,
  Routes,
} from "react-router-dom";
import myCaiApiService from "../../services/mycai-api-v1";
import { Progress } from "../fusion";
import { callMsGraphUsers } from "../../services/graph";
import PayrollChangeGroup from "../payroll/payrollChangeGroupScreen";
import PayrollMeritChange from "../payroll/payrollMeritChanges";
import { useAuthentication } from "../../contexts/AuthContext";
import { updateExpressionStatement } from "typescript";

const annualizedHours = 1880;

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const PayrollPlanningApp = (props) => {
  const { impersonating, graphData, apiAccessToken, graphAccessToken } =
    useAuthentication();

  const [ownedChangeGroups, setOwnedChangeGroups] = useState([]);
  const [assignedChangeGroups, setAssignedChangeGroups] = useState([]);
  const [seeAllChangeGroups, setSeeAllChangeGroups] = useState([]);
  const [loadingOrgs, setLoadingOrgs] = useState(true);
  const [changeGroups, setChangeGroups] = useState([]);
  const [seeAll, setSeeAll] = useState(false);
  const [seeAllList, setSeeAllList] = useState(["tammy.harper@cai.io", "jon.taglieri@cai.io", "tom.salvaggio@cai.io"]);
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const upn = impersonating
    ? impersonating.userPrincipalName
    : graphData?.userPrincipalName;


  useEffect(() => {
    if (impersonating) {
      console.log(`Impersonating: ${impersonating.displayName}`);
    } else {
      console.log("Impersonating: None");
    }
  }, [impersonating]);

  //Initial load to get changeGroup and merit change data. (The merit change data is nested inside the change group jsob obj's)
  useEffect(() => {
    if (!loadingOrgs) setLoadingOrgs(true);

    if (apiAccessToken) {
      myCaiApiService
        .GetChangeGroups(apiAccessToken, impersonating?.employeeId)
        .then((data) => {
          setOwnedChangeGroups(data[0]);
          setChangeGroups(data[0]);
          setAssignedChangeGroups(data[1]);
          setSeeAllChangeGroups(data[2]);
          console.log("bippity");
          console.log(data);
          setLoadingOrgs(false);
        })
        .catch((error) => console.error(error));

      if (impersonating) {
        setSeeAll(seeAllList.includes(impersonating?.userPrincipalName.toLowerCase()));
      } else {
        setSeeAll(seeAllList.includes(graphData?.userPrincipalName.toLowerCase()));
      }
      console.log("See All: " + seeAll);

      myCaiApiService
        .GetWasSubmitted(apiAccessToken, impersonating?.employeeId)
        .then((data) => {
          setWasSubmitted(data);
        })

    }
  }, [apiAccessToken, impersonating]);

  const repullData = () => {
    if (apiAccessToken) {
      setLoadingOrgs(true);
      myCaiApiService
        .GetChangeGroups(apiAccessToken, impersonating?.employeeId)
        .then((data) => {
          setOwnedChangeGroups(data[0]);
          setAssignedChangeGroups(data[1]);

          if (changeGroups && changeGroups.length > 0) {
            if (changeGroups[0].discriminator === "owned") {
              setChangeGroups(data[0]);
            } else if (changeGroups[0].discriminator === "assigned") {
              setChangeGroups(data[1]);
            }
            // basically catch "other" and discriminator undefined
            else {
              setChangeGroups(data[0]);
            }
          } else {
            setChangeGroups(data[0]);
          }

          console.log("bippity");
          console.log(data);
          setLoadingOrgs(false);
        });
      myCaiApiService
        .GetWasSubmitted(apiAccessToken, impersonating?.employeeId)
        .then((data) => {
          setWasSubmitted(data);
        })
    }
  };

  return (
    <Fragment>
      <Routes>
        <Route
          path={``}
          exact
          element={
            <Fragment>
              {loadingOrgs && <Progress>Loading Assigned Orgs</Progress>}
              {!loadingOrgs && (
                <PayrollChangeGroup
                  ownedChangeGroups={ownedChangeGroups}
                  assignedChangeGroups={assignedChangeGroups}
                  seeAllChangeGroups={seeAllChangeGroups}
                  loadingOrgs={loadingOrgs}
                  changeGroupChange={(x) => {
                    setChangeGroups(x);
                  }}
                  accessToken={apiAccessToken}
                  impersonation={impersonating}
                  seeAll={seeAll}
                  wasSubmit={wasSubmitted}
                  onSubmit={() => {
                    repullData();
                  }}
                  upn={upn}
                />
              )}
            </Fragment>
          }
        />
        <Route
          path=":changeGroupId/*"
          element={
            <PayrollMeritChange
              data={changeGroups}
              accessToken={props.accessToken}
              impersonation={props.impersonation}
              seeAll={seeAll}
              upn={upn}
              isLoading={loadingOrgs}
              onMeritUpdate={() => {
                repullData();
              }}
            />
          }
        />
      </Routes>
    </Fragment>
  );
};

export default PayrollPlanningApp;

import { createContext, useEffect, useState, useContext } from "react";
import { useSymphonyApiService } from "../../../hooks/useSymphonyApi";
import { useChatHistory } from "./ChatHistoryContext";
import { useChat } from "./ChatContext";
import { useUserProfile } from "./UserProfileContext";
import { useSettings } from "./SettingsContext";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "cai-fusion";
import { useMessages } from "./MessageContext";
import { useAssistant } from "./AssistantContext";
import { placeholder } from "@codemirror/view";

const NewTemplateContext = createContext();

function NewTemplateProvider({ children }) {
    const apiServiceClient = useSymphonyApiService("v2");
    const { defaultAssistant } = useAssistant();
    const { createToast } = useContext(ToastContext);
    const { receiveMessage, setThinkingResponse } = useMessages();
    const { userProfile } = useUserProfile();
    const { models, settings } = useSettings();
    const { dynamicNewChat } = useChatHistory();
    const { selectChat } = useChat();
    const [userMembership, setUserMembership] = useState({});
    const canShare = () => userMembership?.membership < 2;
    const membershipLevels = {
        1: "Manager",
        2: "Contribute",
        3: "View Only"
    };

    // Until I can think of a better way to operate having a butt ton of placeholder values to start, we're sticking with this
    const placeholderTemplate = {
        "title": `Create a Job Description`,
        "description": "",
        "initialMessageText": `Hey there! Let's get started on writing a job description. Tell me a bit about the role and I'll create a first draft we can collaborate on. Feel free to attach any existing job descriptions you have that you'd like me to reference as an example."`,
        "messageComponents": [],
        "hasFileUpload": false,
        "fileUploadMessage": "Choose file or drag files here to include files with the message.",
        "includedFiles": [],
        "userAddedFiles": [],
        "tags": [],
        "customText": null,
        "data": [],
    }

    // The current chat template's ID. This will be null when no template is selected, or when a template is being made.
    const [chatTemplateId, setChatTemplateId] = useState(undefined);
    // The current chat template. This will be used when viewing or editing a chat template.
    const [chatTemplate, setChatTemplate] = useState(undefined);
    // Chat template's quick actions
    const [chatTemplateShortcuts, setChatTemplateShortcuts] = useState([]);
    // The list of available chat templates.
    const [chatTemplates, setChatTemplates] = useState([]);
    // All, every.
    const [allChatTemplates, setAllChatTemplates] = useState([]);
    // Should be toggled when UI needs to show a loading state.
    const [isLoading, setIsLoading] = useState(false);

    const [showRFPItem, setShowRFPItem] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (!userProfile) return;
        fetchTemplates();
    }, [userProfile])

    useEffect(() => {
        if (chatTemplate?.id !== chatTemplateId) {
            fetchTemplate(chatTemplateId);
        }
    }, [chatTemplateId])

    useEffect(() => {
        if (!userProfile) return
        if (chatTemplate?.accesses) {
            setUserMembership({ ...chatTemplate?.accesses?.find(user => user.userProfileId === userProfile.userProfileId) })
        } else {
            setUserMembership(
                {
                    membership: 1
                }
            )
        }
    }, [chatTemplate, userProfile])

    const fetchTemplates = async () => {
        console.log("[TEMPLATE] Loading templates...");
        const respTemplates = await apiServiceClient.Templates.getTemplates();

        console.log("[TEMPLATE] Templates recieved.");
        console.log("[TEMPLATE] Templates:", respTemplates);

        // Set the list of chat templates to this returned value.
        setChatTemplates(respTemplates ?? []);
        setAllChatTemplates([...respTemplates] ?? []);
    }

    const fetchTemplate = async (templateId) => {
        if (!templateId) {
            setChatTemplate(undefined);
            return;
        };

        try {
            console.log("[TEMPLATE] Loading template", templateId);
            let resp = await apiServiceClient.Templates.getTemplate(templateId);
            console.log("[TEMPLATE] Template recieved", resp);
            if (resp) setChatTemplate({ ...resp, customText: resp.assistant?.systemMessage ?? resp.customText });

            // Getting chat template quick actions
            console.log("[TEMPLATE] Getting template quick actions...");
            let shortcutResp = await apiServiceClient.Templates.getTemplateQuickActions(templateId);
            console.log("[TEMPLATE] Quick actions recieved:", shortcutResp);
            if (resp) setChatTemplateShortcuts([...shortcutResp]);

            return { ...resp };
        } catch (error) {
            console.log("[TEMPLATE] Error when loading template with ID", templateId);
			navigate(`/maestro/templates/`);
        }
    }

    const navigateToTemplateForm = async (templateId = undefined) => {
        if (templateId) {
            setChatTemplateId(templateId);
            // Redundant operation, but this allows us to be sure we have an updated templateObj.
			await fetchTemplate(templateId);
			navigate(`/maestro/templates/${templateId}`);
        }
        else {
            preparePlaceholderTemplate();
            navigate(`/maestro/templates/new`);
        }
    }

    const preparePlaceholderTemplate = () => {
        let fullPlaceHolderTemplate = { ...placeholderTemplate }
        fullPlaceHolderTemplate.modelId = models?.find((model) => model.isDefaultTextModel)?.engine
        fullPlaceHolderTemplate.modelParameters = [...settings]
        fullPlaceHolderTemplate.customText = defaultAssistant.systemMessage
        setChatTemplate({ ...fullPlaceHolderTemplate });
    }

    const leaveTemplateForm = () => {
        navigate(`/maestro/templates`);
        setChatTemplateId(undefined);
    }

    // -------------------------------
    // Basic Template Ops
    // -------------------------------

    const deleteFileFromTemplate = async (resourceId) => {
        setIsLoading(true);
        try {
            await apiServiceClient.Templates.deleteFileFromTemplate(chatTemplateId, resourceId);
        } catch (error) {
            console.log("[TEMPLATE] Error when creating a new chat template.", error);
            setChatTemplates([...chatTemplates])
            createToast("An error occurred while creating your template. Please wait some time and try again.", "error");
        } finally {
            setIsLoading(false);
        }
    }

    const addFileToTemplate = async (file) => {
        setIsLoading(true);
        try {
            const result = await apiServiceClient.Templates.addFileToTemplate(chatTemplateId, file);
            if (result) {
                return result;
            } else {
                createToast("An error occurred while creating your template resource. Please wait some time and try again.", "error");
            }
        } catch (error) {
            console.log("[TEMPLATE] Error when creating a new chat template.", error);
            setChatTemplates([...chatTemplates])
            createToast("An error occurred while creating your template. Please wait some time and try again.", "error");
        } finally {
            setIsLoading(false);
        }
    }

    const createTemplate = async (templateObj) => {
        setIsLoading(true);
        try {
            console.log("[TEMPLATE] Attempting to create template from", templateObj);
            // Create a temporary template, the ID being "loadingitem" controls conditional rendering.
            // Attempt to create the template object using the form-made object.
            if (templateObj.customText === defaultAssistant.systemMessage) {
                templateObj.assistantId = defaultAssistant.assistantInstructionId
            } else {
                templateObj.assistantId = null;
            }

            let newTemplate = await apiServiceClient.Templates.createTemplate(templateObj);
            console.log("[TEMPLATE] Template created successfully. New object:", newTemplate)

            //Adding files to template:
            if (chatTemplate.includedFiles) {
                let files = await Promise.all(chatTemplate.includedFiles.map(async (file) => await apiServiceClient.Templates.addFileToTemplate(newTemplate.id, file)));
                newTemplate.files = files;
            }

            // Filter out the temporary template and add the new fully assembled template.
            setChatTemplates([newTemplate, ...chatTemplates]);
            createToast("Chat template created successfully.", "success");

            // Quick Action additions
            chatTemplateShortcuts.map(async (shortcut) => {
                // When creating, make sure to add the principle ID of the template.
                await apiServiceClient.Templates.createTemplateQuickAction(newTemplate.id, {...shortcut, principleId: newTemplate.id});
            });

            navigate(`/maestro/templates`);
            setChatTemplateId(undefined);
        } catch (error) {
            console.log("[TEMPLATE] Error when creating a new chat template.", error);
            setChatTemplates([...chatTemplates])
            createToast("An error occurred while creating your template. Please wait some time and try again.", "error");
        } finally {
            setIsLoading(false);
        }
    }

    const updateTemplate = async (templateObj) => {
        // Similar to the create template, but when adding the returned value to the list of templates, filter out the old one with the ID.
        setIsLoading(true);
        let oldTemplates = [...chatTemplates]
        try {
            if (templateObj.customText === defaultAssistant.systemMessage) {
                templateObj.assistantId = defaultAssistant.assistantInstructionId
            } else {
                templateObj.assistantId = null;
            }

            console.log("[TEMPLATE] Attempting to update template with ID", chatTemplateId, "to", templateObj);
            let newTemplate = await apiServiceClient.Templates.updateTemplate(chatTemplateId, { ...templateObj, "accesses": []});
            console.log("[TEMPLATE] Template created successfully. New object:", newTemplate)
            // Filter out the temporary template and add the new fully assembled template.
            setChatTemplates([newTemplate, ...chatTemplates.filter((template) => template.id !== templateObj.id)]);
            createToast(`${newTemplate.title} updated successfully.`, "success");

            // Updating quick actions
            chatTemplateShortcuts.filter((shortcut) => (shortcut.isDeleted ?? false)).map(async (shortcut) => {
                await apiServiceClient.QuickActions.deteleQuickAction(shortcut.id);
            });
            chatTemplateShortcuts.filter((shortcut) => (shortcut.isEdited ?? false)).map(async (shortcut) => {
                // If it's already been created, edit, otherwise create.
                if (shortcut.id) {
                    await apiServiceClient.QuickActions.updateQuickAction(shortcut.id, shortcut);
                } else {
                    // When creating, make sure to add the principle ID of the template.
                    await apiServiceClient.Templates.createTemplateQuickAction(newTemplate.id, {...shortcut, principleId: newTemplate.id});
                }
            });

            navigate(`/maestro/templates`);
            setChatTemplateId(undefined);
        }
        catch (error) {
            console.log("[TEMPLATE] Error when updating a chat template.", error);
            setChatTemplates(oldTemplates);
            createToast("An error occurred while updating your template. Please wait some time and try again.", "error");
        }
        finally {
            setIsLoading(false);
        }
    }

    const deleteTemplate = async (templateId) => {
        try {
            console.log("[TEMPLATE] Deleting template with ID:", templateId);
            await apiServiceClient.Templates.deleteTemplate(templateId);
            // Update states
            setChatTemplates([...chatTemplates.filter((template) => template.id !== templateId)])
            setAllChatTemplates([...chatTemplates.filter((template) => template.id !== templateId)])
        } catch (error) {
            console.log("[TEMPLATE] Problem occurred during template deletion.");
        }
    }

    const searchTemplates = async (query, silent = false) => {
        // Handling the event of an empty search query
        if (!query || !query.trim()) {
            setChatTemplates(allChatTemplates);
            return;
        }

        // Setting loading state for UI loading components
        if (!silent) setIsLoading(true);

        try {
            console.log("[TEMPLATE] Searching templates with query", query);

            // Convert query to lowercase to ensure case-insensitive search
            let lowerCaseQuery = query.toLowerCase();

            // Filter chatTemplates based on name or description containing the query
            const filteredTemplates = allChatTemplates.filter(template => {
                const titleMatch = template.title.toLowerCase().includes(lowerCaseQuery);
                const descriptionMatch = template.description.toLowerCase().includes(lowerCaseQuery);
                return titleMatch || descriptionMatch;
            });
            // Set the filtered templates
            setChatTemplates(filteredTemplates);

            // Check RFP to see if it matches the search criteria.
            const RFPTitle = "Perform a Standard RFP Review"
            const RFPDescription = "Analyze an RFP and get a first review on the document's details."

            setShowRFPItem(RFPTitle.toLowerCase().includes(lowerCaseQuery) || RFPDescription.toLowerCase().includes(lowerCaseQuery));

            console.log("[TEMPLATE] Results:", filteredTemplates);
        } catch (error) {
            handleApiError("Error searching templates.", error);
        } finally {
            setIsLoading(false);
        }
    }

    const executeTemplate = async () => {
        // uh
        //let messageText = chatTemplate.messageComponents.map((promptPart) => promptPart.value).join('');
        //console.log("[TEMPLATE] Executing current template:", chatTemplate, "Initial prompt:", messageText);
        let session = await apiServiceClient.Templates.createTemplateSession(chatTemplate.id, chatTemplate.messageComponents);
        // navigate(`/symphony/${session?.chatId}`);
        await selectChat(session?.chatId);
        dynamicNewChat(session);

        try {
            await Promise.all(chatTemplate.userAddedFiles.map(async (file) => await apiServiceClient.Templates.addFileToSession(chatTemplate.id, session.chatId, file)));
        } catch (ex) {
            console.log(`[TEMPLATE] Failed to write user added files with exception ${ex.message}`)
        }

        setThinkingResponse(session.messages);
        let resp = await apiServiceClient.Templates.executeSession(chatTemplate.id, session.chatId);

        receiveMessage(resp.result);
    }

    // -------------------------------
    // Secondary Template Ops
    // -------------------------------

    // Toggle the favorite status of a template.
    const toggleFavoriteTemplate = async (templateId, isFavorited) => {
        try {
            let currTemplate = chatTemplates.find((template) => template.id === templateId);
            console.log("[TEMPLATE] Toggling favorite for ID", templateId, "to", !isFavorited);
            if (isFavorited) {
                await apiServiceClient.Templates.unfavoriteTemplate(templateId);
            } else {
                await apiServiceClient.Templates.favoriteTemplate(templateId);
            }
            currTemplate.isFavorited = !currTemplate.isFavorited;
            // Proc update to the template list to update rendering
            setChatTemplates([...chatTemplates]);
            //createToast("Favorite status updated successfully.", "success");
        } catch (error) {
            handleApiError("Error toggling favorite status.", error);
        }
    }

    const isMember = async (user) => {
        console.log("[DATASTORE] " + user.identifier + " being checked for membership");

        const membership = chatTemplate.accesses.find((x) => x.userProfileId === user?.userProfileId);
        return membership;
    }


    // Adds a user to a data store.
    const shareTemplate = async (user, membership) => {
        if (!chatTemplateId) {
            console.error("[TEMPLATE] NO DATASTORE HERE DUDE");
            return;
        }

        if (user) {
            try {
                console.log("[TEMPLATE] Sharing datastore with a user.")
                const resp = await apiServiceClient.Templates.shareTemplate(chatTemplateId, undefined, user.identifier, membership);
                //const resp = await apiServiceClient.DataStore.getActiveUsers(dataStoreId);
                setChatTemplate({ ...chatTemplate, accesses: [...chatTemplate.accesses, resp] })
                createToast("Template shared successfully.", "success");
            } catch (error) {
                handleApiError("Error sharing Template.", error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    // I'm gonna be honest I have no idea why this function exists
    const replaceUser = (userId, accessList) => {
        const updatedUsers = accessList.map((user) => user.userProfileId === userId.userProfileId ? userId : user)
        setChatTemplate({ ...chatTemplate, accesses: [...updatedUsers] })
    }

    // Updates a member's current membership level for a data store.
    const updateTemplateAccess = async (userId, membership) => {
        console.log("[TEMPLATES] test")
        setIsLoading(true);
        try {
            await apiServiceClient.Templates.updateAccess(chatTemplateId, userId.userProfileId, membership);
            const response = await apiServiceClient.Templates.getTemplate(chatTemplateId);
            replaceUser(userId.userProfileId, response.accesses);
            createToast("Template access updated successfully", "success");
        } catch (error) {
            handleApiError("Error updating Template access", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Removes a user's access to a data store.
    const removeTemplateAccess = async (userId) => {
        setIsLoading(true);
        try {
            await apiServiceClient.Templates.removeAccess(chatTemplateId, userId.userProfileId);
            const response = await apiServiceClient.DataStore.getDataStore(chatTemplateId); //could optimize by creating a getActive users only api call
            setChatTemplate({ ...chatTemplate, accesses: [...response.accesses] })
            createToast("User access removed successfully", "success");
        } catch (error) {
            handleApiError("Error removing user access", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Creates a new template shortcut and adds it to the list
    const createTemplateShortcut = (name, prompt) => {
        // The "isEdited" field is frontend only and is for staging templates to be updated.
        setChatTemplateShortcuts([{name, prompt, isEdited: true}, ...chatTemplateShortcuts]);
    }

    // Editing a currently existing template shortcut.
    const editTemplateShortcut = (name, prompt, index) => {
        // Find and update the proper fields.
        let tempShortcuts = [...chatTemplateShortcuts];
        tempShortcuts[index].name = name;
        tempShortcuts[index].prompt = prompt;
        // The "isEdited" field is frontend only and is for staging templates to be updated.
        tempShortcuts[index].isEdited = true;
        setChatTemplateShortcuts([...tempShortcuts]);
    }

    // Deletes a template shortcut from the list.
    const deleteTemplateShortcut = (index) => {
        if (chatTemplateShortcuts[index].id) {
            // If the shortcut already existed beforehand, mark it for deletion.
            let tempShortcuts = [...chatTemplateShortcuts];
            tempShortcuts[index].isDeleted = true;
            setChatTemplateShortcuts([...tempShortcuts]);
        } else {
            // If it didn't exist before, we can just remove it.
            setChatTemplateShortcuts([...chatTemplateShortcuts.slice(0, index), ...chatTemplateShortcuts.slice(index+1)]);
        }
    }

    // TODO: Delete all shortcuts in a template when it's deleted :D

    // Helper function for printing API errors.
    const handleApiError = (message, error) => {
        console.error(message, error);
        createToast(message, "error");
        setIsLoading(false);
    };

    return (
        <NewTemplateContext.Provider
            value={{
                // States
                placeholderTemplate,
                allChatTemplates,
                chatTemplate,
                setChatTemplate,
                chatTemplates,
                chatTemplateShortcuts,
                isLoading,
                chatTemplateId,
                setChatTemplateId,
                membershipLevels,
                isMember,
                canShare,
                showRFPItem,
                // Functions
                navigateToTemplateForm,
                preparePlaceholderTemplate,
                leaveTemplateForm,
                createTemplate,
                fetchTemplate,
                updateTemplate,
                deleteTemplate,
                searchTemplates,
                executeTemplate,
                toggleFavoriteTemplate,
                shareTemplate,
                updateTemplateAccess,
                removeTemplateAccess,
                deleteFileFromTemplate,
                addFileToTemplate,
                createTemplateShortcut,
                editTemplateShortcut,
                deleteTemplateShortcut,
            }}
        >
            {children}
        </NewTemplateContext.Provider>
    );
}

// Hook to use the NewTemplateContext in a component
function useNewTemplate() {
    const context = useContext(NewTemplateContext);
    if (context === undefined) {
        throw new Error(
            "useNewTemplate must be used within a NewTemplateProvider"
        );
    }
    return context;
}

export { NewTemplateProvider, useNewTemplate };

import { useState, useEffect, useContext } from "react";
import { ReactComponent as IconCloseSquare } from "../../../images/close-square.svg";
import { ReactComponent as IconTrash } from "../../../images/icon-trash.svg"
import { ReactComponent as IconAdd } from "../../../images/icon-add.svg"

import ImageSearch from "../../../images/search-icon-light.png"

import DnDFileUpload from "../../fusion/FileUpload/DragAndDrop";
import FileDisplay from "../components/symphonyFileDisplay";
import { Drawer, HeadingRow, Pagination, ProgressBar } from "cai-fusion";
import { useResource } from "../contexts/ResourceContext";
import { useMessages } from "../contexts/MessageContext";
import { useSettings } from "../contexts/SettingsContext";
import { ToastContext } from "cai-fusion";
import { useDataStore } from "../contexts/DataStoreContext";
import { useTransientVisibility } from "../../fusion/Contexts/VisualContext";

const DataSourceDrawer = () => {
    const { dataSources, enabledDataStores, setEnabledDataStores, handleDataSourcesChange, handleFilesUpload, uploadComplete, setUploadComplete, enableDataStore, disableDataStore } = useResource();
    const { requestedTokens } = useMessages();
    const { tokenLimit } = useSettings();
    const [tokenUsage, setTokenUsage] = useState(0.0);
    const [maxTokens, setMaxTokens] = useState(false);
    const [browsingData, setBrowsingData] = useState(false);
    const { createToast } = useContext(ToastContext);
    const { dataStores, allDataStores, searchDataStores } = useDataStore();
    const { visibilityStates, hide } = useTransientVisibility();

    const [searchText, setSearchText] = useState("");
    const [page, setPage] = useState(1);
    const [disabledDataStores, setDisabledDataStores] = useState([]);

    const NUM_PER_PAGE = 10

    const closeClickedHandler = () => {
        hide('DataSourceDrawer');
    };

    useEffect(() => {
        setDisabledDataStores(dataStores.filter((dataStore) => !enabledDataStores.find((enabledDataStore) => enabledDataStore.id === dataStore.id)));
    }, [dataStores, enabledDataStores])

    useEffect(() => {
        // A check to update pagination as needed - if we're on a page that no longer exists, go back one.
        if (page > 1 && disabledDataStores.length <= (page - 1) * 10) {
            setPage(page - 1);
        }
    }, [disabledDataStores])

    useEffect(() => {
        // Close the modal if the upload was successful
        if (uploadComplete) {
            closeClickedHandler();
            setUploadComplete(false);
        }
    }, [uploadComplete]);

    useEffect(() => {
        if (tokenLimit > 1) {
            const percent = Math.floor((requestedTokens / tokenLimit) * 100);
            setTokenUsage(percent);
            setMaxTokens(percent >= 99.98);
        }
    }, [requestedTokens, tokenLimit]);

    // Effect for creating a toast when maxTCHit is set to true
    useEffect(() => {
        if (maxTokens) {
            const toastMessage = (
                <>
                    <h4>Max Memory Reached</h4>
                    <div>
                        I can only handle so many words in a single chat, and it looks like this chat has become too large to manage smoothly. To keep my response time quick, we have two options:
                        <ul>
                            <li><strong>Start a New Chat</strong>: This will give us a blank slate and help us continue our conversation without any hiccups.</li>
                            <li><strong>Remove Some Files</strong>: You can delete some of the files we've uploaded in this chat to free up space.</li>
                        </ul>
                        Thanks for your understanding!
                    </div>
                </>
            );
            createToast(toastMessage, 'info');
        }
    }, [maxTokens, createToast]);

    return (
        <Drawer
            clickOutsideToClose= { true }
            isHidden={ !visibilityStates.DataSourceDrawer }
            id="data-sources-drawer"
            onClose={() => closeClickedHandler()}
            header={
                <HeadingRow
                    title="Data Sources"
                    eyebrow="Settings"
                    size={3}
                >
                    <div className="m-icon-row m-icon-row--right-align">
                        <a
                            id="drawerCloseBtn"
                            className="a-icon m-icon-row__icon"
                            title="Close Associate Details"
                            onClick={()=> closeClickedHandler() }
                        >
                            <IconCloseSquare className="a-icon-link__icon">
                                {" "}
                            </IconCloseSquare>
                        </a>
                    </div>
                </HeadingRow>
            }
        >
            <form className="o-form l-mt-sm">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-12">
                                <h5 className="a-heading a-heading--heading-3">
                                    Data Collections
                                </h5>
                                <p className="a-muted-text">Include pre-defined collections of related content that provide context for the assistant. These can include files, websites and more. Data collections can be used across different chats.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="o-block">
                                    <table className="o-treegrid o-treegrid--compact l-mr-sm">
                                        <colgroup className="o-treegrid__colgroup">
                                            <col className="o-treegrid__col o-treegrid__col--medium" span="1" />
                                            <col className="o-treegrid__col o-treegrid__col--32" span="1" />
                                        </colgroup>
                                        <tbody>
                                            {enabledDataStores.map((enabledDataStore) => {
                                                return (
                                                    <tr className="o-treegrid__row o-treegrid__row--shown" key={enabledDataStore.name}>
                                                        <td className="o-treegrid__cell"><span className="a-subtle-text"><strong>{enabledDataStore.name}</strong></span></td>
                                                        <td className="o-treegrid__cell o-treegrid__cell--right-aligned">
                                                            <button className="a-icon-link a-icon-link--destructive" onClick={(e) => { e.preventDefault(); disableDataStore(enabledDataStore) }}>
                                                                <div className="a-icon-link__inner">
                                                                    <div className="a-icon-link__icon-wrapper">
                                                                        <IconTrash className="a-icon-link__icon"/>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className={`m-collapsible-panel m-collapsible-panel--shaded l-mt-xs ${ browsingData ? "m-collapsible-panel--expanded" : ""}`}>
                                        <a className="m-collapsible-panel__heading" aria-expanded={browsingData ? "true": "false" }>
                                            <h3 className="a-heading a-heading--heading-5 m-collapsible-panel__heading-label">Browse additional collections to add</h3>
                                            <span className="m-collapsible-panel__collapse-toggle" onClick={(e) => { e.preventDefault(); setBrowsingData(!browsingData); }}>
                                                <svg className="m-collapsible-panel__collapse-icon" xmlns="http://www.w3.org/2000/svg" width="15.08" height="15.08" viewBox="0 0 15.08 15.08" aria-hidden={browsingData ? "false": "true"}>
                                                    <g id="Layer_2" data-name="Layer 2" transform="translate(-6.46 -6.46)">
                                                        <g id="Layer_1" data-name="Layer 1">
                                                            <rect className="m-collapsible-panel__collapse-icon-horizontal" id="Rectangle_45" data-name="Rectangle 45" width="15.08" height="2.15" transform="translate(6.46 12.92)"></rect>
                                                            <rect className="m-collapsible-panel__collapse-icon-vertical" id="Rectangle_46" data-name="Rectangle 46" width="2.15" height="15.08" transform="translate(12.92 6.46)"></rect>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                        </a>
                                        <div className="m-collapsible-panel__contents">
                                            <div className="row">
                                                <div className="col-12">
                                                    <input className="a-search-input__text-box" type="text" placeholder="Search collections..." aria-label="Search" id="search_field" name="search_field" onChange={(e) => setSearchText(e.target.value)}/>
                                                    <button className="a-search-input__button" type="submit" onClick={(e) => {e.preventDefault(); searchDataStores(searchText);}}>
                                                        <img className="a-search-input__img" src={ImageSearch} alt="search button icon" />
                                                    </button>
                                                    <table id="sharedUsers" className="o-treegrid o-treegrid--compact l-mr-sm l-mt-xs">
                                                        <colgroup className="o-treegrid__colgroup">
                                                            <col className="o-treegrid__col o-treegrid__col--xxx-wide" span="1" />
                                                            <col className="o-treegrid__col o-treegrid__col--small" span="1" />
                                                        </colgroup>
                                                        <tbody>
                                                            {disabledDataStores.slice((page - 1) * NUM_PER_PAGE, Math.min(disabledDataStores.length, page * NUM_PER_PAGE)).map((dataStore) => {
                                                                    return (
                                                                        <tr className="o-treegrid__row o-treegrid__row--shown" key={dataStore.id}>
                                                                            <td className="o-treegrid__cell">
                                                                                <p className="a-subtle-text l-mb-none"><strong>{dataStore.name}</strong></p>
                                                                                <p className="a-subtle-text a-muted-text l-mt-none">{dataStore.description}</p>
                                                                            </td>
                                                                            <td className="o-treegrid__cell">
                                                                                <div className="m-button-row">
                                                                                    <button className="a-icon-link" onClick={(e) => { e.preventDefault(); enableDataStore(dataStore) }}>
                                                                                        <div className="a-icon-link__inner">
                                                                                            <div className="a-icon-link__icon-wrapper">
                                                                                                <IconAdd className="a-icon-link__icon" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                    <Pagination 
                                                        numPerPage={NUM_PER_PAGE}
                                                        data={disabledDataStores}
                                                        currentPage={page}
                                                        onPageChange={(page) => setPage(page)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {(dataSources !== null && dataSources?.length !== 0) &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="o-block">
                                            {dataSources?.map((source, index) => (
                                                <label className="a-checkbox" key={index}>
                                                    <span>{source.name}</span>
                                                    <input
                                                        checked={source.included ?? false}
                                                        type="checkbox"
                                                        onChange={() => handleDataSourcesChange(index)}
                                                    />
                                                    <div className="b-input"></div>
                                                </label>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row l-mt-md">
                            <div className="col-md-12">
                                <h5 className="a-heading a-heading--heading-3">
                                    Web Pages
                                </h5>
                                <p className="a-muted-text">
                                    Provide the URLs of web pages you'd like Maestro to
                                    incorporate into the conversation. Note that Maestro
                                    can't crawl whole websites. It will only scan the
                                    individual pages you provide URLs for below.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="o-block">Coming Soon!</div>
                            </div>
                        </div>
                        <div className="row l-mt-md">
                            <div className="col-12">
                                <h5 className="a-heading a-heading--heading-3">
                                    Included Files
                                </h5>
                                <p className="a-muted-text">
                                    Documents that the assistant is able to reference for
                                    context in this chat.
                                </p>
                            </div>
                        </div>
                        <div className="row l-mb-sm">
                            <div className="col-12">
                                <div className="o-block">
                                    <FileDisplay />
                                </div>
                            </div>
                        </div>
                        <DnDFileUpload
                            onFileUpload={handleFilesUpload}
                        />
                        <div className="row l-mt-md">
                            <div className="col-md-12">
                                <h5 className="a-heading a-heading--heading-3">Memory Usage</h5>
                                <p className="a-muted-text">
                                    Maestro can only hold so much information in memory for a
                                    given chat. Each file, website, and message use some of
                                    that memory. When the memory limit is reached, it's time
                                    to start a new chat or to remove files and web pages you
                                    no longer need.
                                </p>
                            </div>
                        </div>
                        <div className="l-row">
                            <div className="col-md-12">
                                <ProgressBar 
                                    percentage={tokenUsage}
                                    yellowThreshold={80}
                                    redThreshold={100}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Drawer>
    );
}

export default DataSourceDrawer;

import { Heading } from "cai-fusion";
import { symphonySVGs } from "../../configs/iconConfig.js"
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react"
import SymphonyUserSettings from "../symphony/symphonyUserSettings.jsx";

const SettingsOption = ({ id, name, svg }) => {

    const params = useParams();
    const isActive = params['*'] === id

    const OptionIcon = symphonySVGs[svg];

    return (
        <li className={`m-vertical-tabs__item ${isActive ? "m-vertical-tabs__item--active" : ""}`}>
            <NavLink to={`/settings/${id}`} className="m-vertical-tabs__link">
                <div className="m-vertical-tabs__icon-wrapper">
                    <OptionIcon className="m-vertical-tabs__icon" />
                </div>
                <span className="m-vertical-tabs__label">{name}</span>
            </NavLink>
        </li>
    )
}


const SettingsPage = () => {

    const navigate = useNavigate();
    const params = useParams();
    const pageID = params['*']

    const formatName = (colorId) => {
        // Split the string into an array of words, capitalize the first letter of each, then paste it back together
        return colorId.split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '); 
    }

    useEffect(() => {
        console.log("test");
        document.title = "Settings - " + formatName(params['*']);
    }, [params])

    // For safety reasons, this might want to be exported elsewhere in the future.
    // If a user can view page with id "maestro", then "maestro" key should have true value.
    const [permissionMap, setPermissionMap] = useState({
        "maestro": true,
    })

    // If this is just /settings, redirect to display settings.
    // If user does not have permission to view said page, also redirect to display settings.
    if (!pageID || permissionMap[pageID] === false) {
        navigate("/settings/display");
    }
    // Similar thing, but for the Maestro rebrand.
    if (pageID === "symphony") {
        navigate("/settings/maestro");
    }

    // Creating a flag for showing the "App Settings" header.
    const appFlag = Object.values(permissionMap).some(value => value === true);

    return (
        <>
                <div className="o-page-section o-page-section l-mt-md l-pt-none">
                    <div className="o-page-section__content-container o-page-section__content-container--fixed-width l-pt-none l-mt-neg-sm">
                        <div className="l-row l-pt-sm">
                            {/* Show all options as needed. */}
                            <div className="col-md-3">
                                <Heading size={4}>Global Settings</Heading>
                                <nav className="m-vertical-tabs">
                                    <ul className="m-vertical-tabs__list">
                                        <SettingsOption id="display" name="Display Preferences" svg="IconSettings" />
                                        <SettingsOption id="profile" name="Personal Profile" svg="IconCopy" />
                                        {/*<SettingsOption id="accessibility" name="Accessibility Settings" svg="IconSuccess" />*/}
                                    </ul>
                                </nav>
                                {appFlag && <Heading size={4}>App Settings</Heading>}
                                <nav className="m-vertical-tabs">
                                    <ul className="m-vertical-tabs__list">
                                        {permissionMap["maestro"] && <SettingsOption id="maestro" name={process.env.REACT_APP_BRANDING_SYMPHONY_NAME ?? "Maestro"} svg="IconSettings" />}
                                    </ul>
                                </nav>
                            </div>
                            {/* Load the correct settings menu based off of which page we're looking at */}
                            <div className="col-md-9">
                                {
                                    pageID === 'display' ? <DisplaySettings /> :
                                    pageID === 'profile' ? <TempProfileSettings /> :
                                    // pageID === 'accessibility' ? <AccessibilitySettings /> :
                                    pageID === 'maestro' ? <SymphonyUserSettings /> :
                                    <>
                                        <Heading size={3}>No Settings Found</Heading>
                                        <p>No settings are found for this section.</p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}


/*
    The in-house pages for the settings page. 
    Individual applications' settings menus should be imported from where the rest of their code is housed.
*/
const DisplaySettings = () => {
    return (
        <>
            <Heading size={3}>Coming Soon!</Heading>
            <p>Settings that can change how you see {process.env.REACT_APP_BRANDING_APP_NAME ?? "MyCAI"} will be coming soon!</p>
        </>
    )
}

const TempProfileSettings = () => {
    return (
        <>
            <Heading size={3}>Coming Soon!</Heading>
            <p>Changes to your global {process.env.REACT_APP_BRANDING_APP_NAME ?? "MyCAI"} profile are coming soon!</p>
        </>
    )
}

const ProfileSettings = () => {
    const [section, setSection] = useState(0);

    return (
        <>
            <Heading size={1}>My Profile Settings</Heading>
            {/* <div className="m-tab-row l-mt-sm">
                <a className={`m-tab-row__tab ${section === 0 ? "m-tab-row__tab--active" : ""}`} onClick={() => setSection(0)}>
                    <span className="m-tab-row__tab-title">Section 1</span>
                </a>
                <a className={`m-tab-row__tab ${section === 1 ? "m-tab-row__tab--active" : ""}`} onClick={() => setSection(1)}>
                    <span className="m-tab-row__tab-title">Section 2</span>
                </a>
            </div> */}

            {/* {section === 0 && <> */}
                <div className="row l-mt-sm">
                    <div className="col-12">
                        <Heading size={3}>Personalization Info</Heading>
                        <p className="a-muted-text">Information for the all {process.env.REACT_APP_BRANDING_APP_NAME} applications to properly identify you how you choose.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="o-block">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="m-form-group">
                                        <label for className="a-label">Preferred Name</label>
                                        <input type="text" className="a-text-input" value="Steve Smith" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="m-form-group">
                                        <label for className="a-label">Favorite Color</label>
                                        <input type="text" className="a-text-input" value="I ran out of ideas really quickly." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </>}

            {section === 1 && <>
                <p>Section 2 data should be filled in here.</p>
            </>} */}
        </>
    )
}

const AccessibilitySettings = () => {
    return (
        <>
            <Heading size={1}>My Accessbility Settings</Heading>
        </>
    )
}

export default SettingsPage;